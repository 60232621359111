<template>
  <base-button
    :loading="loading"
    :disabled="disabled"
    class="search-btn"
    :block="false"
    color="primary"
    submit
    @click="$emit('click')"
  >
    <v-icon>$search</v-icon>
  </base-button>
</template>

<script>
import BaseButton from "./BaseButton.vue";

/**
 * Component which user can click to perform profile settings update.
 */
export default {
  name: "ButtonSearch",
  components: { BaseButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.search-btn {
  border-radius: 0 50px 50px 0;
  width: 96px;
}

@media only screen and (max-height: 1000px) {
  .search-btn {
    max-height: 48px;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .search-btn {
    min-height: 36px;
    width: 64px;
  }
}
</style>
