<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <base-button
        :loading="loading"
        :disabled="disabled"
        class="language-btn input"
        :block="false"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <svg-icon left name="language" />
        {{ value }}
      </base-button>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in languages" :key="index" @click="select(item)">
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import SvgIcon from "@/components/SvgIcon";

/**
 * Component which user can click to perform profile settings update.
 */
export default {
  name: "ButtonLanguage",
  components: { SvgIcon, BaseButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    languages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    select(language) {
      this.$emit("input", language);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.language-btn {
  border-radius: 0;
  width: 78px;
  margin-right: 1px;
}

.not-transparent {
  background: white;
}

@media only screen and (max-height: 1000px) {
  .language-btn {
    max-height: 48px;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .language-btn {
    min-height: 36px;
    width: 64px;
  }
}
</style>
