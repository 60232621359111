<template>
  <DocumentPreviewList v-if="loading" loading />
  <div v-else class="d-flex flex-column justify-center ma-10">
    <!-- FIXME: show a decent message -->
    <!-- {{ details }}-->
    <p>
      Cauzza works best when you enter a question in natural language. So ask what you want to know but don't
      try to guess keywords like in Google.
    </p>
    <!--    <p v-if="totalDocuments">-->
    <!--      Search through {{ totalDocuments }} documents. For more details-->
    <!--      <router-link to="dashboard"> CLICK HERE </router-link>-->
    <!--    </p>-->
    <!-- <p>For example: "Can I fly my drone over Ghent after 10pm?" instead of "drone ghent curfew"</p>
    <p>Try it out with your own search!</p> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import DocumentPreviewList from "@/components/DocumentPreviewList.vue";

const { mapActions, mapState } = createNamespacedHelpers("main");
/**
 * The index view.
 */
export default {
  name: "IndexInstance",
  components: {
    DocumentPreviewList,
  },
  computed: {
    ...mapState({
      details: (state) => state.selectedIndexDetails,
      loading: (state) => state.loadingResults,
      stats: (state) => state.selectedIndexDetails.stats,
    }),

    totalDocuments() {
      return this.details.datasources.reduce((acc, cur) => acc + cur.statistics.successful?.count, 0);
    },
  },
};
</script>
