// import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib";
import SvgIcon, { ICONS } from "../components/SvgIcon.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

Vue.component("FontAwesomeIcon", FontAwesomeIcon); // Register component globally
library.add(fas);
library.add(far);

Vue.use(Vuetify);

const customIcons = ICONS.reduce(
  (accum, icon) => ({
    ...accum,
    ...{
      [icon]: {
        component: SvgIcon,
        props: {
          name: icon,
        },
      },
    },
  }),
  {}
);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#6ad9a6",
        "primary-selected": "#0DF2A6",
        secondary: "#0aa9e6",
        tertiary: "#7e8287",
        accent: "#82B1FF",
        error: "#e02b2b",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        login_background: "#0aa9e6",
        login_background2: "#263c84",
        "pale-blue": "#e0e3e5",
        "pale-grey": "#f7f9fa",
        "light-teal": "#9cd3d0",
        "dark-grey": "#141516",
        "cool-grey": "#9ca0a4",
        "dusty-blue": "#608aab",
        gunmetal: "#53575c",
        steel: "#7e8287",
        azure: "#0aa9e6",
        tomato: "#e02b2b",
        orange: "#ffbc00",
        "dusk-blue": "#263c84",
        "seafoam-blue": "#6ad9a6",
        greyblue: "#61b9b4",
        "cool-grey-two": "#a6aaae",
        "pale-lilac": "#eae9f0",
        "mid-blue": "#275eaa",
        "dark-grey2": "#d8d9db",
        "secondary-light": "#edf6fa",
      },
    },
  },
  icons: {
    values: {
      ...customIcons,
      "far-thumbs-up": {
        component: FontAwesomeIcon,
        props: {
          icon: ["far", "thumbs-up"],
        },
      },
      "fas-thumbs-up": {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "thumbs-up"],
        },
      },
      "far-thumbs-down": {
        component: FontAwesomeIcon,
        props: {
          icon: ["far", "thumbs-down"],
          class: "fa-rotate-180",
        },
        class: "fa-rotate-180",
      },
      "fas-thumbs-down": {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "thumbs-down"],
          class: "fa-rotate-180",
        },
      },
      "fas-exclamation-circle": {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "exclamation-circle"],
        },
      },
    },
    iconfont: "faSvg",
  },
});
