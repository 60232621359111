<template>
  <div>
    <h3 class="text-center">Number of pages</h3>
    <chart-stacked-bar :chart-data="chartDataNumberOfPages" />
  </div>
</template>

<script>
import ChartStackedBar from "@/components/ChartStackedBar.vue";

/**
 * The stacked charts
 */
export default {
  name: "DSStatsNumberOfPages",
  components: {
    ChartStackedBar,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    chartDataNumberOfPages() {
      return {
        labels: ["Number of pages"],
        datasets: this.getChartDatasets("parts"),
      };
    },
  },
  methods: {
    getChartDatasets(key) {
      const errorAttrs = ["language_error", "empty", "unprocessable", "unsupported_extension"];

      const data = this.data?.statistics;

      if (!data) {
        return [
          {
            label: "Successful",
            backgroundColor: "#6ad9a6",
            data: [],
          },
          {
            label: "Duplicates",
            backgroundColor: "#36a2eb",
            data: [],
          },
          {
            label: "Errors",
            backgroundColor: "#f87979",
            data: [],
          },
        ];
      }

      const { successful, duplicates } = data;

      return [
        {
          label: "Successful",
          backgroundColor: "#6ad9a6",
          data: [successful[key]],
        },
        {
          label: "Duplicates",
          backgroundColor: "#36a2eb",
          data: [duplicates[key]],
        },
        {
          label: "Errors",
          backgroundColor: "#f87979",
          data: [errorAttrs.reduce((acc, cur) => acc + data[cur][key], 0)],
        },
      ];
    },
  },
};
</script>
