<template>
  <v-dialog :value="show" persistent width="500">
    <v-card>
      <v-card-title class="headline" primary-title> An error occurred </v-card-title>
      <v-form ref="form" v-model="valid" class="dialog-padded" lazy-validation @submit.prevent="handleSubmit">
        <v-card-text>
          <template v-if="isSentryEnabled || true">
            Our team has been notified, but any context is surely appreciated. If you'd like to help, tell us what
            happened.
          </template>
          <template v-else>
            This dialog comes here just to warn you did something wrong, check your console and show some debugging
            skills.
          </template>

          <v-textarea v-model="formFields.comments" placeholder="What happened?" :rules="[rule.feedbackRequired]" />
          <base-input
            v-model="formFields.email"
            placeholder="Email"
            type="email"
            icon="$mail"
            class="input"
            :rules="[rule.emailRequired, rule.validEmail]"
          />
          <base-input
            v-model="formFields.name"
            placeholder="Name"
            icon="$user"
            class="input"
            :rules="[rule.nameRequired]"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <base-button empty color="secondary" :large="false" :block="false" @click.prevent="dismiss">
            Dismiss
          </base-button>
          <base-button color="primary" submit :large="false" :block="false">Submit crash report</base-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import { isEnabled } from "@/plugins/sentry.js";

/**
 * A general error dialog that requests for crash report details.
 */
export default {
  components: {
    BaseButton,
    BaseInput,
  },
  props: {
    /**
     * Whether or not to show the dialog.
     */
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rule: {
        nameRequired: this.RULES.required("Name is required"),
        emailRequired: this.RULES.required("Email is required"),
        feedbackRequired: this.RULES.required("Feedback is required"),
        validEmail: this.RULES.email("This doesn't look like a valid email"),
      },
      valid: true,
      formFields: {
        name: "",
        email: "",
        comments: "",
      },
    };
  },
  computed: {
    isSentryEnabled() {
      return isEnabled;
    },
  },

  methods: {
    dismiss($event) {
      /**
       * Emitted when dismissing the dialog without submitting the form.
       */
      this.$emit("dismiss", $event);
      this.formSafeReset();
    },
    formSafeReset() {
      this.$refs.form && this.$refs.form.reset();
    },
    handleSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      /**
       * Emitted upon form submission. Will carry the form fields a payload.
       */
      this.$emit("submit", { ...this.formFields });
      this.formSafeReset();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-padded {
  padding: 0 8px 8px;
}

.v-card__actions {
  padding: 8px 16px;

  > .v-btn.v-btn {
    padding: 0 16px;
  }
}

::v-deep .v-text-field__details {
  margin-bottom: 8px;
  padding: 0 12px;
}
</style>
