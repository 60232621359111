<template>
  <div class="fill-white">
    <reactive-score-visual-scatter :styles="myStyles" :chart-data="chartData" />
    <v-range-slider v-model="range" :min="0" :max="dataArray.length - 1" @click="confirmValue" @end="confirmValue" />
  </div>
</template>

<script>
import { clamp, reverse, sortedIndex, sortedLastIndex, throttle } from "lodash";
import ReactiveScoreVisualScatter from "@/components/ReactiveScoreVisualScatter";

const SCORE_PRECISION = 1e4;

/**
 * Component allows to select score range with plot representation and a slider.
 *
 * During slides, temporary updates will be emitted. When the sliding ends, the input itself is changed and emitted.
 */
export default {
  name: "ScoreRangeSelect",
  components: {
    ReactiveScoreVisualScatter,
  },
  props: {
    /**
     * Controlled value of the range slider
     */
    value: {
      type: Array,
      default: () => [0, 100],
    },
    /**
     * Plot axis data array
     * Array contains Objects with two properties, x and y
     */
    dataArray: {
      type: Array,
      required: true,
    },
    /**
     * Minimum allowed value of range slider
     */
    min: {
      type: Number,
      default: -Infinity,
    },
    /**
     * Maximum allowed value of range slider
     */
    max: {
      type: Number,
      default: Infinity,
    },
  },
  data() {
    return {
      range: [0, this.dataArray.length - 1],
      tempScoreRange: [0, 100],
      throttledPointColors: throttle(this.pointColorsHelper, 50),
      pointColors: [],
    };
  },
  computed: {
    roundedDataArray() {
      return this.dataArray.map(this.round);
    },
    transformedData() {
      return this.roundedDataArray.map((score, idx) => ({ x: idx, y: score }));
    },
    myStyles() {
      return {
        height: "400px",
        position: "relative",
        padding: "0 3px", // Needed to make the position of the slider thumbs to aligned with the points in the plot.
      };
    },
    chartData() {
      return {
        datasets: [
          // {
          //   data: this.range.map((docIndex) => ({ x: docIndex, y: this.roundedDataArray[docIndex] })),
          //   pointBackgroundColor: ["red", "red"],
          //   pointHoverRadius: 4,
          // },
          {
            data: this.transformedData,
            pointBackgroundColor: this.pointColors,
            pointBorderColor: "rgba(0,0,0,0)",
            pointBorderWidth: 0,
            pointRadius: 1,
            pointHoverRadius: 3,
          },
        ],
      };
    },
    reversedData() {
      const copy = this.roundedDataArray.slice();
      return reverse(copy);
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val != null) {
          const minScore = this.round(val[0] / 100);
          const maxScore = this.round(val[1] / 100);
          const n = this.roundedDataArray.length;

          let rangeMin = 0;
          while (rangeMin < n - 1 && this.roundedDataArray[rangeMin] > maxScore) {
            ++rangeMin;
          }

          let rangeMax = n - 1;
          while (rangeMax > 0 && this.roundedDataArray[rangeMax] < minScore) {
            --rangeMax;
          }

          if (this.range[0] !== rangeMin || this.range[1] !== rangeMax) {
            this.range = [rangeMin, rangeMax];
          }

          // Supposedly a more efficient way, but sometimes the selection shifts by one after sliding...
          // // val = [minScore, maxScore], but roundedDataArray is from high to low score, so left boundary is determined
          // // through looking at maxScore and vice versa. However, sortedIndex assumes order is from low to high...
          // const lowToHighScores = this.reversedData;
          // // Following selects first index where the min score should be inserted in the array to keep it sorted
          // // so when we reverse it again, it will be the rightmost index
          // const lowestScoringSelectedDocIdx = sortedIndex(lowToHighScores, val[0] / 100);
          // const rangeMax = clamp(this.roundedDataArray.length - 1 - lowestScoringSelectedDocIdx, 0, this.roundedDataArray.length - 1);
          // // Following selects last index where the max score should be inserted in the array to keep it sorted
          // // so when we reverse it again, it will be the leftmost index
          // const highestScoringSelectedDocIdx = sortedLastIndex(lowToHighScores, val[1] / 100);
          // const rangeMin = clamp(this.roundedDataArray.length - highestScoringSelectedDocIdx, 0, this.roundedDataArray.length - 1);
          //
          // if (this.range[0] !== rangeMin || this.range[1] !== rangeMax) {
          //   this.range = [rangeMin, rangeMax];
          //   console.log(`VALUE UPDATE: ${val} -> ${this.range}`);
          // }
        }
      },
      immediate: true,
    },
    range: {
      handler(newValue) {
        // Remember: roundedDataArray is ordered from high to low score, so the right slider knob is actually about the min score
        // and vice versa!
        this.throttledPointColors();
        const minScore = clamp(this.roundedDataArray[newValue[1]], this.min, this.max);
        const maxScore = clamp(this.roundedDataArray[newValue[0]], this.min, this.max);
        this.tempScoreRange = [minScore * 100, maxScore * 100];
        /**
         * Emitted during slides to update the parent component with the current values.
         */
        this.$emit("update:tempRange", this.tempScoreRange);
      },
      immediate: true,
    },
  },
  created() {
    this.pointColorsHelper();
  },
  methods: {
    pointColorsHelper() {
      const color = this.$vuetify.theme.themes.light["tertiary"];
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      const defaultColor = `rgba(${r}, ${g}, ${b}, 1)`;

      let points = this.pointColors.fill(defaultColor);
      if (this.range != null) {
        for (let i = this.range[0]; i <= this.range[1]; i++) {
          points[i] = this.$vuetify.theme.themes.light.primary;
        }
        // points[this.range[0]] = "red";
        // points[this.range[1]] = "red";
      }
      this.pointColors = points.slice();
      return points;
    },
    confirmValue() {
      this.$emit("input", this.tempScoreRange);
    },
    round(num) {
      return Math.round((num + Number.EPSILON) * SCORE_PRECISION) / SCORE_PRECISION;
    },
  },
};
</script>

<style lang="scss" scoped>
.fill-white {
  background-color: white;
  padding: 0 12px;
  min-width: 454px;
}
</style>
