<template>
  <v-tooltip bottom open-delay="1000">
    <template #activator="{ on }">
      <v-btn
        text
        class="text-capitalize big-button"
        :disabled="!allowExport"
        :ripple="false"
        v-on="on"
        @click="handleDownload"
      >
        Total results:
        <v-skeleton-loader v-if="loading" type="table-cell" class="ml-1 mt-1" width="3em" tile />
        <span v-else>{{ total }}</span>
        <v-icon v-if="allowExport" right> $download </v-icon>
      </v-btn>
    </template>
    <span>Export results</span>
  </v-tooltip>
</template>

<script>
/**
 * Component that shows total q-ty of matched results.
 */
export default {
  name: "TotalResults",
  props: {
    /**
     * Total results q-ty
     */
    total: {
      type: Number,
      default: 0,
    },
    /**
     * Sets the component in a loading state.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Enables the export functionality of this component to allow users to export the results to a CSV file.
     */
    allowExport: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
    };
  },
  methods: {
    handleDownload() {
      /**
       * Emitted when the component is clicked, signals the intention of the user to download the results as a CSV.
       */
      this.$emit("download");
    },
  },
};
</script>

<style lang="scss" scoped>
.big-button {
  height: 64px !important;

  &.v-btn.v-btn--disabled {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
</style>
