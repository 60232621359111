<script>
import TextHit from "@/components/TextHit.vue";

/**
 * The extended highlight component. Uses seq to render counters
 */
export default {
  name: "TextScoreHit",
  extends: TextHit,

  methods: {
    /**
     * String for hit counter, ex: 2/10, 15/132
     * @param i integer
     * @returns string Example: "12/15"
     */
    hitCounter(i) {
      const currentHit = this.snippet.hits[i].seq;

      return `${currentHit + 1}/${this.totalHits}`;
    },

    isSelected(i) {
      const currentHit = this.snippet.hits[i].seq;

      return currentHit === this.currentSelection;
    },
  },
};
</script>
