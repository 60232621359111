import * as mutationType from "../mutationTypes";
import { initState } from "./state";

export default {
  [mutationType.SET_PROFILE](state, profile) {
    state.profile = profile;
  },

  [mutationType.RESET](state) {
    // acquire initial state
    const init = initState();
    Object.keys(init).forEach((key) => {
      state[key] = init[key];
    });
  },

  [mutationType.SHOW_FORCE_LOGOUT_SNACKBAR](state, data) {
    state.showForceLogout = data;
  },
};
