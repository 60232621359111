<template>
  <ToolbarFilterMenu :icon="icon" :text="fieldName + title" :close-on-content-click="false" :disabled="disabled">
    <v-list>
      <v-list-item color="primary">
        <v-checkbox
          label="Check"
          color="inherit"
          :append-icon="active ? '$clear' : ''"
          :false-value="false"
          :true-value="true"
          :indeterminate="!active"
          :value="switchModel"
          @change="change"
          @click:append="clear"
        />
      </v-list-item>
    </v-list>
  </ToolbarFilterMenu>
</template>

<script>
import ToolbarFilterMenu from "./ToolbarFilterMenu";

/**
 * Component filters that allow to filter documents by boolean value.
 */
export default {
  name: "FilterBoolean",
  components: { ToolbarFilterMenu },
  props: {
    /**
     * The filter specification, determines what field it will filter etc. Of the form `{field: String}`.
     */
    specs: {
      type: Object,
      required: true,
    },
    /**
     * Current filter value. Of the form `{value: [Boolean, null], active: Boolean}`.
     */
    value: {
      type: Object,
      required: true,
    },
    /**
     * The (left) icon of this filter. Defaults to no icon.
     */
    icon: {
      type: String,
      default: "",
    },
    /**
     * Disables the filter UI.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      switchModel: false,
      active: false,
    };
  },
  computed: {
    fieldName() {
      const split_field = this.specs.field.split(".");
      return this.specs.displayName || `${split_field[split_field.length - 1]}`;
    },
    title() {
      return this.active ? (this.switchModel ? ": Yes" : ": No") : "";
    },
  },
  watch: {
    value: {
      handler(newVal = {}) {
        const incompleteValue = newVal.value === undefined || newVal.active == null;
        this.switchModel = newVal.value || null;
        this.active = newVal.active || false;
        if (!this.active && this.switchModel !== null) {
          this.switchModel = null;
          this.updateFilter();
        } else if (incompleteValue) {
          this.updateFilter();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    updateFilter() {
      /**
       * Emits the new value corresponding to v-model.
       */
      this.$emit("input", { value: this.switchModel, active: this.active });
    },
    clear() {
      this.active = false;
      this.switchModel = null;
      this.updateFilter();
    },
    change(val) {
      this.switchModel = val;
      this.active = true;
      this.updateFilter();
    },
  },
};
</script>
