<template>
  <div>
    <v-sheet color="pale-grey">
      <v-container fluid>
        <v-row v-if="!loading" align="start" justify="center">
          <!--suppress RequiredAttributes --><!-- Required attrs are hidden within array objects -->
          <document-preview
            v-for="preview in previews"
            :key="`card_${preview.id}`"
            v-bind="preview"
            class="ma-3"
            @feedback="handleFeedback"
            @hideDocument="handleHide"
          />
          <div class="infinite-loader">
            <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
              <!--              <div slot="spinner">-->
              <!--                <skeleton-list />-->
              <!--              </div>-->
              <div slot="no-more">End of results</div>
              <div slot="no-results">No documents found</div>
              <div slot="error">
                <template v-if="errorState && errorState.status === 404 && errorState.detail === 'No such qid'">
                  <h3 class="ma-4">The query you are looking for does not exist.</h3>
                  <p class="mx-4">
                    You probably came here through opening a link and it seems that that link is either wrong or
                    expired.
                  </p>
                </template>
                <template v-else>
                  <h3 class="ma-4">Something unexpected went wrong</h3>
                  <p class="mx-4">
                    Well, this is embarrassing... Not really what neither you nor us were hoping it would come to.
                  </p>
                </template>
              </div>
            </infinite-loading>
          </div>
        </v-row>
        <skeleton-list v-else />
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import DocumentPreview from "@/components/DocumentPreview.vue";
import SkeletonList from "@/components/SkeletonDocumentsPreviewList.vue";

/**
 * Main component for displaying the search results. Basically a list of `DocumentPreview` items.
 */
export default {
  name: "DocumentPreviewList",
  components: {
    InfiniteLoading,
    DocumentPreview,
    SkeletonList,
  },

  props: {
    /**
     * The content of the previews to display. It should be an array of objects. Each object will be
     * passed as properties to the DocumentPreview responsible for rendering it. See DocumentPreview to
     * see what key/value pairs are expected within each object (basically every prop should have
     * an entry within each object).
     */
    previews: {
      type: Array,
      default: () => [],
    },

    /**
     * If true, will display loading skeletons instead of the given previews.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * The error state of search results, if error happens during search contains error details
     */
    errorState: {
      type: Object,
      default: null,
    },
    /**
     * Represents total count of documents from search query.
     */
    totalResults: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      infiniteId: +new Date(),
    };
  },

  watch: {
    previews(v) {
      // Do not refresh infinite loader if error happens during search
      // Same when user clears search input and all documents should be cleared as well
      if (this.errorState || this.totalResults === 0) {
        return;
      }

      this.infiniteId += 1;
    },
  },

  methods: {
    handleFeedback(e) {
      /**
       * Emitted when user hits any of the thumb button
       */
      this.$emit("feedback", e);
    },
    infiniteHandler($state) {
      /**
       * Emitted when user scrolls to the end of the list.
       */
      this.$emit("infinite-scroll", $state);
    },
    handleHide(e) {
      /**
       * Emitted when close(hide) button is pressed
       */
      this.$emit("hideDocument", e);
    },
  },
};
</script>

<style lang="scss">
.infinite-loader {
  width: 100%;
}
</style>
