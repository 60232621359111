<template>
  <div class="monitoring-container">
    <div>
      <v-container>
        <v-row>
          <v-col :cols="3">
            <v-select
              :item-text="(job) => `Dataset: ${job.dataset_id} Job ID: ${job.job_id}`"
              :item-value="(job) => job.job_id"
              :items="jobs"
              label="Select job"
              @change="setCurrentJob"
              @focus="fetchJobs"
            ></v-select>
          </v-col>
          <v-col :cols="9">
            <monitoring-content :job-stats="jobStats" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import MonitoringContent from "@/components/MonitoringContent";

import { getJobProgress, getJobs } from "@/store/actionTypes";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("main");
const { mapActions: mapAuthActions } = createNamespacedHelpers("auth");

export default {
  name: "Monitoring",
  components: {
    MonitoringContent,
  },
  data() {
    return {
      polling: null,
      jobs: [],
      currentJob: null,
      jobStats: {},
    };
  },
  beforeDestroy() {
    clearTimeout(this.polling);
  },
  methods: {
    ...mapAuthActions({
      getJobs,
      getJobProgress,
    }),
    setCurrentJob(value) {
      this.currentJob = this.jobs.find((job) => job.job_id === value);
      this.resetJobStats();
      clearTimeout(this.polling);
      this.pollJobProgress();
    },
    resetJobStats() {
      /**
       * this.currentJob is the output of /jobs/{job_id}/progress and it looks like this:
       * {
       *   job_id: JOB_ID,
       *   dataset_id: DATASET_ID,
       *   stages: {
       *     StageName: {
       *       WorkerName: UNITS_OF_WORK
       *     }
       *   }
       * }
       *
       * We want to turn it into this.jobStats, which looks like:
       * {
       *   StageName: {
       *      WorkerName: [] <-- we will put the measurements in this list like this { UNNITS_OF_WORK, timestamp }
       *   }
       * }
       */

      for (let [stageName, stage] of Object.entries(this.currentJob.stages)) {
        let workers = Object.keys(stage);
        /**
         * Object.fromEntries() turns a list of key-value pairs into an object.
         * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
         *
         * So basically we take WorkerName from the workers array and map it to [WorkerName, []], we can then turn that
         * into { WorkerName: [] }.
         */
        this.$set(this.jobStats, stageName, Object.fromEntries(workers.map((worker) => [worker, []])));
      }
    },
    updateJobStats() {
      let now = new Date().toLocaleTimeString();
      for (let [stageName, stage] of Object.entries(this.currentJob.stages)) {
        for (let [workerName, unitsOfWork] of Object.entries(this.jobStats[stageName])) {
          this.jobStats[stageName][workerName].push({
            units_of_work: this.currentJob.stages[stageName][workerName],
            timestamp: now,
          });
        }
      }
    },
    async fetchJobs() {
      this.jobs = await this.getJobs(false);
    },
    async pollJobProgress() {
      this.currentJob = await this.getJobProgress(this.currentJob.job_id);
      this.updateJobStats();
      this.polling = setTimeout(() => {
        this.pollJobProgress();
      }, 60 * 1000); // in ms
    },
  },
};
</script>

<style lang="scss" scoped></style>
