<script>
import { Line, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
  name: "MonitoringGraph",
  extends: Line,
  mixins: [reactiveProp],
  props: {
    /**
     * FIXME
     */
    chartData: {
      type: Object,
      default: () => {},
    },
    /**
     * An object containing any extra options that apply to the chart
     * @see https://www.chartjs.org/docs/latest/charts/line.html#dataset-properties
     */
    options: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{}],
          yAxes: [{ ticks: { beginAtZero: true, stepSize: 1 } }],
        },
      }),
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
