<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "ChartStackedBar",
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    /**
     * FIXME
     */
    chartData: {
      type: Object,
      default: null,
    },
    /**
     * An object containing any extra options that apply to the chart
     * @see https://www.chartjs.org/docs/latest/charts/line.html#dataset-properties
     */
    options: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      }),
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
