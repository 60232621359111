<template>
  <v-card>
    <v-app-bar color="primary">
      <v-toolbar-title>Drives to sync</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon :loading="loadingModel" @click="$emit('refresh')">
        <v-icon>refresh</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card-subtitle
      >Selected {{ selected.length }} drives
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" clearable hide-details></v-text-field>
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-checkbox v-model="userBox" label="Drives of all users" @change="onUserBoxChange" />
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-checkbox v-model="groupBox" label="Drives of all groups" @change="onGroupBoxChange" />
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-checkbox v-model="siteBox" label="Drives of all sites" @change="onSiteBoxChange" />
        </v-col>
      </v-row>
      <v-data-table
        v-model="selected"
        :search="search"
        :headers="headers"
        :items="drives"
        :loading="loadingModel"
        item-key="id"
        show-select
        class="elevation-1"
      >
        <template #top></template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <div id="wrapper">
        <base-button
          class="sync-button"
          color="primary"
          :block="false"
          :loading="loadingModel"
          :disabled="selected.length === 0"
          @click="confirm"
        >
          <div v-if="selected.length > 0">Sync {{ selected.length }} drives!</div>
          <div v-else>Select one or more drives!</div>
        </base-button>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "DriveSelector",
  components: { BaseButton },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    drives: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loadingModel: false,
      search: "",
      selected: [],
      headers: [
        // { text: "id", value: "id" },
        { text: "Drive name", value: "name" },
        { text: "Drive type", value: "type" },
      ],
      userBox: false,
      groupBox: false,
      siteBox: false,
    };
  },
  watch: {
    loading: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.loadingModel = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    confirm() {
      this.loadingModel = true;
      console.log(`selected these drives:`);
      for (let drive of this.selected) {
        console.log(drive.id);
      }
      this.$emit("select", {
        sp_users: this.selected.filter((entity) => entity.type.includes("User")),
        sp_groups: this.selected.filter((entity) => entity.type.includes("Group")),
        sp_sites: this.selected.filter((entity) => entity.type.includes("Site")),
      });

      this.loadingModel = false;
    },
    selectDriveType(driveType, add) {
      if (add) {
        for (let drive of this.drives) {
          if (drive.type.includes(driveType)) {
            this.selected.push(drive);
          }
        }
      } else {
        this.selected = this.selected.filter((drive) => !drive.type.includes(driveType));
      }

      this.selected = Array.from(new Set(this.selected));
    },
    onUserBoxChange(newValue) {
      this.selectDriveType("User", newValue);
    },
    onGroupBoxChange(newValue) {
      this.selectDriveType("Group", newValue);
    },
    onSiteBoxChange(newValue) {
      this.selectDriveType("Site", newValue);
    },
  },
};
</script>

<style scoped>
#wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sync-button {
  width: 320px;
}
</style>
