import moment from "moment";

export const dateFormat = (date) => {
  // Formats date provided by vuetify date picker YYYY-MM-DD (ISO)
  // to date string DD/MM/YYYY, used to show date to user
  if (!date) {
    return "";
  }

  const momentDate = moment(date);

  if (momentDate.isValid()) {
    return momentDate.format("DD/MM/YYYY");
  }

  throw new Error("Invalid date format: " + date);
};

/**
 * Convert date DD/MM/YYYY HH:MM:SS to format that is used by vuetify date picker YYYY-MM-DD
 * @param date {string} The date in
 * @returns {string|null}
 */
export const dateStringToISO8601 = (date) => {
  if (!date) {
    return null;
  }

  let momentDate = moment(date);

  if (!momentDate.isValid()) {
    // Case when trying to format old backend format
    momentDate = moment(date, "DD/MM/YYYY");
  }
  if (momentDate.isValid()) {
    return momentDate.format("YYYY-MM-DD");
  } else {
    throw new Error("Invalid date format:", date);
  }
};

export const endOfDay = (date) => {
  return moment(date).endOf("day").toISOString();
};

export const startOfDay = (date) => {
  return moment(date).startOf("day").toISOString();
};

export const fromUnixTimestamp = (timestamp) => {
  return moment.unix(timestamp);
};
