<template>
  <div>
    <h3 class="text-center">Error extension distribution</h3>
    <chart-doughnut :chart-data="chartData" />
  </div>
</template>

<script>
import { capitalize } from "lodash";
import ChartDoughnut from "@/components/ChartDoughnut.vue";

/**
 * The error extentions charts
 */
export default {
  name: "DSStatsErrorExtensionsDoughnut",
  components: {
    ChartDoughnut,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    chartData() {
      const { statistics } = this.data;

      if (!statistics) {
        return {
          labels: [],
          datasets: [],
        };
      }

      const extensionErrors = Object.keys(statistics.unsupported_extension.categories);

      const labels = extensionErrors.map((el) => capitalize(el));
      const data = extensionErrors.map((err) => statistics.unsupported_extension.categories[err].count);

      return {
        labels,
        datasets: [
          {
            label: "Unsupported extensions",
            data,
            backgroundColor: ["#ff6384", "#36a2eb", "#cc65fe", "#ffce56", "#CDDC39", "#f29136"],
          },
        ],
      };
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
};
</script>
