<template>
  <div class="form-container">
    <v-card min-width="432px">
      <v-card-title>Connect to SharePoint</v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-alert v-model="showError" type="error"> Failed to give admin consent.</v-alert>
          <v-radio-group v-model="customTenantId" mandatory>
            <v-radio :label="`Home tenant (${$route.query.tenant})`" :value="false"></v-radio>
            <v-radio v-model="tenantIdModel" label="Custom tenant:" :value="true"></v-radio>
          </v-radio-group>
          <v-text-field
            v-model="tenantIdModel"
            :disabled="!customTenantId"
            :rules="[rule.uuid]"
            placeholder="Tenant id"
            outlined
            label="Tenant id"
          />
        </v-card-text>
      </v-form>
      <v-card-actions>
        <button-o-auth-login
          class="oauth-button"
          :oauth-type="OAUTH_MICROSOFT"
          :loading="loading"
          title="Connect"
          @click="handleOAuth()"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import ButtonOAuthLogin from "@/components/ButtonOAuthLogin.vue";
import { oauthAdminConsent } from "@/store/actionTypes.js";
import { createNamespacedHelpers } from "vuex";
import { STATUS_CODES } from "@/utils/client";
import { OAuthTypes } from "@/utils/const";

const { mapActions, mapState } = createNamespacedHelpers("auth");

export default {
  name: "SelectTenant",
  components: {
    ButtonOAuthLogin,
  },
  props: {
    /**
     * tenantId
     */
    tenantId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tenantIdModel: "",
      rule: {
        uuid: this.RULES.validUUID("Please use a valid tenant id"),
      },
      OAUTH_MICROSOFT: OAuthTypes.MICROSOFT,
      OAUTH_GOOGLE: OAuthTypes.GOOGLE,
      loading: false,
      showError: false,
      valid: true,
      customTenantId: false,
      urlTenantId: "",
    };
  },
  watch: {
    tenantId: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.tenantIdModel = newValue;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.urlTenantId = this.$route.query.tenant;
  },
  methods: {
    ...mapActions({
      oauthAdminConsent,
    }),
    async handleOAuth() {
      if (this.customTenantId) {
        if (!this.$refs.form.validate()) {
          return;
        }
      }
      try {
        this.loading = true;
        let resp = await this.oauthAdminConsent({
          tenantId: this.customTenantId ? this.tenantId : this.urlTenantId,
        });
      } catch (error) {
        if (error.status == STATUS_CODES.BADREQUEST) {
          this.showError = true;
        } else {
          throw error;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
}
</style>
