<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script>
const ICONCONTEXT = require.context("@/assets/icons");
const ICONIMPORTS = {};
ICONCONTEXT.keys().forEach((key) => {
  const IconName = key
    .replace(/\.svg$/, "") // Remove SVG extension
    .replace(/^\.\//, ""); // Remove beginning ./
  ICONIMPORTS[IconName] = ICONCONTEXT(key);
});

export const ICONS = Object.keys(ICONIMPORTS);

/**
 * Check if the given icon exists in our assets dir or not.
 * @param icon The (base)name of the icon (file). I.e. for the icon './user.svg', it is 'user'.
 * @returns {boolean} True if the icon file exists, false iff not.
 */
function exists(icon) {
  return Object.prototype.hasOwnProperty.call(ICONIMPORTS, icon);
}

/**
 * Low-level component to render one of our (custom) SVG icons. Normally you shouldn't be using
 * this component directly, but rather depend on `v-icon` with `$vuetify.icons.[iconname]` or
 * `$[iconname]`. For example:
 *
 * ```
 *  <v-icon>$vuetify.icons.user</v-icon>
 *  <v-icon>$user</v-icon>
 * ```
 */
export default {
  name: "SvgIcon",

  props: {
    /**
     * The (base)name of the icon (file). I.e. for the icon './user.svg', it is 'user'.
     */
    name: {
      type: String,
      required: true,
      validator: exists,
    },

    /**
     * The title of the icon (displayed in the tooltip).
     */
    title: {
      type: String,
      default: null,
    },

    /**
     * Make the icon small.
     */
    small: {
      type: Boolean,
      default: false,
    },

    /**
     * Make the icon large.
     */
    large: {
      type: Boolean,
      default: false,
    },

    /**
     * Make the icon tiny.
     */
    tiny: {
      type: Boolean,
      default: false,
    },

    /**
     * Shift the icon a bit to the left (e.g. for rendering on buttons).
     */
    left: {
      type: Boolean,
      default: false,
    },

    /**
     * Shift the icon a bit to the right (e.g. for rendering on buttons).
     */
    right: {
      type: Boolean,
      default: false,
    },

    /**
     * Make the icon full width.
     */
    block: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconPath() {
      let iconUrl = {};

      if (exists(this.name)) {
        let icon = ICONIMPORTS[this.name];
        if (Object.prototype.hasOwnProperty.call(icon, "default")) {
          icon = icon.default;
        }
        iconUrl = icon.url;
      } else {
        iconUrl = "";
      }

      return iconUrl;
    },

    className() {
      return [
        `svg-icon svg-icon--${this.name}`,
        {
          small: this.small,
          large: this.large,
          tiny: this.tiny,
          block: this.block,
          left: this.left,
          right: this.right,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: currentColor;
  height: 24px;
  width: 24px;

  &.large {
    height: 32px;
    width: 32px;
  }

  &.small {
    height: 16px;
    width: 16px;
  }

  &.tiny {
    height: 8px;
    width: 8px;
  }

  &.block {
    width: 100%;
  }

  &.left {
    margin-left: -4px;
    margin-right: 8px;
    //height: 18px;
    width: 18px;
  }
  &.right {
    margin-left: 8px;
    margin-right: -4px;
    //height: 18px;
    width: 18px;
  }
}

.no-outline {
  outline: none;
}
</style>
