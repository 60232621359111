<template>
  <div>
    <h3 class="text-center">Success: Languages</h3>
    <chart-doughnut :chart-data="chartData" />
  </div>
</template>

<script>
import { capitalize } from "lodash";
import ChartDoughnut from "@/components/ChartDoughnut.vue";

/**
 * The stacked charts
 */
export default {
  name: "DSStatsLanguages",
  components: {
    ChartDoughnut,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    chartData() {
      const { statistics } = this.data;

      if (!statistics?.languages || !statistics?.multilanguages) {
        return {
          labels: [],
          datasets: [],
        };
      }

      const languages = [
        ...Object.keys(statistics?.languages.categories),
        ...Object.keys(statistics?.multilanguages.categories),
      ];

      return {
        labels: languages.map((el) => capitalize(el)),
        datasets: [
          {
            label: "Main",
            data: languages.map(
              (lang) =>
                statistics.languages.categories[lang]?.count || statistics.multilanguages.categories[lang]?.count
            ),
            backgroundColor: ["#ff6384", "#36a2eb", "#cc65fe", "#ffce56", "#CDDC39", "cyan", "yellow", "pink"],
          },
        ],
      };
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          reverse: false,
        },
      };
    },
  },
};
</script>
