<template>
  <div class="fill-height">
    <!-- FIXME: implement a nicer not found design   -->
    <div v-if="notFound" class="not-found">Couldn't find the query with ID {{ qid }}.</div>
    <!--  <BaseLoader v-else-if="loading" loading message="Fetching results for query" />-->
    <DocumentPreviewList v-else-if="loading" loading />
    <router-view v-else />
    <snack-bar v-model="notFound" @input="$router.push({ name: 'indexInstance' })">
      Couldn't find the query with ID {{ qid }}
      <template slot="buttonContent">Go back</template>
    </snack-bar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { getSearchResults, getSearchResultsAfterOAuthFailed } from "@/store/actionTypes.js";
import { STATUS_CODES } from "@/utils/client.js";
import SnackBar from "@/components/SnackBar.vue";
import DocumentPreviewList from "@/components/DocumentPreviewList.vue";

const { mapActions, mapState } = createNamespacedHelpers("main");
const { mapActions: mapAuthActions } = createNamespacedHelpers("auth");

/**
 * Makes sure the results for the search with the given qid are available in the store..
 */
export default {
  name: "Search",
  components: { DocumentPreviewList, SnackBar },
  props: {
    indexId: {
      type: String,
      required: true,
    },
    qid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      notFound: false,
    };
  },
  computed: {
    ...mapState({
      result: (state) => state.result,
      loading: (state) => state.loadingResults,
      errorState: (state) => state.errorState,
      errorStatusCode: (state) => state.errorStatusCode,
    }),
  },
  watch: {
    qid: {
      async handler(newValue) {
        this.notFound = false;
        if (this.result.qid !== newValue && !this.loading) {
          this.notFound = false;
          await this.getSearchResults({
            index: this.indexId,
            qid: newValue,
          });

          if (this.errorStatusCode == null) return;

          if (this.errorStatusCode === STATUS_CODES.NOTFOUND) {
            this.notFound = true;
            // TODO: check if it is indeed an OAuth permission mismatch or whether it was something else.
          } else if (this.errorStatusCode === STATUS_CODES.FORBIDDEN) {
            await this.getSearchResultsAfterOAuthFailed({
              indexId: this.indexId,
              queryId: newValue,
              tenantId: this.errorState.detail.tenant,
            });
          } else if (this.errorStatusCode) {
            this.$router.push({ name: "indexInstance" });
            throw new Error("Failed to get search results");
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({ getSearchResults }),
    ...mapAuthActions({ getSearchResultsAfterOAuthFailed }),
  },
};
</script>
<style lang="scss" scoped>
.not-found {
  padding: 12px;
  width: 100%;
  text-align: center;
}
</style>
