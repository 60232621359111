<template>
  <div>
    <button-o-auth-login
      class="oauth-button"
      :oauth-type="OAUTH_MICROSOFT"
      :loading="loading"
      title="Connect with SharePoint"
      @click="
        handleOAuth({
          redirectUrl: '/sp-admin',
          oauthType: OAUTH_MICROSOFT,
          extraOptions: 'prompt=select_account',
          append_tenant_id: true,
        })
      "
    />
  </div>
</template>

<script>
import ButtonOAuthLogin from "@/components/ButtonOAuthLogin.vue";
import { PASSWORD_LIMITS } from "@/utils/const.js";
import { oauthLogin } from "@/store/actionTypes.js";
import { createNamespacedHelpers } from "vuex";
import { STATUS_CODES } from "@/utils/client";
import { OAuthTypes } from "@/utils/const";

const { mapActions, mapState } = createNamespacedHelpers("auth");

/**
 * The login-page.
 */
export default {
  name: "SharePointAdminConsentLogin",

  components: {
    ButtonOAuthLogin,
  },

  props: {
    /**
     * The URL where to redirect to after a successful login. Defaults to the home route.
     */
    nextUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      OAUTH_MICROSOFT: OAuthTypes.MICROSOFT,
      OAUTH_GOOGLE: OAuthTypes.GOOGLE,
    };
  },
  methods: {
    ...mapActions({
      oauthLogin,
    }),
    async handleOAuth(data) {
      this.loading = true;
      let resp = await this.oauthLogin(data);
      this.loading = false;

      window.location.href = resp["auth_url"];
    },
  },
};
</script>

<style lang="scss" scoped>
.oauth-button {
  margin-top: 20px;
}
</style>
