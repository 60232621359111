<template>
  <div>
    <div class="settings-container fill-height">
      <h3 class="uppercase text-center">Edit permission: {{ name }}</h3>
      <v-form ref="form" v-model="valid" class="container" lazy-validation @submit.prevent="saveChanges">
        <template v-for="(item, index) in formFields">
          <div :key="index" class="d-flex email-wrap justify-space-between">
            <div
              v-if="item.readonly"
              :class="{ 'text-decoration-line-through': item.deleted }"
              class="d-flex align-center pl-16"
            >
              <span>{{ item.email }}</span>
            </div>
            <base-input
              v-else
              :key="index"
              v-model="item.email"
              placeholder="Email"
              type="email"
              icon="$mail"
              class="input mt-4"
              :rules="index !== formFields.length - 1 ? [rule.emailRequired, rule.validEmail] : [() => true]"
            />
            <base-button
              v-if="index + 1 !== formFields.length"
              :key="`${index}.button`"
              large
              icon
              :class="{ 'mt-4': !item.readonly }"
              @click.prevent="item.deleted ? restoreField(index) : removeEmailField(index)"
            >
              <v-icon v-if="item.deleted" small color="primary">$arrow</v-icon>
              <v-icon v-else small color="primary">$remove_email</v-icon>
            </base-button>
            <div v-else class="placeholder"></div>
          </div>
        </template>
      </v-form>

      <snack-bar v-model="snackBarError">
        {{ errorMsg }}
      </snack-bar>
    </div>
    <v-footer
      v-resize="resize"
      app
      color="white"
      elevation="4"
      :height="smallScreenHeight ? '72px' : '90px'"
      class="footer-container"
    >
      <base-button
        :disabled="saved"
        :loading="loading"
        :block="false"
        class="footer-button"
        color="primary"
        type="submit"
        @click.native="saveChanges"
      >
        {{ submitMessage }}
      </base-button>
    </v-footer>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import SnackBar from "@/components/SnackBar.vue";
/**
 * Edit permission component
 */
export default {
  name: "EditPermissions",
  components: {
    BaseButton,
    BaseInput,
    SnackBar,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    currentUsers: {
      type: Array,
      default: () => [],
    },
    saved: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    barRequestError: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rule: {
        emailRequired: this.RULES.required("Email is required"),
        validEmail: this.RULES.email("This doesn't look like a valid email"),
      },
      valid: true,
      formFields: [
        {
          email: "",
          deleted: false,
          readonly: false,
        },
      ],
      snackBarError: false,
    };
  },

  computed: {
    submitMessage() {
      if (this.saved) {
        return "Saved!";
      } else {
        return "Save changes";
      }
    },
    smallScreenHeight() {
      return this.windowHeight < 1000;
    },
  },

  watch: {
    barRequestError: {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.snackBarError = newValue;
        }
      },
      immediate: true,
    },
    currentUsers: {
      handler(usersEmails) {
        this.formFields = [
          ...usersEmails.map((email) => ({ email, deleted: false, readonly: true })),
          ...this.formFields,
        ];
      },
      immediate: true,
    },
    formFields: {
      handler(emails) {
        if (emails[emails.length - 1].email !== "") {
          this.addEmailField();
        }
      },
      deep: true,
    },
    saved(isSaved) {
      if (isSaved) {
        this.updateFieldsState();
      }
    },
  },

  methods: {
    resize() {
      this.windowHeight = window.innerHeight;
    },
    addEmailField() {
      this.formFields.push({ email: "", deleted: false, readonly: false });
    },
    removeEmailField(i) {
      if (this.formFields[i].readonly) {
        this.formFields[i].deleted = true;
      } else {
        this.formFields.splice(i, 1);
      }
    },
    restoreField(i) {
      this.formFields[i].deleted = false;
    },
    updateFieldsState() {
      this.formFields = this.formFields
        // Filtering all deleted and empty emails
        .filter((el, _i, formFields) => !el.deleted && el.email !== "")
        // Filters unique fields
        .filter((v, i, a) => a.findIndex((t) => t.email === v.email) === i)
        // Make remaining fields saved
        .map((el) => ({
          ...el,
          readonly: true,
        }));

      this.formFields.push({ email: "", readonly: false, deleted: false });
    },
    saveChanges() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // noinspection JSValidateTypes
      // Get the accept invite route. Uses a stub token parameter because router does not want to match it otherwise.
      const acceptInvite = this.$router.resolve({ name: "accept-invite", params: { token: "stub" } }).route;
      const path = acceptInvite.fullPath.substring(0, acceptInvite.fullPath.length - acceptInvite.params.token.length);

      let emails = this.formFields.filter((el) => !el.deleted && el.email !== "").map((el) => el.email);

      // Filter duplicates
      emails = [...new Set(emails)];

      const urlPrefix = `${window.location.origin}${path}`;
      this.$emit("saveChanges", { emails, urlPrefix });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-container {
  overflow: auto;
  padding: 0 13px; // Padding for tiny screens to have some breeding room around the app
  max-width: calc(436px + 2 * 12px); // Design says 436 for the inputs + padding of the wrapper
  display: flex;
  flex-flow: column nowrap;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
}
.placeholder {
  min-width: 44px;
}
.footer-container {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
}

h3 {
  color: var(--v-cool-grey-two-base);
}

.container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  /* padding-bottom: 90px; */
}

.input {
  flex: 1 1 100%;
}

.button {
  flex: 1 0 40%;
  margin: 20px 20px;
}

.footer-button {
  height: 56px;
  padding: 0 24px;
  font-size: 1rem;
  font-weight: bold;

  svg {
    margin-left: 0;
    margin-right: 15px;
  }
}

.email-wrap {
  flex: 1 1 100%;
}

@media only screen and (max-height: 1000px) {
  .footer-button {
    height: 44px;
    padding: 0 18px;
  }
}
</style>
