export function initState() {
  return {
    showForceLogout: false,
    profile: {
      id: 0,
      avatar: null,
      email: "",
      role: "",
      firstname: "",
      lastname: "",
      subscription_count: 0,
    },
  };
}

export default initState();
