<template>
  <ToolbarFilterMenu :icon="icon" :text="fieldName + title" :close-on-content-click="false" :disabled="disabled">
    <v-list>
      <v-list-item>
        <v-text-field :value="currentValue" @input="onInput" />
        <v-checkbox color="secondary" label="Exact" :input-value="checkbox" @change="onChange" />
      </v-list-item>
      <v-list-item>
        <v-checkbox v-model="allowEmpty" color="secondary" :label="`Include documents without ${title}`" />
      </v-list-item>
    </v-list>
  </ToolbarFilterMenu>
</template>

<script>
import ToolbarFilterMenu from "./ToolbarFilterMenu";
import { debounce } from "lodash";

/**
 * Component filters that allow to filter documents by set of possible values.
 */
export default {
  name: "FilterFreeFormText",
  components: { ToolbarFilterMenu },
  props: {
    /**
     * The filter specification, determines what field it will filter etc. Of the form `{field: String}`.
     */
    specs: {
      type: Object,
      required: true,
    },
    /**
     * Current filter value. Of the form `{ value: String, active: Boolean }`.
     */
    value: {
      type: Object,
      required: true,
    },
    /**
     * The (left) icon of this filter. Defaults to no icon.
     */
    icon: {
      type: String,
      default: "",
    },
    /**
     * Disables the filter UI.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allowEmpty: false,
      checkbox: false,
      currentValue: "",
      active: false,
      debounceEmit: debounce(() => {
        this.updateFilter();
      }, 1500),
    };
  },
  computed: {
    fieldName() {
      const split_field = this.specs.field.split(".");
      return this.specs.displayName || `${split_field[split_field.length - 1]}`;
    },
    title() {
      return this.active ? `: ${this.currentValue}` : "";
    },
  },
  watch: {
    value: {
      handler(newValue) {
        const { value: { text, exact } = {} } = newValue;
        const incompleteValue = text == null || exact == null || newValue.active == null;
        this.currentValue = text || "";
        this.checkbox = !!exact;
        this.active = !!newValue.active;

        if (!this.active && this.currentValue !== "") {
          this.currentValue = "";
          this.debounceEmit();
        } else if (this.active && this.currentValue === "") {
          this.active = false;
          this.debounceEmit();
        } else if (incompleteValue) {
          this.debounceEmit();
        }
      },
      immediate: true,
      deep: true,
    },
    allowEmpty(val) {
      if (this.active) {
        this.debounceEmit();
      }
    },
  },
  methods: {
    updateFilter() {
      /**
       * Emits the new value corresponding to v-model.
       */
      this.$emit("input", {
        value: { text: this.currentValue, exact: this.checkbox },
        active: this.active,
        include_no_data: this.allowEmpty,
      });
    },
    onInput(value) {
      this.currentValue = value;
      this.active = !!this.currentValue;
      this.debounceEmit();
    },
    onChange(value) {
      this.checkbox = value;
      this.debounceEmit();
    },
  },
};
</script>
