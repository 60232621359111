import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import filters from "./plugins/filters";
// noinspection ES6UnusedImports
import amplitude from "./plugins/amplitude";
// noinspection ES6UnusedImports
import helpHero from "./plugins/helphero";
// noinspection ES6UnusedImports
import hotjarPlugin from "@/plugins/hotjar.js";
// noinspection ES6UnusedImports
import * as sentryPlugin from "@/plugins/sentry.js";
import validationRules from "./plugins/validationRules";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  filters,
  validationRules,
  render: (h) => h(App),
}).$mount("#app");
