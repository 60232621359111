<template>
  <v-overlay :value="loading" absolute>
    <div class="d-flex flex-column align-center">
      <v-progress-circular indeterminate size="64" />
      <div v-if="message !== ''" class="loading-text">{{ message }}</div>
    </div>
  </v-overlay>
</template>

<script>
/**
 * Basic overlay with a s
 */
export default {
  name: "BaseLoader",
  props: {
    message: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-text {
  padding-top: 10px;
}
</style>
