import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import main from "./main";
import * as actionType from "./actionTypes";
import * as mt from "./mutationTypes";
import { submitSentryFeedback } from "../plugins/sentry";

Vue.use(Vuex);

/**
 * Central state management.
 * Base modules connected via "modules" prop
 *
 */
export default new Vuex.Store({
  actions: {
    [actionType.reset]({ dispatch }) {
      dispatch("main/reset");
      dispatch("auth/reset");
    },
    [actionType.showErrorDialog]({ commit }, { eventId }) {
      commit(mt.SET_EVENT_ID, eventId);
      commit(mt.TOGGLE_ERROR_DIALOG, true);
    },
    [actionType.hideErrorDialog]({ commit }) {
      commit(mt.TOGGLE_ERROR_DIALOG, false);
    },
    [actionType.submitErrorDialog]({ state, commit }, form) {
      submitSentryFeedback({ ...form, event_id: state.currentSentryEventId });
      commit(mt.TOGGLE_ERROR_DIALOG, false);
    },
  },
  mutations: {
    [mt.TOGGLE_ERROR_DIALOG](state, data) {
      state.showErrorDialog = data;
    },
    [mt.SET_EVENT_ID](state, data) {
      state.currentSentryEventId = data;
    },
  },
  state: {
    showErrorDialog: false,
    currentSentryEventId: null,
  },
  modules: {
    auth,
    main,
  },
  /*
   * Throws a warning when trying o mutate the store without passing through a mutation.
   * Disabled in production due to the performance hit for monitoring for this type of changes
   * (as per recommendation on https://vuex.vuejs.org/guide/strict.html).
   */
  strict: process.env.NODE_ENV !== "production",
});
