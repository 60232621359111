<script>
import { Scatter, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

/**
 * Component draws plot based on score distribution
 */
export default {
  name: "ScoreVisualScatter",
  extends: Scatter,
  mixins: [reactiveProp],

  data() {
    return {
      options: {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
          duration: 0, // general animation time
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        legend: {
          display: false, //this will remove legend
        },
        layout: {
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 5,
          },
        },
        title: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (tooltipItem) {
              return `result ${tooltipItem.index}: score ${Number(tooltipItem.yLabel * 100).toFixed(2)}`;
            },
          },
        },
        hover: {
          mode: "index",
          intersect: false,
          animationDuration: 0, // duration of animations when hovering an item
        },
        scales: {
          xAxes: [
            {
              ticks: {
                stepSize: 1,
              },
              display: false, //this will remove all the x-axis grid lines
            },
          ],
          yAxes: [
            {
              display: false, //this will remove all the x-axis grid lines
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
