<template>
  <div class="container">
    <v-alert :value="showAlert" transition="fade-transition" :type="success ? 'success' : 'error'" dismissible>
      <div v-if="success">Successfully Connected to SharePoint!</div>
      <div v-else>[{{ errorCode }}]: {{ errorDetail }}</div>
    </v-alert>
    <drive-selector
      v-if="success"
      :drives="drives"
      :loading="loading"
      @refresh="refresh"
      @select="select"
    ></drive-selector>
  </div>
</template>
<script>
import DriveSelector from "@/components/DriveSelector.vue";
import { getSharePointDriveEntities, selectSharePointDrives } from "@/store/actionTypes";
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapState } = createNamespacedHelpers("main");

export default {
  name: "SelectTenant",
  components: {
    DriveSelector,
  },
  data() {
    return {
      tenantId: "",
      showAlert: true,
    };
  },
  computed: {
    success: {
      get() {
        return this.$route.query.status === "success";
      },
    },
    errorCode: {
      get() {
        return this.$route.query.code;
      },
    },
    errorDetail: {
      get() {
        return this.$route.query.detail;
      },
    },
    ...mapState({
      loading: (state) => state.loadingResults,
      sp_users: (state) => state.sp_users,
      sp_groups: (state) => state.sp_groups,
      sp_sites: (state) => state.sp_sites,
    }),
    drives: {
      get() {
        let currentDrives = [];
        for (let userDetail of this.sp_users) {
          currentDrives.push({
            id: userDetail.id,
            name: `OneDrive of ${userDetail.name} (${userDetail.email})`,
            type: ["User"],
          });
        }

        for (let siteDetail of this.sp_sites) {
          currentDrives.push({
            id: siteDetail.id,
            name: `${siteDetail.name}`,
            type: ["Site"],
          });
        }

        for (let groupDetails of this.sp_groups) {
          currentDrives.push({
            id: groupDetails.id,
            name: `${groupDetails.name}`,
            type: ["Group"],
          });
        }

        return currentDrives;
      },
    },
  },
  async mounted() {
    if (this.success) {
      this.tenantId = this.$route.query.tenant;
      this.refresh();
    }
    this.hideAlert();
  },
  methods: {
    ...mapActions({
      getSharePointDriveEntities,
      selectSharePointDrives,
    }),
    hideAlert(event) {
      window.setInterval(() => {
        this.showAlert = false;
      }, 10000);
    },
    async refresh() {
      await this.getSharePointDriveEntities({ tenantId: this.tenantId });
    },
    async select(data) {
      const { sp_users, sp_groups, sp_sites = [] } = data;
      await this.selectSharePointDrives({
        tenantId: this.tenantId,
        data: {
          sp_users: this.sp_users.filter((sp_user) => sp_users.some((x) => x.id === sp_user.id)),
          sp_groups: this.sp_groups.filter((sp_group) => sp_groups.some((x) => x.id === sp_group.id)),
          sp_sites: this.sp_sites.filter((sp_site) => sp_sites.some((x) => x.id === sp_site.id)),
        },
      });
    },
  },
};
</script>
<style scoped>
.container {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  padding-top: 36px;
}
</style>
