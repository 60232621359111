import * as getterTypes from "../getterTypes";
import { FILTER_REGISTRY } from "@/utils/filters";

export default {
  [getterTypes.activeFilters](state) {
    return state.filters
      .filter((filter) => filter.props.active)
      .map((filter) => {
        const { active, ...props } = filter.props;
        return { ...filter, props };
      });
  },
  [getterTypes.getResultDocuments](state, getters) {
    //const filterPipeline = getters[getterTypes.activeFilters].map((filter) => {
    //  // Producing check function
    //  const spec = state.selectedIndexDetails.filters[filter.index];
    //  return FILTER_REGISTRY[filter.type](spec.props, filter.props);
    //});

    return state.result.documents.filter((document) => {
      //const filtered = filterPipeline.every((checkFunction) => checkFunction(document));
      return !document.hidden;
    });
  },
  [getterTypes.filterSpecs](state) {
    return state.selectedIndexDetails.filters.map((indexFilter) => {
      const filterInfo = state.result.filterInfo?.find(
        (filterInfo) => filterInfo.props.field === indexFilter.props.field
      );

      return filterInfo || indexFilter;
    });
  },
};
