import router from "@/router";
import moment from "moment";
import * as actionType from "../actionTypes";
import * as mt from "../mutationTypes";
import * as getterTypes from "../getterTypes";
import { EVENT_TYPES, logEvent } from "@/plugins/amplitude.js";
import { initState } from "./state";
import { isEqual } from "lodash";
import { awback } from "@/plugins/awback";
import { parse } from "content-disposition";

export default {
  async [actionType.search]({ commit, state, getters }, { index, query }) {
    // Fires when user enters query and presses search button (or enter)
    commit(mt.RESET_ERROR_STATE);
    commit(mt.SET_OFFSET, 0);
    commit(mt.LOADING_RESULTS, true);

    const lang = state.language;
    const params = state.pagination;
    const filters = getters[getterTypes.activeFilters];
    try {
      logEvent(EVENT_TYPES.SEARCH, { query });
      commit(mt.CLEAR_RESULT);
      commit(mt.SET_QUERY, query);

      const result = await awback.search(index, params, { query, lang, filters });
      const merged_result = { ...initState().result, ...result };

      commit(mt.SET_RESULT, merged_result);
      commit(mt.SET_OFFSET, merged_result.documents.length);

      // Second argument is to avoid 'duplicate route' error
      router.push({ name: "searchResults", params: { indexId: index, qid: result.qid } }, () => {});
    } catch (e) {
      commit(mt.SET_ERROR_STATE, e);
    } finally {
      commit(mt.LOADING_RESULTS, false);
    }
  },
  async [actionType.getSearchResults]({ commit, state, getters }, { index, qid }) {
    // Fires when user paste url that containts qid and applying filters to received results
    commit(mt.RESET_ERROR_STATE);
    commit(mt.SET_OFFSET, 0);
    commit(mt.LOADING_RESULTS, true);
    const filters = getters[getterTypes.activeFilters];
    const params = { ...state.pagination, filters };
    try {
      commit(mt.CLEAR_RESULT);
      const result = await awback.fetchResults(index, qid, params);
      const merged_result = { ...initState().result, ...result };
      commit(mt.SET_RESULT, merged_result);
      commit(mt.SET_LANGUAGE, merged_result.lang);
      commit(mt.SET_QUERY, result.query);
      commit(mt.SET_OFFSET, merged_result.documents.length);
    } catch (e) {
      commit(mt.SET_ERROR_STATE, e);
    } finally {
      commit(mt.LOADING_RESULTS, false);
    }
  },

  async [actionType.loadMore]({ commit, state, getters }, { index, qid }) {
    // Fires when user scrolls results down
    commit(mt.RESET_ERROR_STATE);
    const filters = getters[getterTypes.activeFilters];
    const params = { ...state.pagination, filters };

    const result = await awback.fetchResults(index, qid, params);

    const documents = [...state.result.documents, ...result.documents];
    const res = { ...state.result, ...result, documents };

    commit(mt.SET_RESULT, res);
    commit(mt.SET_OFFSET, res.documents.length);

    return result.documents.length;
  },
  async [actionType.getIndexes]({ commit, state }, args = {}) {
    const { forceReload = false } = args;
    if (forceReload || state.indices.length === 0) {
      const indices = await awback.getIndexes();

      commit(mt.SET_INDICES, indices);
    }
  },
  async [actionType.getIndexDetails]({ commit }, index) {
    const indexDetails = await awback.getIndexDetails(index);

    commit(mt.CLEAR_RESULT);
    commit(mt.SET_INDEX_DETAILS, indexDetails);
    commit(mt.SET_LANGUAGE, indexDetails.lang[0]);
  },
  async [actionType.resetIndexDetails]({ commit }) {
    commit(mt.CLEAR_RESULT);
    commit(mt.SET_INDEX_DETAILS, {});
  },

  async [actionType.updateUserFilterValue]({ dispatch, commit, state }, { index, userFilterValue }) {
    // userFilter handbook:
    // text: {value: 'abc', active: true}
    // text + exact: {value: 'abc', exact: true, active: true}
    // date: {value: 'datestr', active: true}
    // date+range: {min: 'datestr_from', max: 'datestr_to', active: true}
    // number: {value: 15, active: true}
    // number+range: {min: 3, max: 10, active: true}
    // category: {items: ['foo'], active: true}
    // category+multiple: {items: ['foo', 'bar'], active: true}
    // bool: {value: false, active: true}

    const oldFilterValue = state.filters[index].props.value;
    const oldFilterActive = state.filters[index].props.active;
    const oldFilterNoData = state.filters[index].props.include_no_data;

    const filter = {
      ...state.filters[index],
      props: {
        ...state.filters[index].props,
        ...userFilterValue,
      },
    };

    commit(mt.UPDATE_USER_FILTER, { index, filter });

    const newFilterValue = state.filters[index].props.value;
    const newFilterNoData = state.filters[index].props.include_no_data;

    const hasAnythingChanged = !isEqual(oldFilterValue, newFilterValue) || !isEqual(oldFilterNoData, newFilterNoData);

    if (!state.result.qid) {
      return;
    }

    if ((userFilterValue.active && hasAnythingChanged) || (!userFilterValue.active && oldFilterActive)) {
      dispatch(actionType.getSearchResults, { index: state.selectedIndexDetails.id, qid: state.result.qid });
    }

    logEvent(EVENT_TYPES.FILTER_RESULTS, filter);
  },
  async [actionType.selectLanguage]({ commit }, language) {
    logEvent(EVENT_TYPES.SELECT_LANGUAGE, { language });

    commit(mt.SET_LANGUAGE, language);
  },
  async [actionType.resetSearch]({ commit }) {
    commit(mt.CLEAR_RESULT);
    commit(mt.CLEAR_FILTERS);
    router.push({ name: "indexInstance" }, () => {});
  },
  async [actionType.openResultDocument]({ commit, state }, { id, qid, indexId }) {
    logEvent(EVENT_TYPES.DOC_OPEN, { id });
    if (!state.result.cache[id]) {
      const document = await awback.document(indexId, qid, id);

      // Copy the document feedback from the corresponding document preview
      const docPreview = state.result.documents.find((doc) => doc.id === id);
      if (docPreview) {
        document.documentFeedback = docPreview.documentFeedback;
      }

      commit(mt.CACHE_DOCUMENT, document);
    }

    commit(mt.SET_LAST_OPENED, id);
  },
  async [actionType.hideResultDocument]({ state, commit }, id) {
    logEvent(EVENT_TYPES.DOC_HIDE, { id });
    const index = state.result.documents.findIndex((doc) => doc.id === id);
    const doc = { ...state.result.documents[index], hidden: true };

    commit(mt.UPDATE_RESULT_DOCUMENT, { doc, index });
  },
  [actionType.reset]({ commit }) {
    commit(mt.RESET);
  },
  [actionType.toggleFilters]({ commit }) {
    commit(mt.TOGGLE_FILTERS);
  },
  [actionType.saveScrollPosition]({ commit }, value) {
    commit(mt.SET_SCROLL_POSITION, value);
  },
  async [actionType.downloadDocument]({ state }, { indexId, qid, id }) {
    logEvent(EVENT_TYPES.DOC_DOWNLOAD, { id });

    const { mimetype, filename } = state.result.cache[id];

    // In case we have PDF open it in new tab in iframe, so download name will be taken from path - filename
    if (mimetype === "application/pdf") {
      let routeData = router.resolve({ name: "documentDownload", params: { indexId, qid, id, filename } });
      window.open(routeData.href, "_blank");
    } else {
      // Immediate download
      const data = await awback.downloadDocument(indexId, qid, id);
      const blob = new Blob([data.file], { type: data.type });
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = filename;
      link.click();
    }
  },

  async [actionType.exportResults]({ state, getters }) {
    const filters = getters[getterTypes.activeFilters];
    const params = { filters };

    const indexId = state.selectedIndexDetails.id;
    const qid = state.result.qid;
    const data = await awback.exportResults(indexId, qid, params);

    const blob = new Blob([data.file], { type: data.type });

    const href = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = href;
    const disposition = parse(data.disposition);
    link.download = disposition.parameters["filename*"] || disposition.parameters["filename"];
    link.click();
    window.setTimeout(() => {
      URL.revokeObjectURL(href);
    });
  },

  [actionType.saveDocumentFeedback]({ commit, state }, { id, documentFeedback }) {
    if (documentFeedback) {
      logEvent(EVENT_TYPES.THUMB_UP, { id, qid: state.result.qid });
      awback.thumbUp(state.selectedIndexDetails.id, state.result.qid, id);
    } else {
      logEvent(EVENT_TYPES.THUMB_DOWN, { id, qid: state.result.qid });
      awback.thumbDown(state.selectedIndexDetails.id, state.result.qid, id);
    }
    // update document in documents list
    const index = state.result.documents.findIndex((doc) => doc.id === id);
    const doc = { ...state.result.documents[index], documentFeedback };
    commit(mt.UPDATE_RESULT_DOCUMENT, { doc, index });

    // update cached document
    if (state.result.cache[id]) {
      commit(mt.CACHE_DOCUMENT, { ...state.result.cache[id], documentFeedback });
    }
  },
  async [actionType.shareIndexWith]({ state }, { emails, urlPrefix }) {
    await awback.shareIndex(state.selectedIndexDetails.id, emails, urlPrefix);
  },
  async [actionType.shareSubWith]({ state }, { emails, urlPrefix }) {
    await awback.shareSub(state.subscriptionDetails.id, emails, urlPrefix);
  },

  async [actionType.getSubscriptions]({ commit }) {
    const subscriptions = await awback.getSubscriptions();

    commit(mt.SET_SUBSCRIPTIONS, subscriptions);
  },
  async [actionType.getSubscriptionDetails]({ commit }, params) {
    const {
      subId,
      from = moment().startOf("month").format("YYYY-MM-DD"),
      to = moment().endOf("month").format("YYYY-MM-DD"),
    } = params;

    const subscriptionDetails = await awback.getSubscriptionDetails(subId, from, to);
    commit(mt.SET_SUBSCRIPTION_DETAILS, subscriptionDetails);
  },

  // local set for storybook
  async [actionType.setSubscriptions]({ commit }, subscriptions) {
    commit(mt.SET_SUBSCRIPTIONS, subscriptions);
  },

  async [actionType.setSubscriptionInfo]({ commit }, subscriptionDetails) {
    commit(mt.SET_SUBSCRIPTION_DETAILS, subscriptionDetails);
  },
  async [actionType.resetSubscriptionInfo]({ commit }) {
    commit(mt.SET_SUBSCRIPTION_DETAILS, {});
  },

  async [actionType.getSharePointUsers]({ commit, state }, { tenantId }) {
    let forceReload = true;
    // const { forceReload = false } = args;
    if (forceReload || state.sp_users.length === 0) {
      const sp_users = await awback.getSharePointUsers(tenantId);

      commit(mt.SET_SP_USERS, sp_users);
    }
  },

  async [actionType.getSharePointGroups]({ commit, state }, { tenantId }) {
    let forceReload = true;
    if (forceReload || state.sp_groups.length === 0) {
      const sp_groups = await awback.getSharePointGroups(tenantId);

      commit(mt.SET_SP_GROUPS, sp_groups);
    }
  },

  async [actionType.getSharePointSites]({ commit, state }, { tenantId }) {
    let forceReload = true;
    if (forceReload || state.sp_sites.length === 0) {
      const sp_sites = await awback.getSharePointSites(tenantId);

      commit(mt.SET_SP_SITES, sp_sites);
    }
  },

  async [actionType.getSharePointDriveEntities]({ dispatch, commit }, { tenantId }) {
    commit(mt.LOADING_RESULTS, true);
    await dispatch(actionType.getSharePointUsers, { tenantId: tenantId });
    await dispatch(actionType.getSharePointGroups, { tenantId: tenantId });
    await dispatch(actionType.getSharePointSites, { tenantId: tenantId });
    commit(mt.LOADING_RESULTS, false);
  },

  async [actionType.selectSharePointDrives]({}, { tenantId, data }) {
    await awback.selectSharePointDrives(tenantId, data);
  },
};
