<template>
  <div>
    <!-- FIXME: implement a nicer not found design   -->
    <div v-if="notFound" class="not-found">Couldn't find subscription with ID {{ subscriptionId }}.</div>
    <div v-else class="d-flex justify-center">
      <BaseLoader v-if="loading" loading message="Loading subscription details" />
      <template v-else>
        <router-view />
      </template>
    </div>
    <snack-bar v-model="notFound" @input="$router.push({ name: 'subscriptionsList' })">
      Couldn't find subscription with ID {{ subscriptionId }}
      <template slot="buttonContent">Go back</template>
    </snack-bar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { getSubscriptionDetails, resetSubscriptionInfo } from "@/store/actionTypes.js";
import { STATUS_CODES } from "@/utils/client.js";
import SnackBar from "@/components/SnackBar.vue";
import BaseLoader from "@/components/BaseLoader.vue";

const { mapActions, mapState } = createNamespacedHelpers("main");

/**
 * Responsible for loading and showing error messages about a certain subscription (denoted by its ID).
 */
export default {
  name: "SubscriptionInstance",
  components: {
    BaseLoader,
    SnackBar,
  },
  props: {
    /**
     * The ID of the subscription where to load the details for.
     */
    subscriptionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      notFound: false,
    };
  },

  computed: {
    ...mapState({
      details: (state) => state.subscriptionDetails,
    }),
    id() {
      return Number(this.subscriptionId);
    },

    isShareSubscriptionRoute() {
      return this.$route.name === "shareSubscription";
    },
    loading() {
      return this.details["id"] !== this.id;
    },
  },

  watch: {
    subscriptionId: {
      async handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          try {
            this.notFound = false;
            if (this.details["id"] !== newValue) {
              // noinspection JSValidateTypes
              await this.getSubscriptionDetails({ subId: newValue });
            }
          } catch (e) {
            if (e.status === STATUS_CODES.NOTFOUND) {
              this.notFound = true;
            }
          }
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.resetSubscriptionInfo();
  },

  methods: {
    ...mapActions({
      resetSubscriptionInfo,
      getSubscriptionDetails,
    }),
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  padding: 12px;
  width: 100%;
  text-align: center;
}
</style>
