<template>
  <div class="settings-wrap fill-height py-6">
    <router-link class="back-btn" :to="originRoute">
      <v-icon x-small left>$path</v-icon>
      back
    </router-link>

    <router-view />
  </div>
</template>
<script>
/**
 * Wrapping component for all the settings views.
 */
export default {
  name: "SettingsIndex",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.originRoute = from.path;
    });
  },
  data() {
    return {
      originRoute: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.settings-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  .back-btn {
    position: absolute;
    display: flex;
    align-items: center;
    top: 45px;
    left: 45px;
    opacity: 0.5;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--v-cool-grey-base);
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
