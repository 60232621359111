<template>
  <v-row align="start" justify="center">
    <v-skeleton-loader
      v-for="item in 10"
      :key="`card_skeleton_${item}`"
      ref="skeleton"
      type="actions,article,list-item-three-line,card-text@2"
      class="ma-3"
      elevation="3"
      width="436px"
      min-width="390px"
      height="355px"
    />
  </v-row>
</template>

<script>
/**
 * Component that show avatar or initials.
 */
export default {
  name: "SkeletonDocumentsPreviewList",
  mounted() {
    window.scrollTo({ top: 0 });
  },
};
</script>

<style lang="scss" scoped></style>
