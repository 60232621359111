<template>
  <div>
    <!-- FIXME: implement a nicer not found design   -->
    <div v-if="notFound" class="not-found">Couldn't find index with ID {{ indexId }}.</div>
    <div v-else :style="isDocumentOpen ? { height: '100%', overflow: 'hidden' } : {}">
      <BaseLoader v-if="loading" loading message="Loading index details" />
      <template v-else>
        <toolbar-filters v-if="!isDocumentOpen && !isShareIndexRoute && !isIndexDashboardRoute" :loading="loading">
          <v-slide-group multiple show-arrows>
            <template v-for="(filter, index) of userFilters">
              <!--suppress JSValidateTypes -->
              <v-slide-item :key="filter.type + filter.props.field">
                <component
                  :is="filterComponent(filter.type)"
                  :key="filter.type + filter.props.field"
                  :value="filter.props"
                  :total-results="totalResults"
                  :specs="filterSpecs[index].props"
                  :filter-info="getFilterInfo(filter.props.field)"
                  @input="updateUserFilterValue({ userFilterValue: $event, index })"
                />
              </v-slide-item>
            </template>
            <!--            <TotalResults-->
            <!--              :total="totalResults"-->
            <!--              :loading="loadingResults"-->
            <!--              :allow-export="allowResultsExport"-->
            <!--              @download="exportResults"-->
            <!--            />-->
          </v-slide-group>
        </toolbar-filters>
        <router-view />
      </template>
    </div>
    <snack-bar v-model="notFound" @input="$router.push({ name: 'indexList' })">
      Couldn't find index with ID {{ indexId }}
      <template slot="buttonContent">Go back</template>
    </snack-bar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { exportResults, getIndexDetails, updateUserFilterValue, resetIndexDetails } from "@/store/actionTypes.js";
import { filterSpecs } from "@/store/getterTypes.js";
import { STATUS_CODES } from "@/utils/client.js";
import SnackBar from "@/components/SnackBar.vue";
import BaseLoader from "@/components/BaseLoader.vue";
import TotalResults from "@/components/TotalResults.vue";
import FilterChoice from "@/components/FilterChoice.vue";
import FilterNumeric from "@/components/FilterNumeric.vue";
import ToolbarFilters from "@/components/ToolbarFilters.vue";
import FilterDateRange from "@/components/FilterDateRange.vue";
import FilterBoolean from "@/components/FilterBoolean.vue";
import FilterFreeFormText from "@/components/FilterFreeFormText.vue";

const { mapActions, mapState, mapGetters } = createNamespacedHelpers("main");

/**
 * Responsible for loading and showing error messages about a certain index (denoted by its ID).
 */
export default {
  name: "IndexInstance",
  components: {
    BaseLoader,
    ToolbarFilters,
    TotalResults,
    FilterBoolean,
    FilterChoice,
    FilterNumeric,
    FilterFreeFormText,
    FilterDateRange,
    SnackBar,
  },
  props: {
    /**
     * The ID of the index where to load the details for.
     */
    indexId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      notFound: false,
    };
  },

  computed: {
    ...mapState({
      details: (state) => state.selectedIndexDetails,
      userFilters: (state) => state.filters,
      totalResults: (state) => state.result.totalResults,
      filterInfo: (state) => state.result.filterInfo,
      showFilters: (state) => state.showFilters,
      loadingResults: (state) => state.loadingResults,
    }),
    ...mapGetters({
      filterSpecs,
    }),
    id() {
      return Number(this.indexId);
    },
    isDocumentOpen() {
      return this.$route.params.id;
    },

    isShareIndexRoute() {
      return this.$route.name === "shareIndex";
    },
    isIndexDashboardRoute() {
      return this.$route.name === "indexDashboard";
    },
    loading() {
      return this.details["id"] !== this.id;
    },

    allowResultsExport() {
      const name = this.details.name.toLowerCase();
      const allowedPrefixes = ["digicity", "digipolis", "vlaio"];
      return this.totalResults > 0 && allowedPrefixes.some((prefix) => name.startsWith(prefix));
    },
  },

  watch: {
    indexId: {
      async handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          try {
            this.notFound = false;
            if (this.details["id"] !== newValue) {
              // noinspection JSValidateTypes
              await this.getIndexDetails(newValue);
            }
          } catch (e) {
            if (e.status === STATUS_CODES.NOTFOUND) {
              this.notFound = true;
            }
          }
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.resetIndexDetails();
  },

  methods: {
    ...mapActions({
      getIndexDetails,
      updateUserFilterValue,
      exportResults,
      resetIndexDetails,
    }),
    getFilterInfo(field) {
      return this.filterInfo?.find((el) => el.props.field === field);
    },
    filterComponent(type) {
      switch (type) {
        case "date":
          return "FilterDateRange";
        case "number":
          return "FilterNumeric";
        case "category":
          return "FilterChoice";
        case "bool":
          return "FilterBoolean";
        case "text":
          return "FilterFreeFormText";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  padding: 12px;
  width: 100%;
  text-align: center;
}
</style>
