<template>
  <div>
    <h3 class="text-center">Success: Filetypes</h3>
    <chart-doughnut :chart-data="chartData" />
  </div>
</template>

<script>
import { capitalize } from "lodash";
import ChartDoughnut from "@/components/ChartDoughnut.vue";

/**
 * The file types chart
 */
export default {
  name: "DSStatsFileTypes",
  components: {
    ChartDoughnut,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    chartData() {
      const { statistics } = this.data;

      if (!statistics?.filetypes) {
        return {
          labels: [],
          datasets: [],
        };
      }

      const types = Object.keys(statistics?.filetypes);

      return {
        labels: types.map((el) => capitalize(el)),
        datasets: [
          {
            label: "Main",
            data: types.map((type) => statistics.filetypes[type]),
            backgroundColor: ["#ff6384", "#36a2eb", "#cc65fe", "#ffce56", "#CDDC39"],
          },
        ],
      };
    },
    mainDoughnut() {
      const { statistics } = this.data;
      if (!statistics?.languages || !statistics?.multilanguages) {
        return;
      }

      return {
        labels: ["languages", "multilanguages"],
        dataset: {
          label: "Main",
          data: [statistics.languages.count, statistics.multilanguages.count],
          backgroundColor: ["#ff6384", "#36a2eb"],
        },
      };
    },
    expandedChartData() {
      const { statistics } = this.data;
      if (!statistics?.languages || !statistics?.multilanguages) {
        return;
      }
      const langCategories = Object.keys(statistics.languages.categories);
      const multiLangCategories = Object.keys(statistics.multilanguages.categories);

      return {
        languages: {
          labels: langCategories,
          data: langCategories.map((cat) => statistics.languages.categories[cat].count),
          backgroundColor: ["#f29135", "#91f321", "#c123fe"],
        },
        multilanguages: {
          labels: multiLangCategories,
          data: multiLangCategories.map((cat) => statistics.multilanguages.categories[cat].count),
          backgroundColor: ["#f29135", "#91f321", "#c123fe", "#a393fe"],
        },
      };
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          reverse: false,
        },
      };
    },
  },
};
</script>
