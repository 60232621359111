<template>
  <v-menu
    v-model="open"
    offset-y
    transition="slide-y-transition"
    bottom
    :close-on-content-click="closeOnContentClick"
    :disabled="disabled"
  >
    <template #activator="{ on }">
      <v-btn
        text
        class="text-capitalize big-button"
        :color="open ? 'white' : ''"
        :class="open ? 'secondary' : ''"
        :ripple="false"
        v-on="on"
      >
        <svg-icon v-if="icon !== ''" left :name="icon" />
        <slot name="text">{{ text }}</slot>
      </v-btn>
    </template>
    <slot />
  </v-menu>
</template>
<script>
import SvgIcon from "@/components/SvgIcon";
/**
 * Wrapper around the filter components that embeds them nicely in the filter toolbar.
 */
export default {
  name: "ToolbarFilterMenu",
  components: { SvgIcon },
  props: {
    /**
     * The (left) icon of this filter. Defaults to no icon.
     */
    icon: {
      type: String,
      default: "",
    },
    /**
     * The text to display in the menu button.
     */
    text: {
      type: String,
      default: "",
    },
    /**
     * Disables the filter UI.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether or not filter menu should close when its content is clicked.
     */
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.big-button {
  height: 64px !important;
}
</style>
