<template>
  <v-row justify="center" class="fill-height row-wrap">
    <v-card outlined elevation="3" class="card fill-height tour-doc-content">
      <div class="document-header">
        <h4 class="tour-doc-title">{{ title }}</h4>
        <v-card-actions class="py-0 pr-0 card-actions">
          <!-- Extra span to introduce the same line-height around the icon the router-link does. -->
          <v-tooltip bottom open-delay="1000">
            <template #activator="{ on }">
              <base-button class="tour-doc-close" icon small @click="handleClose" v-on="on">
                <svg-icon small name="close_card" />
              </base-button>
            </template>
            <span>Close document</span>
          </v-tooltip>
        </v-card-actions>
      </div>

      <div class="document-subheader">
        <div class="small-text-grey meta-data">{{ date }}&nbsp;&nbsp;&nbsp;&nbsp;{{ meta.author || meta.Author }}</div>
        <feedback-thumbs v-model="feedback" class="tour-doc-feedback feedback mr-5" />
        <score-bubble class="tour-doc-score" :score="scoreComputed" />
        <div class="tags tour-doc-tags">
          <document-tags :tags="meta.tags" />
        </div>
      </div>

      <div class="document-content-wrap tour-doc-body">
        <div id="scroll-target" ref="scroll-target" class="document-content">
          <div v-scroll:#scroll-target="onScroll" class="document-text">
            <template v-for="(part, i) of pages">
              <div :key="i" ref="text-page" class="text-part">
                <text-hit
                  v-show="documentOrder"
                  ref="text-hit"
                  :snippet="part"
                  :current-selection="currentSelection"
                  :total-hits="totalHits"
                  :score="score"
                />
                <text-score-hit
                  v-show="!documentOrder"
                  ref="text-score-hit"
                  :snippet="part"
                  :current-selection="currentSelection"
                  :total-hits="totalHits"
                  :score="score"
                />
              </div>
            </template>
          </div>
        </div>
        <position-indicator
          v-if="scrollMax !== 0"
          v-model="indicatorModel"
          class="document-indicator"
          :max="scrollMax"
        />
      </div>
    </v-card>
  </v-row>
</template>

<script>
import { fromUnixTimestamp } from "@/utils/dateFormatter";
import TextHit from "@/components/TextHit.vue";
import TextScoreHit from "@/components/TextScoreHit.vue";
import DocumentTags from "@/components/DocumentTags.vue";
import FeedbackThumbs from "@/components/FeedbackThumbs.vue";
import BaseButton from "@/components/BaseButton.vue";
import ScoreBubble from "@/components/ScoreBubble.vue";
import PositionIndicator from "@/components/PositionIndicator.vue";
import debounce from "lodash/debounce";
import SvgIcon from "@/components/SvgIcon";
import sortBy from "lodash/sortBy";

/**
 * Component that show opened document and all text hits in it.
 */
export default {
  name: "DocumentContent",

  components: {
    SvgIcon,
    ScoreBubble,
    PositionIndicator,
    DocumentTags,
    FeedbackThumbs,
    BaseButton,
    TextHit,
    TextScoreHit,
  },

  props: {
    /**
     * Unique document id.
     */
    id: {
      type: String,
      default: "",
    },
    /**
     * Document meta data contains title and author fields, used in document header
     */
    meta: {
      type: Object,
      default: () => ({
        author: "",
        title: "",
        date_created: "",
        Author: "",
      }),
    },
    /**
     * Match score, represents how much does document matches search query.
     */
    score: {
      type: [Number, String],
      default: 0,
    },
    /**
     * Same as above, but calculated based on highest page score
     */
    highestScore: {
      type: [Number, String],
      default: 0,
    },
    /**
     * Current selection  highlight (and scroll into view).
     */
    currentSelection: {
      type: Number,
      default: null,
    },
    /**
     * The page of the document with highest score.
     */
    highestScorePage: {
      type: Number,
      default: 0,
    },
    /**
     * Total number of hits within this document.
     */
    totalHits: {
      type: Number,
      default: 0,
    },

    /**
     * Array containing the content of the document split into pages,
     *  each part with its own text and hits.
     *  Should be an array of objects that look like
     * @props hits, text
     */
    pages: {
      type: Array,
      default: () => [],
    },
    /**
     * Users document score feedback
     */
    documentFeedback: {
      type: Boolean,
      default: null,
    },
    /**
     * Cycle through hits based on score (default) or based on document position.
     */
    documentOrder: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      blockScroll: false,
      indicator: 0,
      duration: 250,
      ease: "easeInOut",
      // strange offset, required when dragging thumb manually
      offset: -119,
      scrollMax: 100,
      options: {},
      debounceBlock: debounce(() => (this.blockScroll = false), 1500),
    };
  },

  computed: {
    title() {
      return this.meta.title || this.meta["File-Name"] || this.$attrs.uri?.split("/").reverse()[0] || "";
    },
    scoreComputed() {
      return this.score || this.highestScore;
    },
    date() {
      return fromUnixTimestamp(this.meta["Creation-Date"] || this.meta.date_created || this.meta.created).format(
        "DD/MM/YYYY"
      );
    },
    indicatorModel: {
      get() {
        return this.indicator;
      },
      set(value) {
        this.indicator = value;
        this.handleScroll(value);
      },
    },
    feedback: {
      get() {
        return this.documentFeedback;
      },
      set(documentFeedback) {
        this.$emit("feedback", { id: this.id, documentFeedback });
      },
    },
    countersTargetArray() {
      // The refs used to scroll to hits
      return this.$refs[this.documentOrder ? "text-hit" : "text-score-hit"]
        ?.flatMap((el) => el.$refs.counter)
        .filter((el) => !!el);
    },
    pagesTargetArray() {
      // The refs used to scroll to pages
      return this.$refs["text-page"];
    },
    seqToRenderOrder() {
      const hits = this.pages.flatMap((page) => page.hits); // Concat all hits over the full document
      // Construct a mapping from seq to render order (former is given by backend, latter is order in which they
      // originally appeared, captured by the 'count' property we injected in each hit.
      return sortBy(hits, "seq").map((hit) => hit.count);
    },
  },

  watch: {
    /**
     * Sets scrollMax to pass to position-indicator along with options for programmatic scrolling
     * Doing this because element (scroll-target) is still updating its scrollHeight param during mounted lifecycle
     * nextTick is also not suitable, for same reason
     * components scrollHeight is finally updated when setTimeout is called of, 100 ms delay just to be sure
     * works fine with 5000 parts
     *
     * Documentation: https://vuejs.org/v2/guide/components-edge-cases.html
     * $refs are only populated after the component has been rendered, and they are not reactive.
     * It is only meant as an escape hatch for direct child manipulation - you should avoid accessing
     * $refs from within templates or computed properties.
     */
    pages: {
      handler(value) {
        setTimeout(() => {
          const container = this.$refs["scroll-target"] || {};
          this.options = {
            duration: this.duration,
            offset: this.offset,
            ease: this.ease,
            container,
          };

          const { scrollHeight, clientHeight } = container;
          this.scrollMax = scrollHeight - clientHeight;
        }, 100);
      },
      immediate: true,
    },

    currentSelection: {
      handler(value) {
        // If document is loading, default selection value is null
        // no need to scroll
        this.goTo(value);
      },
      immediate: true,
    },
    documentOrder() {
      // If ranking of hits is changed, the n-th hit is no longer th n-th hit => we need to update to be consistent
      this.goTo(this.currentSelection);
    },
  },

  methods: {
    /**
     * Scrolls document to selected hit
     */
    goTo(value) {
      if (value == null) {
        return;
      }

      setTimeout(() => {
        this.blockScroll = false;

        let target = this.pagesTargetArray[this.highestScorePage]; // Fallback if there are no hits...
        if (this.countersTargetArray) {
          // Will try to look into hits array, to initially scroll to
          const targetIndex = this.documentOrder ? value : this.seqToRenderOrder[value];
          target = this.countersTargetArray[targetIndex];
        }

        // If user navigates out, there are no target anymore to scroll to
        if (!target) {
          return;
        }

        this.$vuetify.goTo(target, { ...this.options, offset: -60 });
      }, 150);
    },
    /**
     * Fires when user is moving position indicator directly.
     */
    handleScroll(value) {
      // Block position indicator moving after, when onScroll will fire
      // If not blocking - directive with onScroll handle will be invoked after
      // which will return thumb to previous position
      // (thumb will be jumping back and forth while user is dragging it)
      this.blockScroll = true;
      this.debounceBlock();

      this.$vuetify.goTo(value, this.options);
    },

    /**
     * Fires when user is scrolling document with scroll or gesture.
     * Moves position indicator accordingly
     */
    onScroll(e) {
      if (this.blockScroll) {
        return;
      }

      this.indicator = e.target.scrollTop;
    },

    /**
     * Fires when a user clicks the close button.
     */
    handleClose() {
      /**
       * Emitted when user want to close the currently opened doc.
       */
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
$right-margin: 15px;
$narrow-right-margin: $right-margin - 11px; // Used to align center of dismiss button with center of position indicator

.row-wrap {
  padding-top: 36px;
  padding-bottom: 36px;
}

@media only screen and (max-height: 1000px) and (min-height: 800px) {
  .row-wrap {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media only screen and (max-height: 800px) {
  .row-wrap {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.scroll {
  margin: 48px 30px;
}

.card {
  padding: 26px 20px;
  display: flex;
  flex: 0 1 908px;
  flex-flow: column nowrap;
}

.document-header {
  // We don't want the header to be resizing according to left over space, only to its content.
  flex: 0 0 auto;
  margin-left: 10px; // Total horizontal margin between header and card edge should be 30px
  margin-right: $narrow-right-margin; // Align center of dismiss button with center of position-indicator
  display: flex;
  align-content: space-between;
  align-items: flex-start;

  h4 {
    line-height: 32px;
    flex: 1 1 auto;
  }

  .card-actions .v-btn {
    margin-top: -2px;
    margin-right: calc((16px - 28px) / 2);
  }
}

.document-subheader {
  // We don't want the subheader to be resizing according to left over space, only to its content.
  flex: 0 0 auto;

  margin-top: 9px; // See min-height of .card-header to know how margin-top function together
  margin-left: 10px; // Total horizontal margin between subheader and card edge should be 30px
  margin-right: $narrow-right-margin; // Align center of dismiss button with center of position-indicator
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .small-text-grey {
    opacity: 0.7;
    white-space: nowrap;
    max-width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .meta-data {
    margin-right: 57px;
  }

  .score {
    font-weight: bold;
    font-size: 0.75rem;
    line-height: normal;
    height: 1.75rem;
  }

  .tags {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin-left: 16px;
  }
}

.document-content-wrap {
  margin-top: 24px;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;

  .document-content {
    // We don't want the header to be resizing according to left over space, only to its content.
    flex: 0 1 auto;
    -ms-overflow-style: none; /* Hide scroll bar for Internet Explorer 10+ */
    scrollbar-width: none; /* Hide scroll bar for Firefox */

    overflow-y: scroll;
    overflow-x: hidden;

    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    align-self: stretch;
    height: 100%;

    .document-text {
      flex: 0 1 auto;
      padding-right: 61px; // Diff between right edge of text content and highlights
      white-space: pre-wrap;

      .text-part {
        flex: 0 1 auto;
      }
    }
  }

  .document-indicator {
    // Distance from scroll bar to edge of card and right edge of highlight according to design
    margin-right: 12px;
    margin-left: 17px;
  }
}

.document-content {
  &::-webkit-scrollbar {
    display: none; /* Hide scroll bar for Safari and Chrome */
  }
}
</style>
