<template>
  <div class="fill-height">
    <div class="settings-container">
      <avatar-user
        class="align-self-center avatar"
        :size="90"
        :src="profile.avatar"
        :firstname="profile.firstname"
        :lastname="profile.lastname"
      />
      <h3 class="uppercase text-center">
        {{ `${profile.firstname} ${profile.lastname}` }}
      </h3>

      <v-form ref="form" v-model="valid" class="login-wrapper" lazy-validation @submit.prevent="handleSaveChanges">
        <p class="body-text mb-2">First name</p>
        <base-input
          v-model="formFields.firstname"
          type="text"
          :rules="[rule.minLength]"
          placeholder="First name"
          counter
          icon="$user"
          :hint="`At least ${minLength} characters`"
        >
        </base-input>

        <p class="body-text mb-2">Last name</p>
        <base-input
          v-model="formFields.lastname"
          type="text"
          :rules="[rule.minLength]"
          placeholder="Last name"
          counter
          icon="$user"
          :hint="`At least ${minLength} characters`"
        >
        </base-input>

        <p class="body-text mb-2">Current password</p>
        <base-input
          v-model="formFields.currentPassword"
          :type="showPwdCurrent ? 'text' : 'password'"
          :rules="[...rule.anyPwdField(RULES.required('Please give your current password for verification.'))]"
          placeholder="Current password"
          counter
          icon="$password"
          :hint="`At least ${minPassLength} characters`"
        >
          <template slot="append">
            <v-icon class="toggle-show" @click="showPwdCurrent = !showPwdCurrent">
              {{ showPwdCurrent ? "$closed" : "$eye" }}
            </v-icon>
          </template>
        </base-input>

        <p class="body-text mb-2">Your New Password</p>
        <base-input
          v-model="formFields.newPassword"
          :type="showPwdNew ? 'text' : 'password'"
          :rules="[...rule.anyPwdField(rule.pwdRequired, rule.minPwdLength)]"
          placeholder="Your New Password"
          counter
          icon="$password"
          :hint="`At least ${minPassLength} characters`"
        >
          <template slot="append">
            <v-icon class="toggle-show" @click="showPwdNew = !showPwdNew">
              {{ showPwdNew ? "$closed" : "$eye" }}
            </v-icon>
          </template>
        </base-input>

        <p class="body-text mb-2">Repeat Your New Password</p>
        <base-input
          v-model="formFields.newPasswordRepeat"
          :type="showPwdRepeat ? 'text' : 'password'"
          :rules="[
            ...rule.anyPwdField(
              RULES.required('Please type your new password again for verification.'),
              rule.minPwdLength,
              rule.confirmMatch(formFields.newPassword)
            ),
          ]"
          placeholder="Repeat Your New Password"
          counter
          icon="$password"
          :hint="`At least ${minPassLength} characters`"
        >
          <template slot="append">
            <v-icon class="toggle-show" @click="showPwdRepeat = !showPwdRepeat">
              {{ showPwdRepeat ? "$closed" : "$eye" }}
            </v-icon>
          </template>
        </base-input>

        <base-button
          class="save-button"
          :color="invalidCurrentPassword ? 'error' : 'primary'"
          type="submit"
          :loading="loading"
          :disabled="saved"
        >
          {{ submitMessage }}
        </base-button>
      </v-form>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { NAME_LIMITS, PASSWORD_LIMITS } from "@/utils/const";
import { updateProfile } from "@/store/actionTypes";
import BaseButton from "@/components/BaseButton.vue";
import AvatarUser from "@/components/AvatarUser.vue";
import BaseInput from "@/components/BaseInput.vue";
import { STATUS_CODES } from "@/utils/client";

const { mapActions, mapState } = createNamespacedHelpers("auth");

/**
 * The user account settings page.
 */
export default {
  name: "Account",
  components: {
    AvatarUser,
    BaseButton,
    BaseInput,
    // ChangePassword
  },
  data() {
    return {
      rule: {
        minLength: this.RULES.min(`The name needs be at least ${NAME_LIMITS.min} characters long`, NAME_LIMITS.min),
        validEmail: this.RULES.email("This doesn't look like a valid email"),
        pwdRequired: this.RULES.required("Please choose a password"),
        minPwdLength: this.RULES.min(
          `The password needs be at least ${PASSWORD_LIMITS.min} characters long`,
          PASSWORD_LIMITS.min
        ),
        anyPwdField: (...rules) => {
          if (this.anyPwdFieldFilled) {
            return rules;
          } else {
            return [() => true];
          }
        },
        confirmMatch: (newPassword) => this.RULES.confirmMatch(`Passwords don't match`, newPassword),
      },
      minPassLength: PASSWORD_LIMITS.min,

      formFields: {
        currentPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
      },
      showPwdCurrent: false,
      showPwdNew: false,
      showPwdRepeat: false,
      loading: false,
      valid: true,
      overlay: false,
      invalidCurrentPassword: false,
      saved: false,
    };
  },

  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),

    anyPwdFieldFilled() {
      const { newPassword, currentPassword, newPasswordRepeat } = this.formFields;
      return Boolean(newPassword || currentPassword || newPasswordRepeat);
    },

    currentPassword() {
      return this.formFields.currentPassword;
    },
    submitMessage() {
      if (this.invalidCurrentPassword) {
        return "Wrong current password!";
      } else if (this.saved) {
        return "Saved!";
      } else {
        return "Save changes";
      }
    },
  },
  watch: {
    currentPassword(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.invalidCurrentPassword = false;
      }
    },
    profile: {
      handler(data) {
        this.formFields.firstname = data.firstname;
        this.formFields.lastname = data.lastname;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateProfile,
    }),
    /**
     *  Validate the form and save the changes if valid.
     */
    async handleSaveChanges() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      try {
        // noinspection JSValidateTypes
        const { currentPassword, newPassword, firstname, lastname } = this.formFields;
        await this.updateProfile({ currentPassword, newPassword, firstname, lastname });
        this.saved = true;
        setTimeout(() => (this.saved = false), 2000);
      } catch (e) {
        if (e.status === STATUS_CODES.FORBIDDEN) {
          this.invalidCurrentPassword = true;
        } else {
          throw e;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  color: var(--v-cool-grey-two-base);
}

.overlay::v-deep .v-overlay__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  // FIXME: code duplication with views/authenticatedapp/settings/Index.vue CSS .back-btn?
  .back-btn {
    position: absolute;
    display: flex;
    align-items: center;
    top: 45px;
    left: 45px;
    opacity: 0.5;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--v-cool-grey-base);
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}

.settings-container {
  padding: 0 12px; // Padding for tiny screens to have some breeding room around the app
  width: calc(436px + 2 * 12px); // Design says 436 for the inputs + padding of the wrapper
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
}

.avatar {
  margin-bottom: 36px;

  img {
    box-shadow: 0 0 15px var(--v-greyblue-base);
  }
}

.login-wrapper {
  margin-top: 45px;

  > * {
    margin-bottom: 36px;
  }

  .input-password {
    margin-bottom: calc(36px - 14px - 8px - 8px);
  }

  .link {
    color: var(--v-mid-blue-base);
  }
}

.save-button {
  margin-top: 60px;
}

.toggle-show {
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}
</style>
