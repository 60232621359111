import { get } from "lodash";
import moment from "moment";

// boolean
// string: exact or not
// date: range or single
// number: range or single

// Each filter is a factory, in go the spec and the selected value, out comes a predicate that takes a doc as input and
// returns true in case it passes the filter, false if not.

export function boolFilter(spec, value) {
  return (doc) => Boolean(get(doc, spec.field)) === Boolean(value.value);
}

export function choiceFilter(spec, value) {
  return (doc) => value.value.length === 0 || value.value.includes(get(doc, spec.field));
}

export function dateFilter(spec, filter) {
  if (typeof spec.range !== "boolean") {
    throw new Error("Filter error: number filter range prop is not boolean");
  }
  if (typeof spec.field !== "string") {
    throw new Error("Filter error: number filter field prop is not string");
  }

  if (spec.range) {
    return (doc) => {
      const docDate = get(doc, spec.field);
      const isYoungEnough =
        filter.value.min === null || filter.value.min === undefined || moment(filter.value.min).isBefore(docDate);
      const isOldEnough =
        filter.value.max === null || filter.value.max === undefined || moment(filter.value.max).isAfter(docDate);

      return isYoungEnough && isOldEnough;
    };
  } else {
    // Case of exact date, we check just if it's same day
    return (doc) => moment(get(doc, spec.field)).isSame(filter.value, "day");
  }
}

export function numberFilter(spec, filter) {
  // Validation
  if (typeof spec.range !== "boolean") {
    throw new Error("Filter error: number filter range prop is not boolean");
  }
  if (typeof spec.field !== "string") {
    throw new Error("Filter error: number filter field prop is not string");
  }

  if (spec.range) {
    const min = Number.isFinite(filter.value.min) ? filter.value.min : -Infinity;
    const max = Number.isFinite(filter.value.max) ? filter.value.max : +Infinity;

    return (doc) => get(doc, spec.field) > min && get(doc, spec.field) < max;
  } else {
    return (doc) => get(doc, spec.field) === filter.value;
  }
}

export function textFilter(spec, filter) {
  if (spec.exact) {
    return (doc) => get(doc, spec.field, "").toLowerCase() === filter.value.text.toLowerCase();
  } else {
    return (doc) => {
      return get(doc, spec.field, "").toLowerCase().includes(filter.value.text.toLowerCase());
    };
  }
}

export const FILTER_REGISTRY = {
  bool: boolFilter,
  category: choiceFilter,
  date: dateFilter,
  number: numberFilter,
  text: textFilter,
  tree: {},
};
