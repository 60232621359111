<template>
  <div>
    <h3 class="text-center">Success: Elapsed time</h3>
    <chart-doughnut :chart-data="chartData" />
  </div>
</template>

<script>
import { capitalize } from "lodash";
import ChartDoughnut from "@/components/ChartDoughnut.vue";

/**
 * The doughnut chart elapsed time
 */
export default {
  name: "DSStatsElapsed",
  components: {
    ChartDoughnut,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    chartData() {
      const { statistics } = this.data;

      if (!statistics?.successful || !statistics?.multilanguages || !statistics?.duplicates) {
        return {
          labels: [],
          datasets: [],
        };
      }

      const { successful, multilanguages, duplicates } = statistics;
      const elapsedTime = { successful, multilanguages, duplicates };

      const elapsedTimeCategories = Object.keys(elapsedTime);

      return {
        labels: elapsedTimeCategories.map((el) => capitalize(el)),
        datasets: [
          {
            label: "Elapsed",
            data: elapsedTimeCategories.map((el) => Math.round(statistics[el].elapsed)),
            backgroundColor: ["#6ad9a6", "#36a2eb", "#cc65fe", "#ffce56", "#CDDC39", "cyan", "yellow", "pink"],
          },
        ],
      };
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          reverse: false,
        },
      };
    },
  },
};
</script>
