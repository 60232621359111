<template>
  <div class="settings-wrap fill-height py-6">
    <router-link class="back-btn" :to="originRoute">
      <v-icon x-small left>$path</v-icon>
      back
    </router-link>
    <div class="container align-self-start ma-10 mx-14 px-14">
      <edit-permissions
        :name="subscriptionDetails.name"
        :current-users="ownerEmails"
        :saved="saved"
        :bar-request-error="barRequestError"
        :error-msg="errorMsg"
        :loading="loading"
        @saveChanges="handleSaveChanges"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import EditPermissions from "@/components/EditPermissions.vue";
import { shareSubWith, getSubscriptionDetails } from "@/store/actionTypes";
import { STATUS_CODES } from "@/utils/client.js";

const { mapActions, mapState } = createNamespacedHelpers("main");

/**
 * The subscription sharing page.
 */
export default {
  name: "ShareSub",
  components: {
    EditPermissions,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.originRoute = from.path;
    });
  },

  data() {
    return {
      selected: null,
      originRoute: "",
      loading: false,
      saved: false,
      rule: {
        emailRequired: this.RULES.required("Email is required"),
        validEmail: this.RULES.email("This doesn't look like a valid email"),
      },
      valid: true,
      formFields: [
        {
          email: "",
          deleted: false,
          readonly: false,
        },
      ],
      barRequestError: false,
      errorMsg: "",
    };
  },
  computed: {
    ...mapState({
      subscriptions: (state) => state.subscriptions,
      subscriptionDetails: (state) => state.subscriptionDetails,
    }),
    ownerEmails() {
      return this.subscriptionDetails.owners?.map((owner) => owner.email);
    },
  },
  watch: {
    selected: {
      handler(subId) {
        if (subId) {
          this.getSubscriptionDetails({ subId });
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({ shareSubWith, getSubscriptionDetails }),

    async handleSaveChanges({ emails, urlPrefix }) {
      // This will become erorr state, which is passed down, to trigger snackbar
      // We reset error state each time user trying to save
      // The snackbar state itself is responsible only for hiding itself
      this.barRequestError = false;
      this.loading = true;
      try {
        await this.shareSubWith({ emails, urlPrefix });

        this.saved = true;
        setTimeout(() => (this.saved = false), 2000);
      } catch (e) {
        if (e.status === STATUS_CODES.BADREQUEST) {
          this.barRequestError = true;
          this.errorMsg = e.data.detail;
        } else {
          throw e;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  min-width: 60%;
}
.settings-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  .back-btn {
    position: absolute;
    display: flex;
    align-items: center;
    top: 45px;
    left: 45px;
    opacity: 0.5;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--v-cool-grey-base);
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
