<template>
  <v-snackbar :value="currentValue" :timeout="-1" bottom>
    <slot />
    <BaseButton small :block="false" color="secondary" @click="handleClick">
      <slot name="buttonContent">Ok</slot>
    </BaseButton>
  </v-snackbar>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "TheSnackBar",
  components: {
    BaseButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: false,
    };
  },
  watch: {
    value: {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentValue = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleClick($event) {
      this.currentValue = false;
      this.$emit("input", this.currentValue);
    },
  },
};
</script>
