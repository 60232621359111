import Vue from "vue";
import initHelpHero from "helphero";

let hlp;

const helpHeroPlugin = {
  install: (Vue) => {
    if (process.env.VUE_APP_HELP_HERO_KEY) {
      hlp = initHelpHero(process.env.VUE_APP_HELP_HERO_KEY);
    } else {
      hlp = null;
    }
    Vue.prototype.$helpHero = hlp;
  },
};

Vue.use(helpHeroPlugin);

export default hlp;
