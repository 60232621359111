<template>
  <!-- eslint-disable prettier/prettier -->
  <div>
    <v-card-text v-if="hasHit" class="snippet-content text-left pl-0 pt-0 pr-10 content no-overflow">
      <!-- Ugly comments are necessary to get rid of the spaces in the middle of the text.-->
      <template v-for="(hitNode, i) of hitNodes">
        <span v-if="hitNode.startText" :key="`${hitCounter(i)}start`" :class="{'relative': i !== 0}">{{ hitNode.startText }}</span><!--
    --><span :key="`${hitCounter(i)}init`" class="init-row-coloring absolute" :class="{selected: isSelected(i)}" /><!--
    --><span ref="counter" :key="`${hitCounter(i)}counter`" class="match-counter absolute" :class="{selected: isSelected(i)}">{{ hitCounter(i) }}</span><!--
    --><span :key="`${hitCounter(i)}box`" class="relative parent"><!--
      --><span :key="`${hitCounter(i)}highlight`" class="match-box absolute" :class="{ selected: isSelected(i) }" /><!--
      --><span class="relative">{{ hitNode.highlightedText }}</span><!--
    --></span><!--
    --><span :key="`${hitCounter(i)}end`" class="absolute end-row-coloring" /><!--
    --><span v-if="hitNode.endText" :key="`${hitCounter(i)}close`" class="relative">{{ hitNode.endText }}</span>
      </template>
    </v-card-text>
    <v-card-text v-else ref="no-hits-text">
      <span> {{ snippet.text }} </span>
    </v-card-text>
  </div>
  <!-- eslint-enable -->
</template>
<script>
/**
 * The main highlight component, that renders text snippet (page), hit counter.
 */
export default {
  name: "TextHit",
  props: {
    /**
     * Text snippet, should contain text, hit array and hitCount array
     */
    snippet: {
      type: Object,
      default: () => ({
        text: "",
        hits: [],
      }),
    },
    /**
     * Currently selected hit node
     */
    currentSelection: {
      type: Number,
      default: 0,
    },
    /**
     * Total hits in snippet/document.
     */
    totalHits: {
      type: [Number, String],
      default: 0,
    },
  },

  computed: {
    hasHit() {
      if (!this.snippet) {
        return;
      }

      return this.snippet.hits && this.snippet.hits.length > 0;
    },
    /**
     * Converts collection of hits
     * (ex. with one hit [{ range: [5,10], sec: 9 }]
     * two hits:[
          { range: [30, 80], sec: 8 },
          { range: [160, 220], sec: 9 }
        ])
     * to array of text nodes, that will be put in template spans
     *
     * One hit example:
     * [{ range: [5,10], sec: 9 }]
     * converted to
     * [{
     *   startText         # text from 10 to 15, white
     *   highlightedText,  # text from 15 to 20, green, with 9/10
     *   endText           # text from 20 to end, white
     * }]
     *
     * Two hits example:
     * [
          { range: [30, 80], sec: 8 },
          { range: [160, 220], sec: 9 }
        ]
     * converted to
     * [{
     *   startText         # text from 0 to 5, white
     *   highlightedText   # text from 5 to 10, green, with 8/10
     * }, {
     *   startText         # text from 10 to 15, white
     *   highlightedText,  # text from 15 to 20, green, with 9/10
     *   endText           # text from 20 to end, white
     * }]
     */
    hitNodes() {
      const { text } = this.snippet;

      let hitsArray = this.snippet.hits.slice();

      const nodes = [];
      let previousNodeEnd = 0;
      let start, end;

      for (let hit of hitsArray) {
        [start, end] = hit.range;

        const startText = text.slice(previousNodeEnd, start);
        const highlightedText = text.slice(start, end);

        let endText;
        if (hitsArray.indexOf(hit) === hitsArray.length - 1) {
          endText = text.slice(end, text.length);
        }

        nodes.push({ startText, highlightedText, endText });
        previousNodeEnd = end;
      }

      return nodes;
    },
  },
  methods: {
    /**
     * String for hit counter, ex: 2/10, 15/132
     * @param i integer
     * @returns string Example: "12/15"
     */
    hitCounter(i) {
      const currentHit = this.snippet.hits[i].count;

      return `${currentHit + 1}/${this.totalHits}`;
    },

    isSelected(i) {
      const currentHit = this.snippet.hits[i].count;

      return currentHit === this.currentSelection;
    },
  },
};
</script>
<style scoped lang="scss">
$highlight-colour: var(--v-primary-base);
$active-highlight-colour: var(--v-primary-base);
$active-highlight-opacity: 0.4;

.snippet-content {
  /*
   This margin left is needed for to fix distance between the text and the left side of the card
   and to introduce the spacing between the highlights and the text on the left hand side as well.
   */
  margin-left: 10px;
  font-weight: normal;
  //letter-spacing: -0.15px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.parent {
  display: block;
  margin-bottom: 2px;
}

.init-row-coloring {
  display: block;
  height: 1.6em;
  width: 9001px;
  opacity: 0.12;
  background: $highlight-colour;
  &.selected {
    opacity: $active-highlight-opacity;
    background: $active-highlight-colour;
  }
}
.end-row-coloring {
  height: 1.6em;
  width: 9001px;
  opacity: 1;
  background: white;
}
.match-counter {
  display: block;
  color: $highlight-colour;
  font-weight: bold;
  font-size: 13px;
  height: 18px;
  background-color: white;
  margin-top: 2px;
  right: -47px;
  z-index: 1;
  line-height: normal;

  &.selected {
    color: $active-highlight-colour;
  }
}

.match-box {
  opacity: 0.12;
  width: 9001px;
  left: -1500px;
  bottom: 0;
  top: 1.6em;
  background: $highlight-colour;
  &.selected {
    opacity: $active-highlight-opacity;
    background: $active-highlight-colour;
  }
}

.content {
  position: relative;
  height: 100%;
}
</style>
