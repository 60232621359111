<template>
  <div class="fill-height">
    <template v-if="showOpenError">
      <!-- Hide skeleton in case of error -->
    </template>
    <template v-else-if="!loading">
      <document-content
        ref="document"
        v-bind="document"
        :current-selection="currentHit"
        :current-score-selection="currentScoreHit"
        :highest-score="highestScore"
        :highest-score-page="highestScorePageNumber"
        :total-hits="totalHits"
        :document-order="documentOrder"
        @close="handleClose"
        @feedback="saveDocumentFeedback"
      />
    </template>
    <v-row v-else justify="center" class="fill-height">
      <div class="skeleton ma-3">
        <v-skeleton-loader
          type="date-picker-options,list-item,article@5"
          :tile="true"
          class="fill-height fill-width"
          elevation="3"
        />
      </div>
    </v-row>
    <document-footer
      v-model="currentHit"
      :total="totalHits"
      :disable-download="downloadNotFound"
      :download-preview="downloadPreview"
      :downloading="downloading"
      :loading="loading"
      @download="handleDownload"
      @update:scrollOrder="documentOrder = $event"
    />
    <snack-bar v-model="showOpenError" @input="$router.push({ name: 'searchResults' })">
      Could not open the document (document not found).
      <template slot="buttonContent">Go back</template>
    </snack-bar>
    <snack-bar v-model="showDownloadNotFoundError">
      Could not download the original document (document not found).
    </snack-bar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import {
  downloadDocument,
  downloadDocumentAfterOAuthFailed,
  openResultDocument,
  openResultDocumentAfterOAuthFailed,
  saveDocumentFeedback,
} from "@/store/actionTypes.js";
import DocumentContent from "@/components/DocumentContent.vue";
import DocumentFooter from "@/components/DocumentFooter.vue";
import SnackBar from "@/components/SnackBar.vue";
import { EVENT_TYPES, logEvent } from "@/plugins/amplitude.js";
import { STATUS_CODES } from "@/utils/client.js";

const { mapState, mapActions } = createNamespacedHelpers("main");
const { mapState: mapAuthState, mapActions: mapAuthActions } = createNamespacedHelpers("auth");

/**
 * The home page.
 */
export default {
  name: "Document",

  components: {
    DocumentContent,
    DocumentFooter,
    SnackBar,
  },
  props: {
    /**
     * The document unique id, used to fetch data
     */
    id: {
      type: String,
      default: "",
    },
    qid: {
      type: String,
      default: "",
    },
    indexId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      documentOrder: false,
      currentHit: null,
      currentScoreHit: null,
      showDownloadNotFoundError: false,
      downloading: false,
      downloadNotFound: false,
      showOpenError: false,
    };
  },

  computed: {
    ...mapState({
      document(state) {
        // Do not use anonymous function, it will result in 'this' being bound incorrectly
        return state.result.cache[this.id];
      },
      loading(state) {
        // Do not use anonymous function, it will result in 'this' being bound incorrectly
        return !state.result.cache.hasOwnProperty(this.id);
      },
    }),
    totalPages() {
      return this.document ? this.document.totalPages : 0;
    },
    totalHits() {
      return this.document ? this.document.totalHits : 0;
    },
    highestScore() {
      if (!this.document) {
        return;
      }
      return Math.max(...this.document.pages.map((page) => page.score));
    },
    highestScorePageNumber() {
      if (!this.document) {
        return;
      }
      return this.document.pages.findIndex((el) => el.score === this.highestScore);
    },
    downloadPreview() {
      // const name = this.$store.state.main.selectedIndexDetails.name || "";
      // return name.toLowerCase().indexOf("engie") === -1;
      return false;
    },
  },

  watch: {
    id: {
      async handler(id) {
        const { indexId, qid } = this;
        try {
          await this.openResultDocument({ indexId, qid, id });

          // observing cached document change is possible from observing id
          // if there is no hits in page, set -1 (analogy with findIndex)
          this.currentHit = 0;
        } catch (e) {
          if (e.status === STATUS_CODES.NOTFOUND) {
            this.showOpenError = true;
          } else if (e.status === STATUS_CODES.FORBIDDEN) {
            // TODO: check if it is indeed an OAuth permission mismatch or whether it was something else.
            await this.openResultDocumentAfterOAuthFailed({
              indexId: indexId,
              queryId: qid,
              documentId: id,
              tenantId: e.response.data.detail.tenant,
            });
          } else {
            throw e;
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      downloadDocument,
      openResultDocument,
      saveDocumentFeedback,
    }),
    ...mapAuthActions({
      downloadDocumentAfterOAuthFailed,
      openResultDocumentAfterOAuthFailed,
    }),
    handleClose() {
      logEvent(EVENT_TYPES.DOC_CLOSE, { id: this.id });
      this.$router.push({
        name: "searchResults",
        params: { qid: this.qid, indexId: this.indexId },
      });
    },
    async handleDownload() {
      this.downloading = true;
      const { indexId, qid, id } = this;
      try {
        await this.downloadDocument({ indexId, qid, id });
      } catch (e) {
        if (e.status === STATUS_CODES.NOTFOUND) {
          this.showDownloadNotFoundError = true;
          this.downloadNotFound = true;
          // TODO: check if it is indeed an OAuth permission mismatch or whether it was something else.
        } else if (e.status === STATUS_CODES.FORBIDDEN) {
          await this.downloadDocumentAfterOAuthFailed({
            indexId: indexId,
            queryId: qid,
            documentId: id,
            tenantId: e.response.data.detail.tenant,
          });
        } else {
          throw e;
        }
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>

<style scoped>
.fill-width {
  width: 100%;
}

.skeleton {
  width: 100%;
  height: 100%;
  padding: 26px 0 48px 0;
  display: flex;
  flex: 0 1 908px;
  flex-flow: column nowrap;
}
</style>
