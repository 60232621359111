<template>
  <v-row justify="center" align="center" class="progress-bar">
    <v-container>
      <v-progress-linear
        :active="active"
        :background-opacity="opacity"
        :bottom="bottom"
        :buffer-value="buffer"
        :color="color"
        :height="height"
        :indeterminate="indeterminate"
        :query="query"
        :rounded="rounded"
        :stream="stream"
        :striped="striped"
        :top="top"
        :value="currentValue"
        :max-amount="maxAmount"
        :current-amount="currentAmount"
        :show-textual-progress="showTextualProgress"
      >
        <b v-if="showTextualProgress">{{ progressText }}</b>
      </v-progress-linear>
    </v-container>
  </v-row>
</template>

<script>
/**
 * Progress bar for the query counter
 */
export default {
  name: "ProgressBarQuery",

  props: {
    /**
     *  Reduce the height to 0, hiding component
     */
    active: {
      type: Boolean,
      default: true,
    },
    /**
     *  Background opacity, if null it defaults to 0.3 if background color is not specified or 1 otherwise
     */
    opacity: {
      type: [Number, String],
      default: 0.3,
    },
    /**
     *  Aligns the component towards the bottom.
     */
    bottom: {
      type: Boolean,
      default: false,
    },
    /**
     *  The percentage value for the buffer
     */
    buffer: {
      type: [Number, String],
      default: 100,
    },
    /**
     *  Applies specified color to the control
     */
    color: {
      type: String,
      default: "primary",
    },
    /**
     *  Applies position: fixed to the component.
     */
    fixed: {
      type: Boolean,
      default: false,
    },
    /**
     *  Sets the height for the component.
     */
    height: {
      type: [Number, String],
      default: 25,
    },
    /**
     *  Constantly animates, use when loading progress is unknown.
     */
    indeterminate: {
      type: Boolean,
      default: false,
    },
    /**
     *  Animates like indeterminate prop but inversee
     */
    query: {
      type: Boolean,
      default: false,
    },
    /**
     *  Adds a border radius to the progress component
     */
    rounded: {
      type: Boolean,
      default: true,
    },
    /**
     *  An alternative style for portraying loading that works in tandem with buffer-value
     */
    stream: {
      type: Boolean,
      default: false,
    },
    /**
     *  Adds a stripe background to the filled portion of the progress componentse
     */
    striped: {
      type: Boolean,
      default: false,
    },
    /**
     *  Aligns the content towards the top.
     */
    top: {
      type: Boolean,
      default: false,
    },
    /**
     *  The percentage value for current progress (= currentAmount / maxAmount * 100)
     */
    value: {
      type: [Number, String],
      default: 0,
    },
    /**
     *  max amount value
     */
    maxAmount: {
      type: [Number, String],
      default: 100,
    },
    /**
     *  current amount value
     */
    currentAmount: {
      type: [Number, String],
      default: 0,
    },
    /**
     *  show the currentAmount / maxAmount as text inside progress bar
     */
    showTextualProgress: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentValue() {
      return (Math.min(this.currentAmount, this.maxAmount) / this.maxAmount) * 100;
    },
    progressText() {
      const suffix = this.currentAmount === 1 && !this.maxAmount ? "query" : "queries";
      return `${this.currentAmount} ${this.maxAmount ? "/ " + this.maxAmount : ""} ${suffix}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.progress-bar {
  max-width: 100%;
  margin: 0;
}
</style>
