<template>
  <div class="monitoring-content">
    <v-row>
      <v-col v-for="(stageData, stage) in jobStats" :key="stage" :cols="6">
        <h3 class="text-center">
          {{ `${stage}` }}
        </h3>
        <monitoring-graph :chart-data="chartData(stageData)" :styles="chartStyle" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MonitoringGraph from "@/components/MonitoringGraph.vue";

/**
 * FIXME
 */
export default {
  name: "MonitoringContent",
  components: {
    MonitoringGraph,
  },
  props: {
    /**
     * FIXME
     */
    jobStats: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      backgroundColors: ["green", "red", "blue", "cyan", "orange", "purple", "olive", "yellow", "indigo"],
    };
  },
  computed: {
    chartStyle() {
      return {
        height: "320px",
        position: "relative",
      };
    },
  },
  methods: {
    chartData(stageData) {
      let workers = Object.keys(stageData);
      return {
        /*
        Uses the timestamp of when this measurement was fetched.
        Timestamp is equal for all workers, so just use the first worker from the list.
         */
        labels: stageData[workers[0]].map((x) => x.timestamp),
        datasets: workers.map((worker, index) => ({
          backgroundColor: this.backgroundColors[index],
          label: worker,
          data: stageData[worker].map((x) => x.units_of_work),
          fill: true,
        })),
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
