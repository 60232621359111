// root
export const RESET = "RESET";
export const TOGGLE_ERROR_DIALOG = "TOGGLE_ERROR_DIALOG";
export const SET_EVENT_ID = "SET_EVENT_ID";

// main
export const SET_QUERY = "SET_QUERY";
export const SET_RESULT = "SET_RESULT";
export const CACHE_DOCUMENT = "CACHE_DOCUMENT";
export const UPDATE_RESULT_DOCUMENT = "UPDATE_RESULT_DOCUMENT";
export const UPDATE_USER_FILTER = "UPDATE_USER_FILTER";
export const CLEAR_RESULT = "CLEAR_RESULT";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const LOADING_RESULTS = "LOADING_RESULTS";
export const TOGGLE_FILTERS = "TOGGLE_FILTERS";
export const SET_INDICES = "SET_INDICES";
export const SET_LAST_OPENED = "SET_LAST_OPENED";
export const SET_INDEX_DETAILS = "SET_INDEX_DETAILS";
export const SET_OFFSET = "SET_OFFSET";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_SCROLL_POSITION = "SET_SCROLL_POSITION";
export const RESET_ERROR_STATE = "RESET_ERROR_STATE";
export const SET_ERROR_STATE = "SET_ERROR_STATE";
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const SET_SUBSCRIPTION_DETAILS = "SET_SUBSCRIPTION_DETAILS";
export const SET_SP_USERS = "SET_SP_USERS";
export const SET_SP_GROUPS = "SET_SP_GROUPS";
export const SET_SP_SITES = "SET_SP_SITES";

// auth
export const SET_PROFILE = "SET_PROFILE";
export const SHOW_FORCE_LOGOUT_SNACKBAR = "SHOW_FORCE_LOGOUT_SNACKBAR";
