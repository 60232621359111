<template>
  <ul class="mt-3 no-bullets">
    <li>Front-end: {{ version }} - {{ build_env }}</li>
    <li v-if="!loading">
      Back-end ({{ backend_url }}): {{ backend_version }} ({{ backend_timing }}ms) - {{ health_response }} ({{
        health_timing
      }}ms)
    </li>
    <li v-else class="d-flex align-end">
      <span class="mr-1">Back-end:</span><v-skeleton-loader type="text" width="250" />
    </li>
  </ul>
</template>

<script>
import { awback } from "@/plugins/awback";
import version from "@/version";

export default {
  name: "Version",
  data() {
    return {
      version,
      loading: true,
      backend_version: undefined,
      backend_timing: undefined,
      health_response: undefined,
      health_timing: undefined,
      build_env: process.env.VUE_APP_ENV,
      backend_url: process.env.VUE_APP_BASE_URL,
    };
  },
  async mounted() {
    try {
      try {
        const start = new Date();
        this.backend_version = await awback.version();
        this.backend_timing = new Date() - start;
      } catch (e) {
        console.error(e);
      }

      try {
        const start = new Date();
        this.health_response = await awback.health();
        this.health_timing = new Date() - start;
      } catch (e) {
        console.error(e);
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.no-bullets li {
  list-style: none;
}
</style>
