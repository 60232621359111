<template>
  <div>
    <v-select
      v-if="datasources.length > 1"
      v-model="selectedDs"
      :items="datasources"
      class="select mt-10"
      label="Datasources"
      multiple
      item-text="name"
      item-value="id"
    >
      <template v-slot:prepend-item>
        <v-checkbox
          label="Select all"
          color="inherit"
          class="pl-4 select-all"
          :false-value="false"
          :true-value="true"
          :indeterminate="someSelected"
          :value="allSelected"
          @click.native="toggle"
        />

        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
    <div v-if="consolidatedData.id" class="d-flex flex-row flex-wrap">
      <div class="d-flex flex-row flex-wrap cont">
        <div class="flex flex-grow-1 brd">
          <DSStatsErrorExtensionsDoughnut :data="consolidatedData" />
        </div>
        <div class="flex flex-grow-1 brd">
          <DSStatsOverviewSuccessVsErrors :data="consolidatedData" />
        </div>
        <div class="flex flex-grow-1 brd">
          <DSStatsFileTypes :data="consolidatedData" />
        </div>
        <div class="flex flex-grow-1 brd">
          <DSStatsLanguages :data="consolidatedData" />
        </div>
        <div class="flex flex-grow-1 brd">
          <DSStatsElapsed :data="consolidatedData" />
        </div>
        <div class="flex flex-grow-1 brd">
          <DSStatsNumberOfFiles :data="consolidatedData" />
        </div>
        <div class="flex flex-grow-1 brd">
          <DSStatsNumberOfPages :data="consolidatedData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deepSum } from "@/utils/deepSum";

import DSStatsErrorExtensionsDoughnut from "@/components/DSStatsErrorExtensionsDoughnut.vue";
import DSStatsOverviewSuccessVsErrors from "@/components/DSStatsOverviewSuccessVsErrors.vue";
import DSStatsFileTypes from "@/components/DSStatsFileTypes.vue";
import DSStatsLanguages from "@/components/DSStatsLanguages.vue";
import DSStatsNumberOfFiles from "@/components/DSStatsNumberOfFiles.vue";
import DSStatsNumberOfPages from "@/components/DSStatsNumberOfPages.vue";
import DSStatsElapsed from "@/components/DSStatsElapsed.vue";

export default {
  name: "Dashboard",
  components: {
    DSStatsErrorExtensionsDoughnut,
    DSStatsOverviewSuccessVsErrors,
    DSStatsFileTypes,
    DSStatsElapsed,
    DSStatsLanguages,
    DSStatsNumberOfFiles,
    DSStatsNumberOfPages,
  },
  props: {
    datasources: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedDs: [],
    };
  },
  computed: {
    someSelected() {
      return this.selectedDs.length > 0 && !this.allSelected;
    },
    allSelected() {
      return this.selectedDs.length === this.datasources?.length;
    },

    consolidatedData() {
      const selectedDsData = this.datasources.filter((ds) => this.selectedDs.includes(ds.id));

      return deepSum(selectedDsData);
    },
  },

  watch: {
    datasources: {
      handler(ds) {
        if (ds.length === 1) {
          this.selectedDs = [ds[0].id];
        }
      },
      immediate: true,
    },
  },

  methods: {
    toggle() {
      if (this.someSelected || this.allSelected) {
        this.selectedDs = [];
      } else {
        this.selectedDs = this.datasources.map((ds) => ds.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 70%;
  margin: auto;
}
.select-all::v-deep {
  .v-input--selection-controls__input {
    margin-right: 36px;
  }
  /* label {
    color: black;
  } */
}

.cont {
  width: 100%;
  height: 500px;
  justify-content: center;
}
.brd {
  margin-top: 40px;
  min-width: 460px;
  max-width: 460px;
}
</style>
