<template>
  <base-button :loading="loading" :disabled="disabled" :block="true" color="primary" x-large @click="onClick">
    <div v-if="oauthType === OAUTH_MICROSOFT">
      <v-icon left large>$microsoft_logo</v-icon>
      Sign in with work account
    </div>
    <div v-if="oauthType === OAUTH_GOOGLE">
      <v-icon left large>$google_logo</v-icon>
      Sign in with work account
    </div>
  </base-button>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import { OAuthTypes } from "@/utils/const";

/**
 * Component which user can click to perform profile settings update.
 */
export default {
  name: "ButtonOAuthLogin",
  components: { BaseButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    oauthType: {
      type: String,
      default: "",
      validator: (val) => ["microsoft", "google"].includes(val),
    },
  },
  data() {
    return {
      OAUTH_MICROSOFT: OAuthTypes.MICROSOFT,
      OAUTH_GOOGLE: OAuthTypes.GOOGLE,
    };
  },
  methods: {
    onClick() {
      /**
       * Emitted when the button is clicked.
       */
      this.$emit("click");
    },
  },
};
</script>
