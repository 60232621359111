<template>
  <v-app id="app">
    <error-dialog :show="showErrorDialog" @submit="submitErrorDialog" @dismiss="hideErrorDialog" />
    <router-view />
  </v-app>
</template>

<script>
import ErrorDialog from "@/components/ErrorDialog.vue";
import { hideErrorDialog, submitErrorDialog } from "@/store/actionTypes";
import { mapActions, mapState } from "vuex";
import { HandledError } from "@/plugins/awback";

export default {
  name: "App",
  components: {
    ErrorDialog,
  },
  computed: {
    ...mapState({
      showErrorDialog: (state) => state.showErrorDialog,
    }),
  },
  errorCaptured(err) {
    // Used to filter out errors that are already handled.
    console.log(err);
    if (err instanceof HandledError) {
      console.debug("Swallowed error in App!");
      console.debug(err);
      return false; // Stops further propagation
    } else {
      return true;
    }
  },
  methods: {
    ...mapActions({
      submitErrorDialog,
      hideErrorDialog,
    }),
  },
};
</script>

<style lang="scss">
@import "~@/assets/fonts/bebasneue/bebasneue.css";
@import "~@/assets/fonts/montserrat/montserrat.css";

#app {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--v-pale-grey-base);

  font-family: Montserrat, "Avenir", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.15px;
  color: var(--dark-grey);
}

.small-text-grey {
  font-size: 0.875rem;
  color: var(--v-steel-base);
  letter-spacing: -0.15px;
  line-height: normal;
}

@mixin header {
  font-family: Bebas Neue, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #202227;
}

h1 {
  @include header();
  font-size: 54px;
  letter-spacing: 0.48px;
}

h2 {
  @include header();
  font-size: 42px;
  letter-spacing: 0.37px;
}

h3 {
  @include header();
  font-size: 32px;
  letter-spacing: 0.28px;
}

h4 {
  @include header();
  font-size: 26px;
  letter-spacing: 0.23px;
}

.body-text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.15px;
  color: var(--v-dark-grey-base);
}
</style>
