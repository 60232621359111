import Vue from "vue";
import RULES from "@/models/ValidationRules";

const validationRules = {
  install() {
    Vue.prototype.RULES = RULES;
  },
};

Vue.use(validationRules);

export default validationRules;
