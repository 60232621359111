<template>
  <!--  :shrink-on-scroll="isMobile"-->
  <v-app-bar v-resize="resize" color="white" :height="smallScreenHeight ? '84px' : '108px'" app fixed clipped-right>
    <div class="toolbar-container">
      <div :class="['main-logo full', disableSearch ? 'no-search' : '']">
        <svg-icon block name="main_logo" @click.native="navigateHome" />
      </div>
      <div v-if="!disableSearch" class="main-logo trimmed">
        <svg-icon color="secondary" block name="main_logo_no_text" @click.native="navigateHome" />
      </div>
      <div v-if="isSubscriptionsRoute" class="d-flex form-container">
        Subscriptions details for {{ subscriptionName }}
      </div>
      <div v-else-if="!disableSearch" class="form-container">
        <v-form class="d-flex search tour-main-search-bar" @submit.prevent="onSubmit">
          <input-search
            ref="input-search"
            v-model="queryString"
            :disabled="disableSearch"
            :reason="reason"
            :index-name="indexName"
            class="input"
            @clear="handleClear"
          />
          <!-- After submitting search query language menu is being focused automatically -->
          <!-- To hack it search button and language button are swapped -->
          <!-- using css order prop in language-button and search-button classes respectively-->
          <button-search class="input language-button" :disabled="disableSearch" />
          <button-language
            v-model="selectedLanguage"
            class="input search-button"
            :disabled="disableSearch"
            :languages="languages"
          />
        </v-form>
      </div>

      <div class="profile-info tour-profile-menu full">
        <v-tooltip v-if="showShareButton" top open-delay="1000">
          <template #activator="{ on }">
            <v-btn color="primary" icon class="mx-2" @click="onShare" v-on="on">
              <v-icon large>share</v-icon>
            </v-btn>
          </template>
          <span>Edit {{ isSubscriptionsRoute ? "subscription" : "index" }} permissions</span>
        </v-tooltip>
        <div class="d-flex" @click="toggleDrawer">
          <avatar-user :size="50" :src="avatar" :firstname="firstname" :lastname="lastname" />
          <div class="details">
            <p class="body-2">
              {{ `${firstname} ${lastname}` }}
            </p>
            <svg-icon name="dropdown" tiny />
          </div>
        </div>
      </div>
    </div>
    <v-app-bar-nav-icon class="profile-info trimmed align-self-center" @click="toggleDrawer" />
  </v-app-bar>
</template>

<script>
import debounce from "lodash/debounce";
import ButtonSearch from "@/components/ButtonSearch.vue";
import ButtonLanguage from "@/components/ButtonLanguage.vue";
import InputSearch from "@/components/InputSearch.vue";
import AvatarUser from "@/components/AvatarUser.vue";
import SvgIcon from "@/components/SvgIcon";

/**
 * Component which is located on the header, and contains useful tools for user.
 */
export default {
  name: "AppToolbar",

  components: {
    SvgIcon,
    AvatarUser,
    InputSearch,
    ButtonSearch,
    ButtonLanguage,
  },

  props: {
    /**
     * Query string (saved in storage)
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Url to the avatar image of the logged in user.
     */
    avatar: {
      type: String,
      default: "",
    },
    /**
     * First name of the logged in user.
     */
    firstname: {
      type: String,
      default: "",
    },
    /**
     * Last name of the logged in user.
     */
    lastname: {
      type: String,
      default: "",
    },
    /**
     * Search is disabled.
     */
    disableSearch: {
      type: Boolean,
      default: false,
    },
    /**
     * The reason why the search is disabled. Will be displayed as a hint to the user.
     */
    reason: {
      type: String,
      default: "",
    },
    /**
     * The selected index/data collection to search through
     */
    indexName: {
      type: String,
      default: "",
    },
    /**
     * The list of languages available for selection
     */
    languages: {
      type: Array,
      default: () => [],
    },
    /**
     * The language to apply to index search
     */
    storeLanguage: {
      type: String,
      default: "",
    },
    subscriptionDetails: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      debouncedSubmit: debounce(
        () => {
          this.$emit("search", this.queryString);
        },
        500,
        {
          // When user click multiple times first click will be emited, others not
          leading: true,
          trailing: false,
        }
      ),
      windowHeight: null,
    };
  },

  computed: {
    selectedLanguage: {
      get() {
        return this.storeLanguage;
      },
      set(lang) {
        this.selectLanguage(lang);
      },
    },
    showShareButton() {
      const { indexName, subscriptionName } = this;
      return (indexName || subscriptionName) && !this.$route.path.endsWith("share");
    },
    subscriptionName() {
      return this.subscriptionDetails?.name && `"${this.subscriptionDetails.name}"`;
    },
    isSubscriptionsRoute() {
      return this.$route.name === "subscriptionStatistics";
    },
    queryString: {
      get() {
        return this.value;
      },
      set(v) {
        /**
         * Emits input event, required for v-model b-directional data flow on higher level
         */
        this.$emit("input", v);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    smallScreenHeight() {
      return this.windowHeight < 1000;
    },
  },

  mounted() {
    // TODO: set selectionStart and selectionEnd on language filter update
    if (!this.disableSearch) {
      const mySearch = this.$refs["input-search"]?.$refs["search-field"];
      if (mySearch) {
        setTimeout(() => mySearch.focus(), 100);
      }
    }
  },

  methods: {
    onShare() {
      if (this.isSubscriptionsRoute) {
        this.$emit("shareSubscription");
      } else {
        this.$emit("shareIndex");
      }
    },
    handleClear() {
      /**
       * Emitted when the search input is cleared.
       */
      this.$emit("clear");
    },
    navigateHome() {
      // Second argument empty callback is to prevent "Navigation To Same Route error"
      this.$router.push({ name: "home" }, () => {});
    },
    onSubmit() {
      /**
       * Emitted on submission of the search form. Payload will be the query string.
       */
      this.debouncedSubmit();
    },
    toggleDrawer() {
      /**
       * Emitted when the drawer should be opened or closed.
       */
      this.$emit("toggleDrawer");
    },
    resize() {
      this.windowHeight = window.innerHeight;
    },
    selectLanguage(data) {
      this.$emit("selectLanguage", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.toolbar-container {
  display: flex;

  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .main-logo {
    align-self: center;
    justify-self: flex-start;
    color: var(--v-secondary-base);

    &.full {
      button::v-deep {
        width: 100%;
        // nested svg should be full width (svgicon block prop can't be passed)
        * {
          width: auto;
        }
      }
    }

    &.trimmed {
      display: none;
    }
  }

  .form-container {
    flex: 1 1 908px;
    align-items: stretch;
    justify-content: center;
    max-width: 908px;
    margin: 0 20px;

    .search {
      /*flex-grow: 100;*/
      /*max-width: 908px;*/
      /*width: 100%;*/
    }

    .search-button {
      order: 3;
    }

    .language-button {
      order: 2;
    }
  }

  .profile-info.full {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: center;

    .details {
      display: flex;
      align-items: center;
    }

    &:hover {
      cursor: pointer;
    }

    .body-2 {
      margin: 0 2px;
    }
  }

  .main-logo.full,
  .profile-info.full {
    max-width: 272px;
    min-width: 160px;
    flex: 1 10 150px;
  }

  .main-logo.trimmed {
    flex: 1 10 150px;

    button {
      width: 50px;
    }
  }
}

.profile-info.trimmed {
  display: none;
}

//@media only screen and (min-width: #{map-get($grid-breakpoints, 'sm')}) and (max-width: #{map-get($grid-breakpoints, 'md')}) {
@media only screen and (min-width: #{map-get($grid-breakpoints, 'sm')}) and (max-width: 1400px) {
  .toolbar-container {
    .main-logo.full:not(.no-search) {
      display: none;
    }

    .main-logo.trimmed {
      display: initial;
      button::v-deep {
        // nested svg should be full width (svgicon block prop can't be passed)
        * {
          width: auto;
        }
      }
    }

    .profile-info.full {
      flex: 1 10 150px;
      min-width: unset;

      .details {
        display: none;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .toolbar-container {
    flex-flow: column nowrap;
    justify-content: space-evenly;

    .main-logo.full {
      flex: 0 0 auto;
    }

    .form-container {
      flex: 0 0 auto;

      ::v-deep .input {
        height: 36px;
      }
    }

    .profile-info.full {
      display: none;
    }
  }

  .profile-info.trimmed {
    display: initial;
  }
}
</style>
