import router from "@/router";
import { Client, STATUS_CODES } from "@/utils/client";
import AuthenticatedApp from "@/views/authenticatedapp/Index.vue";

const key = process.env.VUE_APP_AW_API_KEY;
const baseUrl = process.env.VUE_APP_BASE_URL;

export class HandledError {
  constructor(reason, error) {
    this.reason = reason;
    this.error = error;
  }
}

class AWBack extends Client {
  async callBackend(config) {
    try {
      return await super.callBackend(config);
    } catch (e) {
      // Unauthorized errors usually means the user's session was expired => redirect to login if trying to access an
      // Authenticated route, except for when accessing the root route ('/'), in which case this call was generated by
      // the navigation guard and we just need to propagate the error and let the navigation guard handle it.
      const { matched, name } = router.currentRoute;

      const isAuthenticatedRoute = matched.some((el) => el.components.default === AuthenticatedApp);
      const isRootRoute = name == null; // Base '/' route has no name
      if (e.status === STATUS_CODES.UNAUTHORIZED && isAuthenticatedRoute && !isRootRoute) {
        awback.clearAuth();
        await router.push({ name: "login" });

        throw new HandledError("Unauthorized called to backend => redirected to login page", e);
      } else {
        throw e;
      }
    }
  }
}

const awback = new AWBack(key, baseUrl);

export { awback };
