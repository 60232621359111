<template>
  <div>
    <h3 class="text-center">General Stats errors</h3>
    <chart-doughnut :chart-data="chartData" />
  </div>
</template>

<script>
import { chartsPalette } from "@/utils/chartsPalette";
import { capitalize } from "lodash";
import ChartDoughnut from "@/components/ChartDoughnut.vue";

/**
 * The errors charts
 */
export default {
  name: "DSStatsOverviewSuccessVsErrors",
  components: {
    ChartDoughnut,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      order: [
        "successful",
        "duplicates",
        "ocr",
        "language_error",
        "empty",
        "unsupported_extension",
        "unsupported_languages",
        "unprocessable",
      ],
      datasets: [],
    };
  },

  computed: {
    chartData() {
      const { statistics } = this.data;

      if (!statistics) {
        return {
          labels: [],
          datasets: [],
        };
      }

      const {
        successful,
        duplicates,
        ocr,
        language_error,
        empty,
        unsupported_extension,
        unsupported_languages,
        unprocessable,
      } = statistics;

      const data = this.order.map((key) => statistics[key].count);

      return {
        labels: this.order.map((el) => capitalize(el).replace("_", " ")),
        datasets: [
          {
            label: "Main",
            data,
            backgroundColor: chartsPalette,
          },
        ],
      };
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
};
</script>
