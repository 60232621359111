<template>
  <dashboard :datasources="datasources" />
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Dashboard from "@/components/Dashboard.vue";

const { mapState } = createNamespacedHelpers("main");

/**
 * Responsible for loading the list of accessible indices is available in the store.
 */
export default {
  name: "IndexDashboard",
  components: { Dashboard },
  computed: {
    ...mapState({
      datasources: (state) => state.selectedIndexDetails.datasources,
    }),
  },
};
</script>
