<template>
  <div />
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { logout } from "@/store/actionTypes";

const { mapActions } = createNamespacedHelpers("auth");
/**
 * The logout 'page'.
 * Not really a page since it is an empty component that does what it needs to do in a lifecycle
 * hook and immediately redirects afterwards. Main reason to do this in a component and not as a
 * callback from a button or so is that support manually browsing to the route serving this
 * component.
 */
export default {
  name: "Logout",
  created() {
    this.logoutWithRedirect();
  },
  methods: {
    ...mapActions({
      logout,
    }),
    async logoutWithRedirect() {
      await this.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped />
