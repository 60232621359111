<template>
  <v-navigation-drawer
    v-if="userRole"
    :value="currentValue"
    width="466"
    temporary
    app
    right
    clipped
    @input="handleInput"
  >
    <div class="column-container">
      <div class="title">
        <h3>Settings</h3>
      </div>

      <div class="tabs">
        <v-tabs
          vertical
          optional
          icons-and-text
          :slider-size="4"
          background-color="transparent"
          class="full-width-tabs"
        >
          <template v-for="item in items">
            <v-tooltip :key="`${item.title}.tooltip`" left :disabled="!showTooltip(item)">
              <template #activator="{ on }">
                <div v-on="on">
                  <v-tab
                    :key="item.title"
                    :to="item.path"
                    class="tab-base"
                    :ripple="false"
                    active-class="tab-active"
                    @click="handleClick(item)"
                  >
                    <span>{{ item.title }}</span>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-tab>
                </div>
              </template>
              <span> {{ item.tooltip }} </span>
            </v-tooltip>
          </template>
        </v-tabs>
      </div>

      <div class="logout">
        <base-button class="tour-logout-btn" empty color="secondary" :to="{ name: 'logout' }">
          <v-icon left>$logout</v-icon>
          <span>Log out</span>
        </base-button>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

/**
 * Component which allow user to use navigation. Simple navigation drawer.
 */
export default {
  name: "NavigationDrawer",

  components: {
    BaseButton,
  },

  props: {
    /**
     * Input value (i.e. the bound through v-model).
     */
    value: {
      type: Boolean,
      default: null,
    },
    userRole: {
      type: String,
      default: "ENDUSER",
    },
    userHasSubscriptions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentValue: null,
    };
  },

  computed: {
    items() {
      let items = [
        { title: "User profile", icon: "$user", path: "/settings/account" },
        { title: "Data collections", icon: "$collection", path: { name: "indexList" }, tooltip: "test" },
        // { title: "Index dashboard", icon: "camera", path: "dashboard" },
      ];

      if (this.userHasSubscriptions || this.userRole === "ADMIN") {
        items.push({
          title: "Subscription details",
          icon: "$collection",
          path: { name: "subscriptionsList" },
        });
      }
      if (this.userRole === "ADMIN") {
        items.splice(1, 0, { title: "Monitoring", icon: "$monitoring-PLACEHOLDER", path: "/monitoring" });
      }

      items.push({
        title: "Contact support",
        icon: "$mail",
        path: "",
        link: `mailto:${process.env.VUE_APP_SUPPORT_EMAIL}`,
      });
      return items;
    },
  },

  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentValue = newValue;
        }
      },
      immediate: true,
    },
  },

  methods: {
    getDisable(title) {
      if (title === "Share index") {
        return !Boolean(this.$router.currentRoute.params.indexId);
      }
    },
    handleInput(newValue) {
      /**
       * Fired each time a user toggles drawer. Will carry the new value as the payload.
       */
      this.$emit("input", newValue);
    },
    handleClick(item) {
      if (item.title === "Filters") {
        /**
         * Emitted when the filters should switch from enabled to disabled or vice versa.
         */
        this.$emit("toggleFilters");
      }
      if (item.title === "Contact support") {
        const link = document.createElement("a");
        link.href = item.link;
        link.target = "_blank";
        link.click();
      }
    },
    showTooltip(item) {
      return !!item.tooltip && this.getDisable(item.title);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer::v-deep .v-navigation-drawer__border {
  width: 2px;
  z-index: -1;
}

.column-container {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  div {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
  }

  .tabs {
    align-items: start;

    .full-width-tabs::v-deep {
      > .v-tabs-bar {
        width: 100%;
        height: 100%;
      }
    }

    .tab-base {
      flex: 2 1 auto;
      align-self: center;
      width: 100%;
      max-width: 100%;

      margin: 20px 0;

      span {
        padding-top: 12px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        text-align: center;
        color: var(--v-cool-grey-base);
      }
    }

    .tab-active {
      /* background-color: white; */
      span {
        color: var(--v-dark-grey-base) !important;
      }
    }
  }

  .logout {
    width: 160px;
    align-self: center;

    span {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.35px;
    }
  }
}
</style>
