<template>
  <div class="settings-container">
    <v-avatar size="90" class="align-self-center avatar">
      <img v-if="profile.avatar" alt="Avatar" src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460" />
      <v-icon v-else color="primary" v-text="profile.icon" />
    </v-avatar>
    <h3 class="uppercase">Notifications</h3>

    <base-button class="save-button" color="primary" type="submit" @click.native="$emit('click')">
      Save Changes
    </base-button>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

/**
 * The user notifications settings page.
 */
export default {
  name: "Notifications",
  components: {
    BaseButton,
  },
  data() {
    return {
      profile: {
        avatar: "https://avatars0.githubusercontent.com/u/9064066?v=4&s=460",
        icon: "JB",
      },
    };
  },
};
</script>

<style scoped lang="scss">
h3 {
  color: var(--v-cool-grey-two-base);
}

.settings-container {
  margin-top: 106px;
  padding: 0 12px; // Padding for tiny screens to have some breeding room around the app
  max-width: calc(436px + 2 * 12px); // Design says 436 for the inputs + padding of the wrapper
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
}

.avatar {
  margin-bottom: 36px;

  img {
    box-shadow: 0 0 15px var(--v-greyblue-base);
  }
}

.save-button {
  margin-top: 60px;
}
</style>
