<template>
  <div>
    <v-form ref="form" v-model="valid" class="form" lazy-validation @submit.prevent="handleNewPassword">
      <h1 class="text-uppercase">Password reset</h1>

      <template v-if="!showTokenIsInvalid">
        <p class="body-text mb-2 align-self-start">Your new password</p>
        <base-input
          v-model="formFields.newPassword"
          :type="showPwdNew ? 'text' : 'password'"
          :rules="[rule.pwdRequired, rule.minPwdLength]"
          placeholder="Your New Password"
          counter
          icon="$password"
          :hint="`At least ${minPassLength} characters`"
        >
          <template slot="append">
            <v-icon class="toggle-show" @click="showPwdNew = !showPwdNew">
              {{ showPwdNew ? "$closed" : "$eye" }}
            </v-icon>
          </template>
        </base-input>

        <p class="body-text mb-2 align-self-start">Repeat your password for confirmation</p>
        <base-input
          v-model="formFields.newPasswordRepeat"
          :type="showPwdRepeat ? 'text' : 'password'"
          :rules="[
            RULES.required('Please type your new password again for verification.'),
            rule.minPwdLength,
            rule.confirmMatch(formFields.newPassword),
          ]"
          placeholder="Repeat Your New Password"
          counter
          icon="$password"
          :hint="`At least ${minPassLength} characters`"
        >
          <template slot="append">
            <v-icon class="toggle-show" @click="showPwdRepeat = !showPwdRepeat">
              {{ showPwdRepeat ? "$closed" : "$eye" }}
            </v-icon>
          </template>
        </base-input>

        <base-button color="primary" class="submit-button" :loading="loading" submit x-large>
          Save changes
        </base-button>
      </template>
      <template v-else>
        <p class="body-text mb-2 align-self-start">
          Your password reset token is invalid or expired. Please make sure you entered the correct URL or request a new
          one through by filling the form <router-link :to="{ name: 'forgot-password' }">here</router-link>.
        </p>
      </template>
      <p class="pa-4"><router-link :to="{ name: 'login' }">Back to login page!</router-link></p>
    </v-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { PASSWORD_LIMITS } from "@/utils/const";
import { STATUS_CODES } from "@/utils/client";
import { setNewPassword } from "@/store/actionTypes";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";

const { mapActions } = createNamespacedHelpers("auth");

/**
 * The new password page.
 */
export default {
  name: "ResetPassword",

  components: {
    BaseButton,
    BaseInput,
  },

  props: {
    /**
     * Token that was emailed to the user to make sure only the designated user can change his password.
     */
    token: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showTokenIsInvalid: false,
      formFields: {
        newPassword: "",
        newPasswordRepeat: "",
      },
      rule: {
        pwdRequired: this.RULES.required("Please choose a password"),
        minPwdLength: this.RULES.min(
          `The password needs be at least ${PASSWORD_LIMITS.min} characters long`,
          PASSWORD_LIMITS.min
        ),
        confirmMatch: (newPassword) => this.RULES.confirmMatch(`Passwords don't match`, newPassword),
      },

      loading: false,
      showPwdRepeat: false,
      showPwdNew: false,
      valid: true,
      minPassLength: PASSWORD_LIMITS.min,
    };
  },

  methods: {
    ...mapActions({
      setNewPassword,
    }),

    /**
     *  Runs on submit of the form. Validates it and does the API call if it is valid.
     */
    async handleNewPassword() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      try {
        await this.setNewPassword({ password: this.formFields.newPassword, token: this.token });
      } catch (e) {
        if (e.status === STATUS_CODES.UNAUTHORIZED) {
          this.showTokenIsInvalid = true;
        } else {
          // will throw error to bubble up in other cases
          throw e;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-flow: column nowrap;
}

h1 {
  margin-bottom: 59px; // Aligns the buttons with the ones on the log-in page.
}

.input {
  margin-bottom: 4px;
}

.submit-button {
  margin-top: 60px;
}
</style>
