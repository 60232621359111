<template>
  <router-link :class="cardClass" :to="{ name: 'document', params: { id } }">
    <v-card width="436px" min-width="390px" height="355px" outlined elevation="3" class="card tour-doc-preview">
      <div class="card-header">
        <h4 class="title-link tour-doc-title">{{ (title || "") | truncate(60) }}</h4>
        <v-card-actions class="py-0 pr-0 card-actions">
          <v-tooltip bottom open-delay="1000">
            <template #activator="{ on }">
              <BaseButton class="tour-doc-open" icon small v-on="on">
                <svg-icon small name="expand_card" />
              </BaseButton>
            </template>
            <span>Open document</span>
          </v-tooltip>
          <!-- Extra span to introduce the same line-height around the icon the router-link does. -->
          <v-tooltip bottom open-delay="1000">
            <template #activator="{ on }">
              <BaseButton
                class="tour-hide-preview"
                icon
                small
                v-on="on"
                @click.capture.stop.prevent="$emit('hideDocument', id)"
              >
                <svg-icon small name="close_card" />
              </BaseButton>
            </template>
            <span>Hide document</span>
          </v-tooltip>
        </v-card-actions>
      </div>
      <div class="card-subheader">
        <div class="small-text-grey tour-doc-date">{{ date }}&nbsp;&nbsp;|&nbsp;&nbsp;{{ author }}</div>
        <div class="d-flex" @click.prevent>
          <feedback-thumbs v-model="feedback" class="mr-5 tour-result-feedback" />
          <score-bubble class="tour-result-score" :score="score" />
        </div>
      </div>
      <div class="card-content">
        <div class="card-text">
          <!--        <div v-if="position !== 0" class="fade-top"></div>-->
          <text-hit :snippet="snippet" :total-hits="totalHits" />
          <!--        <div v-if="position !== 100" class="fade"></div>-->
        </div>
        <position-indicator readonly class="card-indicator" :value="position" />
      </div>
      <v-card-actions class="card-tags">
        <document-tags :tags="meta.tags" />
      </v-card-actions>
    </v-card>
  </router-link>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { fromUnixTimestamp } from "@/utils/dateFormatter";
import DocumentTags from "@/components/DocumentTags.vue";
import ScoreBubble from "@/components/ScoreBubble.vue";
import FeedbackThumbs from "@/components/FeedbackThumbs.vue";
import BaseButton from "@/components/BaseButton.vue";
import PositionIndicator from "@/components/PositionIndicator.vue";
import TextHit from "@/components/TextHit.vue";
import SvgIcon from "@/components/SvgIcon";

const { mapState } = createNamespacedHelpers("main");

/**
 * Showing a sneak preview / snippet of one of the returned results.
 */
export default {
  name: "DocumentPreview",
  components: {
    SvgIcon,
    ScoreBubble,
    DocumentTags,
    FeedbackThumbs,
    BaseButton,
    PositionIndicator,
    TextHit,
  },

  props: {
    /**
     * Unique previewed document id.
     */
    id: {
      type: String,
      default: "",
    },

    /**
     * Document meta data contains title and author fields, used in card header
     */
    meta: {
      type: Object,
      default: () => ({
        author: "",
        title: "",
        date_created: "",
        tags: [],
      }),
    },

    /**
     * Pages found in document with calculated match score and text snippets.
     * Pages with 0 score are excluded
     */
    pages: {
      type: Array,
      default: () => [],
    },

    /**
     * Total pages of the document
     */
    totalPages: {
      type: Number,
      default: 0,
    },
    /**
     * Matching score of the highest score page
     */
    score: {
      type: Number,
      default: 0,
    },

    /**
     * Users document score feedback
     */
    documentFeedback: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    ...mapState({
      lastOpenedId: (state) => state.result.lastOpenedId,
    }),
    scoreColor() {
      switch (true) {
        case this.score > 0.8:
          return "primary";
        case this.score > 0.6:
          return "secondary";
        case this.score > 0.5:
          return "orange";
        case this.score > 0.45:
          return "tomato";
        default:
          return "pale-blue";
      }
    },
    feedback: {
      get() {
        return this.documentFeedback;
      },
      set(documentFeedback) {
        this.$emit("feedback", { id: this.id, documentFeedback });
      },
    },
    cardClass() {
      return { "link-wrap": true, "latest-document": this.lastOpenedId === this.id };
    },
    date() {
      return fromUnixTimestamp(this.meta["Creation-Date"] || this.meta.date_created || this.meta.created).format(
        "DD/MM/YYYY"
      );
    },
    snippet() {
      return {
        text: this.pages[0].snippet,
        hits: this.pages[0].hits,
      };
    },
    position() {
      return (this.pages[0].page / this.totalPages) * 100;
    },
    totalHits() {
      return this.pages[0].totalHits;
    },
    title() {
      return this.meta.title || this.meta["File-Name"] || this.$attrs.uri?.split("/").reverse()[0] || "";
    },
    author() {
      return this.meta.Author || this.meta.author || "";
    },
  },
};
</script>

<style lang="scss" scoped>
$right-margin: 15px;
$narrow-right-margin: $right-margin - 11px; // Used to align center of dismiss button with center of position indicator

.latest-document {
  text-decoration: none;

  .card {
    border: var(--v-secondary-base) solid;
  }
}

.link-wrap {
  text-decoration: none;

  &:visited::v-deep {
    .card,
    .end-row-coloring,
    .match-counter {
      background-color: var(--v-secondary-light-base);
    }
  }
}

.card {
  padding: 26px 20px 10px;
  display: flex;
  flex-flow: column nowrap;
}

.card-header {
  // We don't want the header to be resizing according to left over space, only to its content.
  flex: 0 0 auto;
  margin-left: 10px; // Total horizontal margin between header and card edge should be 30px
  margin-right: $narrow-right-margin; // Align center of dismiss button with center of position-indicator
  display: flex;
  align-content: space-between;
  align-items: flex-start;
  /*
   According to designs, the distance between the header text and the subheader text should be
   54px in case of a header with one line, 22px in case of 2 lines and 14px in case of three lines.
   The line height of the title is 32px.
   Assume we give the subheader a margin-top of 14px so that in case of a three line title, we don't
   need to add any (vertical) margin/padding any more. Of course we still need to fix the space in
   case of a 1- and 2-line header. Notice, the difference in desired space between the single line
   headers and the 2-line headers is exactly the line-height (32px). As such, if we set the
   min-height of the header to 64px (as if it contains always at least 2 lines), we now only need
   to fix the space for the 2-line headers. We already have 14px through the margin-top of the
   subheader, so that leaves us with 8px we need to compensate for, without affecting the space
   in case of a 3-line header. Easy solution: just add it to the min-height of 64px, arriving to
   following statement.
   One last thing: adding the score card inside the subheader increases its height from 18 to 28,
   adding  a space of 5px between the subheader text and the top of the div. Therefore, we need
   to compensate for this in the margin-top of the subheader and set it to 9px instead of 14px.
  */
  min-height: 72px;

  h4.title-link {
    line-height: 32px; // one line height
    max-height: 64px; // maximum two lines
    overflow: hidden; // hide overflowing text and logn words
    flex: 1 1 auto;
    text-decoration: none;
  }

  .card-actions .v-btn {
    padding: 0;
    // Shifting icon buttons into place according to designs
    margin-top: -2px; // Don't exactly know why, but this aligns it with the first line of the title.
    /*
     Button = 28px, SVG = 16px => (28px-16px)/2 empty space surrounding the icon in the button.
     The icon should be residing all the way to the right edge of the card content since we've fixed
     the padding of the card itself to be according to the designs). The button itself is aligned like
     this (to the right), but this means the icon itself isn't. Following margin will shift the whole
     button (so the hover styles etc. move together with the button) to compensate for the empty space
     inside the button.
     */
    margin-right: calc((16px - 28px) / 2);
  }
}

.card-subheader {
  // We don't want the subheader to be resizing according to left over space, only to its content.
  flex: 0 0 auto;

  margin-top: 9px; // See min-height of .card-header to know how margin-top function together
  margin-left: 10px; // Total horizontal margin between subheader and card edge should be 30px
  margin-right: $right-margin; // Total horizontal margin between score and card edge should be 35px
  display: flex;
  justify-content: space-between;
  align-items: center;

  .small-text-grey {
    opacity: 0.7;
    white-space: nowrap;
    max-width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .score {
    font-weight: bold;
    font-size: 0.75rem;
    line-height: normal;
    height: 1.75rem;
  }
}

.card-content {
  // We don't want the header to be resizing according to left over space, only to its content.
  flex: 0 1 auto;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 23px;
  position: relative;
  align-self: stretch;

  .card-text {
    flex: 0 1 auto;
    overflow: hidden;
    padding-right: 61px; // Diff between right edge of text content and highlights
    white-space: pre-wrap;
  }

  .card-indicator {
    // Distance from scroll bar to edge of card and right edge of highlight according to design
    margin-right: 12px;
    margin-left: 17px;
  }
}

.card-tags {
  justify-content: flex-end;
  padding: 10px 0 0;

  ::v-deep .v-slide-group__next,
  ::v-deep .v-slide-group__prev {
    min-width: 37.25px;
    flex: 0 1 37.25px;
  }
}

.fade {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background: linear-gradient(transparent, white);

  &::before {
    content: "\00a0\00a0"; // = &nbsp;
  }
}

.fade-top {
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;

  background: linear-gradient(white, transparent);

  &::before {
    content: "\00a0\00a0"; // = &nbsp;
  }
}
</style>
