<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleForgot">
    <h1 class="text-uppercase">Forgot password?</h1>
    <template v-if="!submitted">
      <base-input
        v-model="formFields.email"
        placeholder="Email"
        type="email"
        icon="$mail"
        class="input"
        :rules="[rule.emailRequired, rule.validEmail]"
        :disabled="submitted"
      />

      <base-button color="primary" class="submit-button" :disabled="submitted" :loading="loading" submit x-large>
        Reset password
      </base-button>
    </template>
    <template v-else> Your request has been submitted, you should get an email any time now. </template>
    <p class="pa-4"><router-link :to="{ name: 'login' }">Back to login page!</router-link></p>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { forgot } from "@/store/actionTypes";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";

const { mapActions } = createNamespacedHelpers("auth");

/**
 * The forgot password page.
 */
export default {
  name: "Forgot",

  components: {
    BaseButton,
    BaseInput,
  },

  data() {
    return {
      rule: {
        emailRequired: this.RULES.required("Email is required"),
        validEmail: this.RULES.email("This doesn't look like a valid email"),
      },

      formFields: {
        email: "",
      },
      loading: false,
      submitted: false,
      valid: true,
    };
  },
  methods: {
    ...mapActions({
      forgot,
    }),

    /**
     *  Runs on submit of the form. Validates it and does the API call if it is valid.
     */
    async handleForgot() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      try {
        // Get the reset route. Uses a stub token parameter because router does not want to match it otherwise.
        const reset = this.$router.resolve({
          name: "reset-password",
          params: { token: "stub" },
        }).route;
        // And now we need to remove the stub token from the path, and per definition of the backend API it has to be
        // the last parameter since the real token will be appended to the result string.
        const path = reset.fullPath.substring(0, reset.fullPath.length - reset.params.token.length);
        await this.forgot({
          ...this.formFields,
          urlPrefix: `${window.location.origin}${path}`,
        });
        this.submitted = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-bottom: 59px; // Aligns the buttons with the ones on the log-in page.
}
.input {
  margin-bottom: 4px;
}
.submit-button {
  margin-top: 60px;
}
</style>
