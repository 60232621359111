<script>
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "ChartDoughnut",
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    /**
     * FIXME
     */
    chartData: {
      type: Object,
      default: () => {},
    },
    /**
     * An object containing any extra options that apply to the chart
     * @see https://www.chartjs.org/docs/latest/charts/line.html#dataset-properties
     */
    options: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
        },
      }),
    },
  },
  mounted() {
    this.renderChart(this.chartData, {
      ...this.options,
      legend: { ...this.options.legend, position: "bottom" },
    });
  },
};
</script>
