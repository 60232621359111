<template>
  <v-text-field
    ref="search-field"
    background-color="pale-blue"
    class="straight-right-border mr-1"
    :placeholder="disabled ? reason : `Search ${indexName}`"
    clear-icon="$close"
    color="gray"
    filled
    flat
    hide-details
    rounded
    solo
    clearable
    data-hj-whitelist
    :value="calculatedValue"
    :disabled="disabled"
    @click:clear="handleClear"
    @input="handleInput"
    @focus="isActive = true"
    @blur="isActive = false"
  />
</template>

<script>
/**
 * Custom styled search bar. Developed as a component separate from BaseInput due to custom styling.
 */
export default {
  name: "InputSearch",

  props: {
    /**
     * Input value (i.e. the bound through v-model).
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Disable the input.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The reason why the search is disabled. Will be displayed as a hint to the user.
     */
    reason: {
      type: String,
      default: "",
    },
    /**
     * The selected index/data collection to search through
     */
    indexName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isActive: false,
      currentValue: "",
    };
  },

  computed: {
    calculatedValue() {
      return this.disabled ? null : this.currentValue;
    },
  },

  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentValue = newValue;
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleClear() {
      /**
       * Emitted when the search input is cleared.
       */
      this.$emit("clear");
    },
    handleInput(newValue) {
      this.isActive = true;
      this.currentValue = newValue;
      /**
       * Fired each time a user changes the input. Will carry the new value as the payload.
       */
      this.$emit("input", this.currentValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.straight-right-border {
  border: 0;
  border-radius: 50px 0 0 50px;
}

@media only screen and (max-height: 1000px) {
  .straight-right-border::v-deep {
    min-height: 48px;

    .v-input__control,
    .v-input__slot {
      min-height: 48px;
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .straight-right-border::v-deep {
    min-height: 36px;

    .v-input__control,
    .v-input__slot {
      min-height: 36px;
    }
  }
}
</style>
