import axios from "axios";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import Vue from "vue";

import { version } from "@/version";
import store from "@/store";
import { showErrorDialog } from "@/store/actionTypes.js";
import { HandledError } from "@/plugins/awback";

const sentrySubdomain = process.env.VUE_APP_SENTRY_DSN_DOMAIN;
const sentryPath = process.env.VUE_APP_SENTRY_DSN_PATH;
const sentryOrganization = process.env.VUE_APP_SENTRY_ORG;
const sentryProject = process.env.VUE_APP_SENTRY_PROJECT_NAME;
const sentryDSN = `https://${sentrySubdomain}@sentry.io/${sentryPath}`;

export const isEnabled = process.env.NODE_ENV === "production";

if (sentryPath && sentryDSN) {
  Sentry.init({
    release: `${sentryProject}@${version}`,
    dsn: sentryDSN,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        logErrors: !isEnabled,
      }),
    ],
    environment: process.env.VUE_APP_ENV,
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      const error = hint.originalException;

      // If it is handled, we ignore it!
      if (error && error instanceof HandledError) {
        console.debug("Swallowed error in Sentry!");
        console.debug(error);
        return null;
      }
      if (event.exception) {
        store.dispatch(showErrorDialog, { eventId: event.event_id });

        // To show sentry built in dialog:
        // Sentry.showReportDialog({ eventId: event.event_id });
      }

      // Returning null will prevent Sentry from actually submitting it.
      return isEnabled ? event : null;
    },
  });
}

export const submitSentryFeedback = async (data) => {
  // https://docs.sentry.io/api/auth/#id1
  // https://sentry.io/settings/account/api/auth-tokens/
  if (isEnabled) {
    const config = {
      method: "POST",
      url: `https://sentry.io/api/0/projects/${sentryOrganization}/${sentryProject}/user-feedback/`,
      headers: { Authorization: `DSN ${sentryDSN}` },
      data,
    };

    return axios(config);
  } else {
    return null;
  }
};
