import Vue from "vue";
import VueRouter from "vue-router";

import { EVENT_TYPES, logEvent } from "@/plugins/amplitude";
import { awback } from "@/plugins/awback";
import store from "@/store";
import { logout, showSnackBar } from "@/store/actionTypes";

import AnonymousApp from "@/views/anonymousapp/Index.vue";
import Login from "@/views/anonymousapp/Login.vue";
import Welcome from "@/views/anonymousapp/Welcome.vue";
import AcceptInvite from "@/views/anonymousapp/AcceptInvite.vue";
import ForgotPassword from "@/views/anonymousapp/ForgotPassword.vue";
import ResetPassword from "@/views/anonymousapp/ResetPassword.vue";
import Register from "@/views/anonymousapp/Register.vue";
import AuthenticatedApp from "@/views/authenticatedapp/Index.vue";
import Logout from "@/views/authenticatedapp/Logout.vue";
import NotFound from "@/views/NotFound.vue";
import Settings from "@/views/authenticatedapp/settings/Index.vue";
import Account from "@/views/authenticatedapp/settings/Account.vue";
import Notifications from "@/views/authenticatedapp/settings/Notifications.vue";
import Document from "@/views/authenticatedapp/index/search/Document.vue";
import DocumentDownload from "@/views/authenticatedapp/index/search/DocumentDownload.vue";

import IndexDashboard from "@/views/authenticatedapp/index/IndexDashboard.vue";

import Subscriptions from "@/views/authenticatedapp/subscription/Index.vue";
import SubscriptionsList from "@/views/authenticatedapp/subscription/List.vue";
import SubscriptionShare from "@/views/authenticatedapp/subscription/Share.vue";
import SubscriptionInstance from "@/views/authenticatedapp/subscription/Instance.vue";
import SubscriptionStatistics from "@/views/authenticatedapp/subscription/Statistics.vue";

import Index from "@/views/authenticatedapp/index/Index.vue";
import IndexList from "@/views/authenticatedapp/index/IndexList.vue";
import ShareIndex from "@/views/authenticatedapp/index/ShareIndex.vue";
import SearchIndex from "@/views/authenticatedapp/index/search/Index.vue";
import IndexInstance from "@/views/authenticatedapp/index/IndexInstance.vue";
import IndexStatistics from "@/views/authenticatedapp/index/IndexStatistics.vue";

import SearchResults from "@/views/authenticatedapp/index/search/SearchResults.vue";
import Monitoring from "@/views/authenticatedapp/admin/Monitoring";
import Version from "@/views/Version.vue";
import SharePointAdminLogin from "@/views/anonymousapp/SharePointAdminLogin.vue";
import SPAdmin from "@/views/authenticatedapp/SPAdmin/Index.vue";
import SelectTenant from "@/views/authenticatedapp/SPAdmin/SelectTenant.vue";
import SelectDrives from "@/views/authenticatedapp/SPAdmin/SelectDrives.vue";

Vue.use(VueRouter);

/**
 * Routing uses nested routing so we can have one guard immediately protecting all routes for which
 * authentication is required. Adding a protected route is then as simple as adding a new child to
 * the protected route. This also allows us to wrap all protected views inside a single component
 * (so we can centralize some layout such as e.g. navigation and a log-out button etc.).
 * The same
 */
const routes = [
  {
    path: "/version",
    component: Version,
  },
  {
    // This is the protected route.
    path: "",
    component: AuthenticatedApp, // Wrap all child routes inside this component,
    name: "home",
    redirect: { name: "indexList" },
    children: [
      {
        path: "doc-download/:indexId/:qid/:id/:filename",
        name: "documentDownload",
        component: DocumentDownload,
        props: true,
      },
      {
        path: "/subscriptions",
        component: Subscriptions,
        children: [
          {
            path: "",
            name: "subscriptionsList",
            component: SubscriptionsList,
          },
          {
            path: ":subscriptionId",
            component: SubscriptionInstance,
            props: true,
            children: [
              {
                path: "",
                name: "subscriptionStatistics",
                component: SubscriptionStatistics,
              },
              {
                path: "share",
                name: "subscriptionShare",
                component: SubscriptionShare,
              },
            ],
          },
        ],
      },
      {
        path: "/data-collection",
        component: Index,
        children: [
          {
            path: "",
            name: "indexList",
            component: IndexList,
          },
          {
            path: ":indexId",
            component: IndexInstance,
            props: true,
            children: [
              {
                path: "",
                name: "indexInstance",
                component: IndexStatistics,
              },

              {
                path: "dashboard",
                name: "indexDashboard",
                component: IndexDashboard,
              },
              {
                path: "share",
                name: "shareIndex",
                component: ShareIndex,
              },

              {
                path: "search/:qid",
                component: SearchIndex,
                props: true,
                children: [
                  {
                    path: "",
                    name: "searchResults",
                    component: SearchResults,
                    props: true,
                  },
                  {
                    path: "doc/:id",
                    name: "document",
                    component: Document,
                    props: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/authenticatedapp/About.vue"),
      },
      {
        path: "/settings",
        component: Settings,
        children: [
          {
            path: "account",
            name: "account",
            component: Account,
          },
          {
            path: "notifications",
            name: "notifications",
            component: Notifications,
          },
        ],
      },
      {
        path: "/logout",
        component: Logout,
        name: "logout",
      },
      {
        path: "/monitoring",
        component: Monitoring,
        name: "monitoring",
        /**
         * This guard prevents non-worker users from accessing the monitoring route. If the user is not a worker,
         * we redirect to the main page.
         * @param to
         *    @see https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
         * @param from
         *    @see https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
         * @param next
         *    @see https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
         */
        async beforeEnter(to, from, next) {
          let profile = await awback.getCachedProfile();
          if (profile.role === "ADMIN") {
            next();
          } else {
            next({
              name: "home",
            });
          }
        },
      },
      {
        path: "/sp-admin",
        component: SPAdmin,
        children: [
          {
            path: "",
            name: "sp-admin",
            component: SelectTenant,
          },
          {
            path: "select-drives",
            component: SelectDrives,
            name: "select-drives",
          },
        ],
      },
    ],
    /**
     * The guard that prevents unauthenticated accesses to this route. If no user is authenticated,
     * we redirect to the login page (also forwarding the URL the user intended to visit for
     * redirection after login).
     * @param to
     *    @see https://router.vuejs.org/guide/advanced/navigation-guards.html#per-route-guard
     * @param from
     *    @see https://router.vuejs.org/guide/advanced/navigation-guards.html#per-route-guard
     * @param next
     *    @see https://router.vuejs.org/guide/advanced/navigation-guards.html#per-route-guard
     */
    async beforeEnter(to, from, next) {
      if (await awback.isAuth()) {
        next();
      } else {
        next({
          name: to.matched.find((route) => route.name === "sp-admin") ? "sp-admin-login" : "login",
          params: { nextUrl: to.fullPath },
        });
      }
    },
  },
  {
    // The anonymous route
    path: "",
    component: AnonymousApp,
    children: [
      {
        path: "/login",
        component: Login,
        props: true,
        name: "login",
      },
      {
        path: "/register",
        component: Register,
        name: "register",
      },
      {
        path: "/forgot-password",
        component: ForgotPassword,
        name: "forgot-password",
      },
      {
        path: "/reset-password/:token",
        component: ResetPassword,
        name: "reset-password",
        props: true,
      },
      {
        path: "/welcome/:token",
        component: Welcome,
        name: "welcome",
        props: true,
      },
      {
        path: "/accept-invite/:token",
        component: AcceptInvite,
        name: "accept-invite",
        props: true,
      },
      {
        path: "/sp-admin/login",
        component: SharePointAdminLogin,
        name: "sp-admin-login",
      },
    ],
    /**
     * Guard that prevents authenticated accesses (we assume that if a user is already
     * authenticated, he has no need for visiting this section). In case such access is detected,
     * the routing is aborted (and the user thus stays on his current page.
     * @param to
     *    @see https://router.vuejs.org/guide/advanced/navigation-guards.html#per-route-guard
     * @param from
     *    @see https://router.vuejs.org/guide/advanced/navigation-guards.html#per-route-guard
     * @param next
     *    @see https://router.vuejs.org/guide/advanced/navigation-guards.html#per-route-guard
     */
    async beforeEnter(to, from, next) {
      if (await awback.isAuth()) {
        if (to.name === "login") {
          next("/");
          return;
        }

        await store.dispatch(`auth/${logout}`);
        store.dispatch(`auth/${showSnackBar}`);
      }

      next();
    },
  },
  {
    // Catch all for non-existing URLs.
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "home") {
    const { qid, indexId } = from.params;

    if (qid) {
      // Navigating home from document or search results should lead to search results
      next({ name: "searchResults", params: { indexId, qid } });
    } else if (indexId) {
      // Navigating home from index selection should keep user on same page
      next({ name: "indexInstance", params: { indexId } });
    } else {
      next({ name: "indexList" });
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  logEvent(EVENT_TYPES.VISIT, { from: from.fullPath, to: to.fullPath });
});
export default router;
