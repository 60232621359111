<template>
  <h1>Couldn't find your page!</h1>
</template>

<script>
/**
 * The 404 page.
 */
export default {
  name: "NotFound",
};
</script>

<style scoped></style>
