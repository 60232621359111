<template>
  <div class="slider-wrapper">
    <v-slider
      class="position-indicator"
      thumb-color="tertiary"
      :value="currentValue"
      :max="max"
      vertical
      :readonly="readonly"
      @input="handleInput"
    />
  </div>
</template>

<script>
/**
 * Scrollbar / slider to the right of the content.
 */
export default {
  name: "PositionIndicator",

  props: {
    /**
     *  Position of scroll bar. Usually used in combination with v-model.
     */
    value: {
      type: [Number, String],
      default: 0,
    },
    /**
     *  Maximum value of slider, corresponds to scrollHeight
     */
    max: {
      type: [Number, String],
      default: 100,
    },
    /**
     *  Disables possibility to scroll manually
     */
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentValue: 0,
    };
  },

  watch: {
    max(v) {
      // max value is being updated after some time component was mounted, moving thumb to the top
      this.currentValue = this.max;
    },
    value: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // We reverse the slider to make it work naturally as a scroll bar.
          this.currentValue = this.max - newValue;
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleInput(newValue) {
      this.currentValue = newValue;
      /**
       * Fired each time a user changes the input. Will carry the new value as the payload.
       */
      this.$emit("input", this.max - this.currentValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-wrapper {
  padding: 18px 0;
}

.position-indicator::v-deep {
  height: 100%;

  * {
    transition: none;
  }

  .v-input__control,
  .v-input__slot,
  .v-slider {
    height: 100%;

    .v-slider--vertical {
      min-height: 100%;
      margin: 18px 0;
    }
  }

  > .v-input__control .v-slider__thumb {
    width: 6px;
    height: 36px;
    left: -3px;
    border-radius: 3.5px;
    opacity: 0.5;

    &:before {
      top: 6px;
      left: -1px;
      width: 8px;
      height: 23px;
      display: none;
    }
  }

  .v-slider__track-container {
    width: 3px;
    border-radius: 2px;
    height: calc(100% + 36px);
    top: -18px;
    opacity: 0.5;
    background: var(--v-dark-grey2-base);

    .v-slider__track-background,
    .v-slider__track-fill {
      display: none;
    }
  }
}

.position-indicator:hover::v-deep {
  > .v-input__control .v-slider__thumb,
  .v-slider__track-container {
    opacity: 1;
  }
}
</style>
