<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="textDate_"
        :label="`${label} (DD/MM/YYYY)`"
        prepend-icon="$date"
        clearable
        clear-icon="$close_card"
        v-bind="attrs"
        :error="dateError"
        :error-messages="dateError ? 'Invalid date. Enter date in format DD/MM/YYYY or select one from calendar' : ''"
        v-on="on"
        @click:clear="clear"
        @blur="handleBlur"
        @input="onChange"
      />
    </template>
    <v-date-picker v-model="date" :min="min" :max="max" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="save">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
import { dateFormat } from "@/utils/dateFormatter";

/**
 * Component allows to select/pick a date.
 */
const { mapState } = createNamespacedHelpers("main");
export default {
  name: "DatePicker",

  props: {
    /**
     * Date to select
     */
    value: {
      type: String,
      default: null,
    },
    /**
     * Label of the field
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Minimum allowed date
     */
    min: {
      type: String,
      default: null,
    },
    /**
     * Maximum allowed date
     */
    max: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      textDate_: "",
      menu: false,
      date: null,
      dateError: false,
    };
  },

  computed: {
    parsedMin() {
      return this.min && moment(this.min);
    },
    parsedMax() {
      return this.max && moment(this.max);
    },
  },

  watch: {
    date(v) {
      this.textDate_ = dateFormat(v);
    },
    value(v) {
      this.date = v;
    },
    menu(isOpen) {
      if (isOpen) {
        this.resetError();
      }
    },
    // Error in callback for immediate watcher "value" :c
    // value: {
    //   hander(newValue, oldValue) {
    //     if (newValue !== oldValue) {
    //       this.date = newValue;
    //     }
    //   },
    //   immediate: true
    // }
  },

  // using mounted, because immediate watcher is not working, see comment above
  mounted() {
    this.date = this.value;
  },

  methods: {
    onChange(newValue) {
      const oldVal = this.textDate_ || "";
      const newVal = newValue || "";
      if (!oldVal || newVal?.length > oldVal?.length) {
        let parts = newVal
          .replaceAll(/[^0-9/]/g, "") // Remove invalid symbols
          .replaceAll(/\/+/g, "/") // Get rid of duplicate slashes
          .split("/") // Split into day-month-year fields
          .slice(0, 3); // A date should only exist of 3 fields

        console.log(parts);
        let value = parts // Pad all 'finished' fields to be at least 2 numbers
          .map((part, i) => (i < parts.length - 1 ? part.padStart(2, "0") : part))
          .reduce(
            (accum, currentValue, i) => {
              // Used to move excess digits to next field while converting to string
              const complete_field = accum.carryOver + currentValue;
              const max_digits = i < 2 ? 2 : 4; // Day or month field should be maximally 2 digits, max for year is 4
              return {
                result: accum.result.concat([complete_field.slice(0, max_digits)]),
                carryOver: complete_field.slice(max_digits),
              };
            },
            { result: [], carryOver: "" }
          )
          .result.join("/");
        console.log(value);

        if (value.length === 2 || value.length === 5) {
          value += "/";
        }

        // Reset to null and update value in next tick to force Vuetify to update it. It doesn't update if assigning
        // the result immediately if the result is the same as the previous result.
        this.textDate_ = null;
        this.$nextTick(() => {
          this.textDate_ = value.replaceAll("//", "/");
        });
      } else {
        // If backspace, we
        this.textDate_ = newVal;
      }
    },
    clear() {
      this.date = null;
      this.textDate_ = "";
      this.save();
    },
    save() {
      this.$refs.menu.save(this.date);
      /**
       * Emitted on confirming the current date selection. Payload is the selected date.
       */
      this.$emit("input", this.date);
    },
    formatted(date) {
      if (!date) {
        return null;
      }

      const momentDate = moment(date, "YYYY-MM-DD");
      return momentDate.format("DD/MM/YYYY");
    },

    parseDate(date) {
      if (!date) {
        return null;
      }

      const momentDate = moment(date, "D/M/YY", true);

      if (!momentDate || !momentDate.isValid()) {
        this.dateError = true;
        return null;
      } else {
        this.dateError = false;
        return momentDate;
      }
    },
    resetError() {
      this.dateError = false;
    },
    handleBlur() {
      let momentDate = this.parseDate(this.textDate_);

      if (momentDate) {
        if (this.parsedMin) {
          momentDate = moment.max(this.parsedMin, momentDate);
        }
        if (this.parsedMax) {
          momentDate = moment.min(this.parsedMax, momentDate);
        }

        this.date = momentDate.format("YYYY-MM-DD");
        this.$emit("input", this.date);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
