<template>
  <v-avatar color="primary" :size="size">
    <img v-if="src" alt="Avatar" :src="src" />
    <span v-else class="white--text headline">{{ initials }}</span>
  </v-avatar>
</template>

<script>
/**
 * Component that show avatar or initials.
 */
export default {
  name: "AvatarUser",

  props: {
    /**
     * Size of the avatar to show in pixels.
     */
    size: {
      type: Number,
      default: 90,
    },
    /**
     * `src` attribute of the image that will be shown.
     */
    src: {
      type: String,
      default: "",
    },
    /**
     * First name of the logged in user.
     */
    firstname: {
      type: String,
      default: "",
    },
    /**
     * Last name of the logged in user.
     */
    lastname: {
      type: String,
      default: "",
    },
  },
  computed: {
    initials() {
      const first = this.firstname && this.firstname[0];
      const last = this.lastname && this.lastname[0];

      return first + last || "?";
    },
  },
};
</script>

<style lang="scss" scoped></style>
