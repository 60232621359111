<template>
  <div class="wrap">
    <v-text-field
      class="base-input"
      required
      flat
      solo
      :height="small ? undefined : 60"
      :autofocus="autofocus"
      :type="type"
      :hint="hint"
      :error="error"
      :counter="counter"
      :placeholder="placeholder"
      :value="currentValue"
      :rules="rules"
      :disabled="disabled"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
    >
      <v-icon slot="prepend-inner" tabindex="-1" :color="iconColor">
        {{ icon }}
      </v-icon>
      <template slot="append">
        <!-- @slot Adds an item after input content. -->
        <slot name="append" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
/**
 * Basic input to be reused throughout the application.
 */
export default {
  name: "BaseInput",
  props: {
    /**
     * Input value (i.e. the bound through v-model).
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Input type ().
     * @values text password email number
     */
    type: {
      type: String,
      default: "text",
    },
    /**
     * Prepending icon
     */
    icon: {
      type: String,
      required: true,
    },
    /**
     * Hint shown under the input
     */
    hint: {
      type: String,
      default: "",
    },
    /**
     * Placed in when input is empty.
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Counts how many symbols were entered
     */
    counter: {
      type: Boolean,
      default: false,
    },
    /**
     * Handles autofocus
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
    /**
     * Puts the input in a manual error state
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * Validation rules array
     */
    rules: {
      type: Array,
      default: () => [],
    },
    /**
     * Make the component a little smaller.
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable the input.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
      touched: false,
      currentValue: "",
    };
  },

  computed: {
    isFieldValid() {
      return this.rules.every((rule) => rule(this.currentValue) === true);
    },
    iconColor() {
      if (this.isActive) {
        return this.isFieldValid ? "primary" : "secondary";
      }

      if (this.touched) {
        return this.isFieldValid ? "primary" : "error";
      }

      return "";
    },
  },

  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentValue = newValue;
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleFocus() {
      this.touched = true;
      this.isActive = true;
    },
    handleBlur() {
      this.isActive = false;
    },
    handleInput(newValue) {
      this.isActive = true;
      this.currentValue = newValue;
      /**
       * Fired each time a user changes the input. Will carry the new value as the payload.
       */
      this.$emit("input", this.currentValue);
    },
  },
};
</script>

<style lang="scss" scoped>
//noinspection CssInvalidPseudoSelector
.wrap {
  // fixes bug with dynamic error messages height
  height: 92px;
}
.base-input::v-deep {
  > .v-input__control > .v-input__slot {
    caret-color: auto;
    border: solid 1px var(--v-pale-blue-base);
  }

  > .v-input__control > .v-input__slot > .v-text-field__slot {
    border-left: solid 1px var(--v-pale-blue-base);
    height: -webkit-fill-available;
    padding-left: 24px;
  }

  > .v-input__control > .v-input__slot > .v-input__prepend-inner {
    margin-right: 8px;
  }

  > .v-text-field__details {
    color: black;
    min-height: 16px;
  }

  > .v-messages {
    min-height: 16px;
  }
}
</style>
