<template>
  <router-view />
</template>

<script>
/**
 * Root view of the admin UI to create a SharePoint connection.
 */
export default {
  name: "SharePointIndex",
};
</script>
