import Vue from "vue";
import amplitude from "amplitude-js";
import * as Sentry from "@sentry/browser";
import version from "@/version";

const amplitudePlugin = {
  install: (Vue, { apiKey, userId, config }) => {
    amplitude.getInstance().init(apiKey, userId, config);
    amplitude.getInstance().setVersionName(version);

    // eslint-disable-next-line
    Vue.prototype.$amplitude = amplitude;
  },
};

Vue.use(amplitudePlugin, {
  apiKey: process.env.VUE_APP_AMPLITUDE_KEY,
  config: {
    secureCookie: true,
  },
});

export default amplitude;

export function logEvent(eventName, payload) {
  try {
    return amplitude.getInstance().logEvent(eventName, payload);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export const EVENT_TYPES = Object.freeze({
  DOC_OPEN: "doc_open",
  DOC_CLOSE: "doc_close",
  DOC_HIDE: "doc_hide",
  DOC_DOWNLOAD: "doc_download",
  FILTER_RESULTS: "filter_results",
  SEARCH: "search",
  SELECT_LANGUAGE: "select_language",
  USER_FORGOT_PWD: "user_forgot_pwd",
  USER_LOGIN: "user_login",
  USER_LOGOUT: "user_logout",
  USER_REGISTER: "user_register",
  USER_SET_PWD: "user_set_pwd",
  USER_UPDATE_PROFILE: "user_update_profile",
  VISIT: "visit_url",
  THUMB_UP: "thumb_up",
  THUMB_DOWN: "thumb_down",
});
