<template>
  <div class="pt-6">
    <v-sheet class="part-of-sheet">
      <v-card class="mx-3 d-flex tour-metadata-filters">
        <v-btn-toggle class="overflow">
          <!-- @slot The default slot. Use this to render the filters in. -->
          <slot />
        </v-btn-toggle>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
/**
 * Toolbar in which the filters will be rendered.
 */
export default {
  name: "ToolbarFilters",
};
</script>

<style lang="scss" scoped>
.part-of-sheet {
  background-color: var(--v-pale-grey-base);
}
.toolbar-content::v-deep {
  > .v-toolbar > .v-toolbar__content {
    padding: 0 16px;
  }
}
.overflow {
  overflow: hidden;
  margin-right: 12px;
}
</style>
