<template>
  <v-chip-group>
    <template v-for="(tag, i) in tags">
      <v-chip :key="tag + i" class="tag" small>{{ tag }}</v-chip>
    </template>
  </v-chip-group>
</template>

<script>
/**
 * Shows document tags
 */
export default {
  name: "DocumentTags",
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.tag {
  pointer-events: none; // No clicks on this element! All clicks should go to the surrounding elements...
}
</style>
