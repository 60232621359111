<template>
  <div class="tour-anonymous_app">
    <v-container pa-0 fill-height fluid>
      <v-row no-gutters class="justify-center">
        <v-col v-if="$vuetify.breakpoint.mdAndUp" md="6" class="left-col">
          <v-img :src="image.src" :lazy-src="image.lazySrc" aspect-ratio="1" height="100%">
            <div class="text-center d-flex flex-column justify-space-between fill-height px-4">
              <div class="main-logo-position pa-0">
                <v-icon class="main-logo" color="white">$main_logo</v-icon>
                <h3 class="white--text text-uppercase">Unlocking valuable insights into your textual data</h3>
              </div>
              <div class="social bottom" style="display: none">
                <!-- Temporarily disabled -->
                <a href="https://www.facebook.com/harvesting.knowledge.cauzza.io/">
                  <v-icon color="white" class="external-link"> $facebook </v-icon>
                </a>
                <a href="https://www.linkedin.com/company/cauzza/">
                  <v-icon color="white" class="external-link"> $linkedin </v-icon>
                </a>
                <a href="https://cauzza.io/">
                  <v-icon color="white" class="external-link"> $web </v-icon>
                </a>
              </div>
            </div>
          </v-img>
        </v-col>
        <v-col xs="11" sm="8" md="6" lg="6" class="right-col text-center">
          <div class="form-wrapper">
            <router-view />
          </div>
          <p class="copyrights bottom">Cauzza © {{ currentYear }} All rights reserved</p>
        </v-col>
      </v-row>
    </v-container>
    <snack-bar v-model="forceLogout"> You were logged out! </snack-bar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { hideSnackBar } from "@/store/actionTypes";
import SnackBar from "@/components/SnackBar.vue";

const { mapState, mapActions } = createNamespacedHelpers("auth");

/**
 * The index-page.
 */
export default {
  name: "AnonymousApp",

  components: {
    SnackBar,
  },

  computed: {
    ...mapState({
      showForceLogout: (state) => state.showForceLogout,
    }),
    forceLogout: {
      get() {
        return this.showForceLogout;
      },
      set() {
        this.hideSnackBar();
      },
    },
    image() {
      if (this.$route.name === "login") {
        return {
          src: require("@/assets/signup_left.jpg"),
          srcLazy: require("@/assets/signup_left_small.jpg"),
        };
      }

      return {
        src: require("@/assets/signin_left.jpg"),
        srcLazy: require("@/assets/signin_left_small.jpg"),
      };
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    ...mapActions({
      hideSnackBar,
    }),
  },
};
</script>

<style lang="scss" scoped>
.left-col {
  height: 100vh;

  .main-logo-position {
    margin-top: 30vh;
  }

  .main-logo {
    width: 60%;
    margin-bottom: 42px;
    color: white;
  }

  .main-logo::v-deep {
    > svg {
      width: 100%;
    }
  }

  .social {
    .external-link {
      cursor: pointer;
      opacity: 0.7;
      margin: 0 25px;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.bottom {
  margin-bottom: 52px;
}

.right-col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .form-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    width: 100%;

    > * {
      flex: 1 1 auto;
      max-width: 436px;
    }
  }

  .copyrights {
    color: var(--v-cool-grey-base);
    padding: 0 12px;
    position: absolute;
    bottom: 0;
  }
}
</style>
