<template>
  <v-card width="100%" class="fill-height subscription-card">
    <v-card flat>
      <v-card-title class="span-select d-flex">
        <div class="mr-2">Show logs from</div>
        <div class="select-wrapper">
          <v-select
            v-model="selectedTimeSpan"
            :items="timeSpans"
            item-text="text"
            item-value="id"
            class="pt-0"
            hide-details
          />
        </div>
      </v-card-title>

      <ProgressBarQuery
        v-if="subscriptionDetails.query_limit == 0"
        :current-amount="subscriptionDetails.bundle_size"
        :max-amount="subscriptionDetails.query_limit"
        :height="30"
        class="mx-1"
      />

      <v-card-text v-if="overDuePrice > 0">
        After overdue you will be charged for €{{ overDuePrice }} per query
      </v-card-text>
    </v-card>

    <v-card-title>
      Queries
      <v-spacer></v-spacer>
      <v-text-field v-model="search" class="mx-4" clear-icon="$close" hide-details clearable label="Search" />
    </v-card-title>

    <v-data-table
      :headers="tableHeaders"
      :items="tableData"
      item-key="name"
      sort-by="name"
      class="elevation-1"
      show-group-by
      :search="search"
    >
      <!--      <template v-slot:body="{ items, headers }">-->
      <!--        <tbody is="transition-group" v-if="items.length > 0" name="list">-->
      <!--          <tr v-for="item in items" :key="`${item.created}${item.index}${item.lang}${item.email}`" class="item-row">-->
      <!--            <td>{{ item.created }}</td>-->
      <!--            <td>{{ item.content }}</td>-->
      <!--            <td>{{ item.index }}</td>-->
      <!--            <td>{{ item.lang }}</td>-->
      <!--            <td>{{ item.email }}</td>-->
      <!--          </tr>-->
      <!--        </tbody>-->
      <!--        <tbody v-else>-->
      <!--          <tr>-->
      <!--            <td :colspan="headers.length" style="text-align: center;">No Results Here!</td>-->
      <!--          </tr>-->
      <!--        </tbody>-->
      <!--      </template>-->
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
import { getSubscriptionDetails, getSubscriptions } from "@/store/actionTypes.js";
import ProgressBarQuery from "@/components/ProgressBarQuery.vue";

const { mapActions, mapState } = createNamespacedHelpers("main");

/**
 * The user Subscriptions page.
 */
export default {
  name: "Subscriptions",
  components: {
    ProgressBarQuery,
  },
  props: {
    isStorybookMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTimeSpan: 1,
      search: "",

      tableHeaders: [
        {
          text: "Date",
          align: "start",
          value: "created",
          groupable: true,
        },
        {
          text: "Content",
          value: "content",
          groupable: false,
        },
        {
          text: "Language",
          value: "lang",
          groupable: true,
        },
        {
          text: "Index name",
          value: "index",
          groupable: true,
        },
        {
          text: "User Email",
          value: "email",
          groupable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      subscriptions: (state) => state.subscriptions,
      subscriptionDetails: (state) => state.subscriptionDetails,
      stats: (state) => state.selectedIndexDetails.stats,
    }),
    timeSpans() {
      return [
        {
          id: 1,
          text: moment().format("MMMM"),
          from: moment().startOf("month").format("YYYY-MM-DD"),
          to: moment().endOf("month").format("YYYY-MM-DD"),
        },
        {
          id: 2,
          text: moment().subtract(1, "months").format("MMMM"),
          from: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
          to: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
        },
        {
          id: 3,
          text: moment().subtract(2, "months").format("MMMM"),
          from: moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD"),
          to: moment().subtract(2, "months").endOf("month").format("YYYY-MM-DD"),
        },
      ];
    },
    overDuePrice() {
      return this.subscriptionDetails.overdue_price?.toFixed(2);
    },
    tableData() {
      const { queries } = this.subscriptionDetails;

      if (!queries) {
        return [];
      }

      return queries.map((query) => ({
        created: moment(query.created).format("YYYY-MM-DD"),
        content: query.content,
        index: query.index,
        lang: query.languages.join(","),
        email: query.user,
      }));
    },
  },
  watch: {
    selectedTimeSpan: {
      handler(id) {
        const { from, to } = this.timeSpans.find((el) => el.id === id);
        const subId = this.subscriptionDetails.id;
        this.getSubscriptionDetails({ subId, from, to });
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      getSubscriptions,
      getSubscriptionDetails,
    }),
  },
};
</script>

<style scoped lang="scss">
.subscription-card {
  margin: 20px 64px 0 64px;
  max-width: 1024px;
}

.span-select {
  width: 360px;
}

.select-wrapper {
  width: 150px;
}

//.list-enter-active,
//.list-leave-active {
//  transition: all 0.8s;
//}
//.list-enter,
//.list-leave-to {
//  opacity: 0;
//  transform: translateX(100%);
//}
//.list-move {
//  transition: transform 0.5s;
//}
//.item-row {
//  display: table-row;
//}
</style>
