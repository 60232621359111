<template>
  <div class="d-flex justify-center ma-10">
    <!-- FIXME: come up with a nicer design for this page. -->
    <v-autocomplete
      v-model="selected"
      class="tour-select-index"
      :multiple="false"
      label="Which data collection would you like to open?"
      :items="indices"
      item-text="name"
      item-value="id"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { resetIndexDetails } from "@/store/actionTypes.js";

const { mapState, mapActions } = createNamespacedHelpers("main");

/**
 * The index view.
 */
export default {
  name: "IndexList",
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapState({
      indices: (state) => state.indices,
    }),
  },

  watch: {
    indices: {
      handler(array) {
        if (array && array.length === 1) {
          this.selected = array[0].id;
        }
      },
      immediate: true,
    },
    selected: {
      handler(indexId) {
        if (indexId) {
          this.$router.push({ name: "indexInstance", params: { indexId: `${indexId}` } });
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.resetIndexDetails();
  },

  methods: {
    ...mapActions({ resetIndexDetails }),
  },
};
</script>
