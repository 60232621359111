import { get, set, uniqWith, isEqual } from "lodash";

export const deepSum = (dataArray) => {
  const getObjectSumFields = (obj, parent) => {
    Object.keys(obj).forEach((key) => {
      const currentKey = parent ? [...parent, key] : [key];

      if (!isNaN(get(obj, key))) {
        objectSumFieldsSchema.push(currentKey);
      } else if (typeof get(obj, key) === "object") {
        getObjectSumFields(get(obj, key), currentKey);
      }
    });
  };

  // Prepare schema, getObjectSumFields will fill objectSumFieldsSchema
  // with fields path like: ['languages', 'categories', "['en', 'fr']", 'count']
  let objectSumFieldsSchema = [];
  if (dataArray.length > 0) {
    dataArray.forEach((el) => getObjectSumFields(el));
  }

  objectSumFieldsSchema = uniqWith(objectSumFieldsSchema, isEqual);

  // Consolidate fields
  const result = {};

  dataArray.forEach((data) => {
    objectSumFieldsSchema.forEach((path) => {
      const value = get(data, path, 0);
      const curValue = get(result, path, 0);

      set(result, path, curValue + value);
    });
  });

  return result;
};
