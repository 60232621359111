<template>
  <div class="thumbs">
    <base-button small class="mx-1" icon @click="thumbDown">
      <v-icon small color="error" class="fa-flip-horizontal">
        ${{ value === false ? "fas-thumbs-down" : "far-thumbs-down" }}
      </v-icon>
    </base-button>
    <base-button small class="thumbs mx-1" icon @click="thumbUp">
      <v-icon small color="secondary">${{ value === true ? "fas-thumbs-up" : "far-thumbs-up" }}</v-icon>
    </base-button>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "FeedbackThumbs",
  components: {
    BaseButton,
  },

  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    thumbUp() {
      if (this.value !== true) {
        this.$emit("input", true);
      }
    },
    thumbDown() {
      if (this.value !== false) {
        this.$emit("input", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbs {
  display: flex;
}
</style>
