<template>
  <ToolbarFilterMenu :icon="icon" :close-on-content-click="false" :disabled="disabled || totalResults === 0">
    <template #text>
      {{ fieldName }}
      <span v-if="specs.range">
        <span v-if="validRangeFrom && !validRangeTo">&ge;</span>
        <span v-if="!validRangeFrom && validRangeTo">&le;</span>
        <span v-if="validRangeFrom && validRangeTo">:</span>
      </span>
      <span v-else>
        <span v-if="validExactNumber">=</span>
      </span>
      {{ title }}
    </template>
    <v-list v-if="specs.range">
      <v-list-item>
        <v-text-field
          :readonly="isScoreFilter"
          style="margin-right: 5px"
          :type="isScoreFilter ? 'text' : 'number'"
          :label="isScoreFilter ? maxLabel : minLabel"
          :value="isScoreFilter ? scoreRangeMax : rangeFrom"
          @input="onFromInput"
        />
        <v-text-field
          :readonly="isScoreFilter"
          :type="isScoreFilter ? 'text' : 'number'"
          :label="isScoreFilter ? minLabel : maxLabel"
          :value="isScoreFilter ? scoreRangeMin : rangeTo"
          @input="onToInput"
        />
      </v-list-item>
      <v-list-item>
        <v-checkbox v-model="allowEmpty" color="secondary" :label="`Include documents without ${fieldName}`" />
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-text-field type="number" :label="exactLabel" :value="exactNumber" @input="onExactInput" />
      </v-list-item>
      <v-list-item>
        <v-checkbox v-model="allowEmpty" color="secondary" :label="`Include documents without ${fieldName}`" />
      </v-list-item>
    </v-list>
    <ScoreRangeSelectScatter
      v-if="specs.field === 'score'"
      v-model="range"
      :data-array="dataArray"
      @update:tempRange="updateTempRange"
    />
  </ToolbarFilterMenu>
</template>

<script>
import ToolbarFilterMenu from "./ToolbarFilterMenu";
import ScoreRangeSelectScatter from "@/components/ScoreRangeSelectScatter";
import { debounce, omit } from "lodash";

/**
 * Component filters that allow to filter documents by any number field.
 */
export default {
  name: "FilterNumeric",
  components: { ToolbarFilterMenu, ScoreRangeSelectScatter },
  props: {
    /**
     * The filter specification, determines what field it will filter etc. Of the form
     * `{field: String, min: [Number, undefined], max: [Number, undefined], range: Boolean}`.
     */
    specs: {
      type: Object,
      required: true,
    },
    /**
     * Current filter value.
     * Of the form `{ value: { min: Number, max: Number }, active: Boolean }` or `{ value: Number, active: Boolean }` depending on
     * the `specs.range` being true or not. The former form is used in case it is true.
     */
    value: {
      type: Object,
      required: true,
    },
    /**
     * The (left) icon of this filter. Defaults to no icon.
     */
    icon: {
      type: String,
      default: "",
    },
    /**
     * Disables the filter UI.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Filter information. Additional data for filter draw.
     */
    filterInfo: {
      type: Object,
      default: () => {},
    },
    /**
     * Total results q-ty
     */
    totalResults: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      allowEmpty: false,
      rangeFrom: 0,
      rangeTo: 100,
      exactNumber: "",
      range: [this.specs.min * 100, this.specs.max != null ? this.specs.max * 100 : 100],
      tempRange: [this.specs.min * 100, this.specs.max != null ? this.specs.max * 100 : 100],
      dirty: false,
      debounceEmit: debounce(() => {
        this.updateFilter();
      }, 1500),
    };
  },
  computed: {
    isScoreFilter() {
      return this.specs.field === "score";
    },
    roundedRangeFrom() {
      return Number(this.rangeFrom).toFixed(2);
    },
    roundedRangeTo() {
      return Number(this.rangeTo).toFixed(2);
    },
    scoreRangeMin() {
      return Number(this.tempRange[0]).toFixed(2);
    },
    scoreRangeMax() {
      return Number(this.tempRange[1]).toFixed(2);
    },
    dataArray() {
      return this.filterInfo ? this.filterInfo.props.scores.slice() : [];
    },
    fieldName() {
      const split_field = this.specs.field.split(".");
      return this.specs.displayName || `${split_field[split_field.length - 1]}`;
    },
    validRangeFrom() {
      return Number.isFinite(+this.rangeFrom) && this.rangeFrom !== "";
    },
    validRangeTo() {
      return Number.isFinite(+this.rangeTo) && this.rangeTo !== "";
    },
    validExactNumber() {
      return Number.isFinite(+this.exactNumber) && this.exactNumber !== "";
    },
    label() {
      const validMin = Number.isFinite(+this.specs.min);
      const validMax = Number.isFinite(+this.specs.max);
      if (validMin && validMax) {
        return `[${this.scaleValue(this.specs.min)}, ${this.scaleValue(this.specs.max)}]`;
      } else if (validMin) {
        return `>= ${this.scaleValue(this.specs.min)}`;
      } else if (validMax) {
        return `<= ${this.scaleValue(this.specs.max)}`;
      } else {
        return "";
      }
    },
    minLabel() {
      return this.label ? `Min (${this.label})` : "Min";
    },
    maxLabel() {
      return this.label ? `Max (${this.label})` : "Max";
    },
    exactLabel() {
      return this.label ? `${this.label}` : "";
    },
    title() {
      if (this.specs.range) {
        if (this.validRangeFrom && this.validRangeTo) {
          if (this.isScoreFilter) {
            return `${this.roundedRangeTo} -> ${this.roundedRangeFrom}`;
          } else {
            return `${this.roundedRangeFrom} -> ${this.roundedRangeTo}`;
          }
        } else if (this.validRangeFrom) {
          return this.roundedRangeFrom;
        } else {
          return this.validRangeTo ? this.roundedRangeTo : "";
        }
      } else {
        return this.validExactNumber ? this.exactNumber : "";
      }
    },
  },
  watch: {
    range(newValue, oldValue) {
      const [oldMin, oldMax] = oldValue;
      const [newMin, newMax] = newValue;
      if (oldMin !== newMin) {
        this.onFromInput(newMin);
      }
      if (oldMax !== newMax) {
        this.onToInput(newMax);
      }
    },
    value: {
      // TODO: add value validation for min max
      handler: function (val = { value: {} }) {
        if (this.specs.range) {
          const { value: { min, max } = {} } = val;

          const invalidValue = min === undefined || max === undefined;
          this.rangeFrom = this.scaleValue(min) || "";
          this.rangeTo = this.scaleValue(max) || "";
          this.exactNumber = null;
          if (!this.dirty && (this.validRangeFrom || this.validRangeTo)) {
            this.rangeFrom = "";
            this.rangeTo = "";
            this.debounceEmit();
          }
        } else {
          const invalidValue = val.value === undefined;
          this.exactNumber = this.scaleValue(val.value) || "";
          this.rangeFrom = null;
          this.rangeTo = null;
          if (!this.dirty && this.validExactNumber) {
            this.exactNumber = "";
            this.debounceEmit();
          } else if (invalidValue) {
            this.debounceEmit();
          }
        }
      },
      immediate: true,
      deep: true,
    },
    allowEmpty(val) {
      if (this.active) {
        this.debounceEmit();
      }
    },
  },
  methods: {
    getMax() {
      // We show 100 on initial state for better UX,
      // initial state = no min and no max values = zeros everywhere
      // As soon user touch filter - it will be updated and show actual state
      if (this.isScoreFilter) {
        return !this.dirty && this.roundedRangeTo === "0" ? "100" : this.roundedRangeTo;
      } else {
        return this.rangeTo;
      }
    },
    updateFilter() {
      const value = this.specs.range
        ? {
            min: this.validRangeFrom ? this.scaleValueDown(this.rangeFrom) : "",
            max: this.validRangeTo ? this.scaleValueDown(this.rangeTo) : "",
          }
        : this.validExactNumber
        ? this.scaleValueDown(this.exactNumber)
        : null;
      /**
       * Emits the new value corresponding to v-model.
       */

      this.$emit("input", { value: this.validateValue(value), active: this.dirty, include_no_data: this.onAllowEmpty });
    },
    onFromInput(value) {
      if (value !== this.rangeFrom) {
        // Update rangeFrom before active update, otherwise when updating from
        // inactive state, active remains false forever
        this.rangeFrom = value;
        this.dirty = true;
        this.debounceEmit();
      }
    },
    onToInput(value) {
      if (value !== this.rangeTo) {
        // Update rangeTo before active update, otherwise when updating from
        // inactive state, active remains false forever
        this.rangeTo = value;
        this.dirty = true;
        this.debounceEmit();
      }
    },
    onExactInput(value) {
      if (value !== this.exactNumber) {
        this.dirty = true;
        this.exactNumber = value;
        this.debounceEmit();
      }
    },
    scaleValue(value) {
      if (this.specs.field === "score") {
        return value * 100;
      }

      return +value;
    },
    scaleValueDown(value) {
      if (this.specs.field === "score") {
        return value / 100;
      }

      return +value;
    },
    validateValue(value) {
      let result;
      if (this.specs.range) {
        result = { ...value };
        // Backend triggers errors on any strings or null values
        if (value.min === "") {
          result = omit(result, "min");
        }
        if (value.max === "") {
          result = omit(result, "max");
        }

        if (value.min === undefined && value.max === undefined) {
          this.dirty = false;
        }
      } else {
        result = value;
        if (value == null) {
          this.dirty = false;
        }
      }

      return result;
    },
    updateTempRange(range) {
      this.tempRange = range.slice();
    },
  },
};
</script>
