<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleRegister">
      <h1 class="text-uppercase">Sign up</h1>
      <template v-if="!registrationSuccessful">
        <base-input
          v-model="formFields.firstname"
          :rules="[rule.firstNameRequired]"
          placeholder="First name"
          icon="$user"
          class="input"
        />
        <base-input
          v-model="formFields.lastname"
          :rules="[rule.lastNameRequired]"
          placeholder="Last name"
          icon="$user"
          class="input"
        />

        <base-input
          v-model="formFields.email"
          placeholder="Email"
          type="email"
          icon="$mail"
          class="input"
          :rules="[rule.emailRequired, rule.validEmail]"
        />
        <base-input
          v-model="formFields.company"
          placeholder="Company"
          icon="business"
          class="input"
          :rules="[rule.companyRequired]"
        />

        <v-checkbox
          v-model="confirm"
          :rules="[rule.confirmRequired]"
          color="primary"
          class="mt-0 pt-0"
          label="I confirm that my data will be uploaded to and processed by Cauzza. Cauzza will only use this data to serve the purposes of this demo for you."
        />

        <base-button color="primary" class="submit-button" submit x-large :loading="loading">
          Create account
        </base-button>
        <p class="pa-4">
          Already have an account?
          <router-link :to="{ name: 'login' }">Log in!</router-link>
        </p>
      </template>
      <template v-else>
        <p class="body-text mb-2 align-self-start">
          Thank you for creating an account. We will contact you soon to finish registration.
        </p>
      </template>
    </v-form>
    <snack-bar v-model="showRegistrationFailed">
      We encountered some problems during registration, please try again later.
    </snack-bar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { register } from "@/store/actionTypes";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import SnackBar from "@/components/SnackBar.vue";

const { mapActions } = createNamespacedHelpers("auth");

/**
 * The register page.
 */
export default {
  name: "Register",

  components: {
    BaseButton,
    BaseInput,
    SnackBar,
  },

  props: {
    /**
     * The URL where to redirect to after a successful register. Defaults to the home route.
     */
    nextUrl: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      rule: {
        firstNameRequired: this.RULES.required("Please set your first name"),
        lastNameRequired: this.RULES.required("Please set your last name"),
        emailRequired: this.RULES.required("Email is required"),
        companyRequired: this.RULES.required("Please set company name"),
        confirmRequired: this.RULES.required("Please confirm policy"),
        validEmail: this.RULES.email("This doesn't look like a valid email"),
      },

      formFields: {
        firstname: "",
        lastname: "",
        email: "",
        company: "",
      },
      confirm: false,
      rememberMe: false,
      showPwd: false,
      valid: true,
      loading: false,
      registrationSuccessful: false,
      showRegistrationFailed: false,
    };
  },

  methods: {
    ...mapActions({
      register,
    }),

    /**
     *  Runs on submit of the form. Validates it and does the API call if it is valid.
     */
    async handleRegister() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;

      try {
        // Get the welcome route. Uses a stub token parameter because router does not want to match it otherwise.
        const welcome = this.$router.resolve({ name: "welcome", params: { token: "stub" } }).route;
        // And now we need to remove the stub token from the path, and per definition of the backend API it has to be
        // the last parameter since the real token will be appended to the result string.
        const path = welcome.fullPath.substring(0, welcome.fullPath.length - welcome.params.token.length);
        await this.register({ ...this.formFields, urlPrefix: `${window.location.origin}${path}` });

        this.registrationSuccessful = true;
      } catch (e) {
        this.showRegistrationFailed = true;
        throw e; // Rethrow error for Sentry
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-bottom: 59px; // Aligns the buttons with the ones on the log-in page.
}
.input {
  margin-bottom: 4px;
}
.submit-button {
  margin-top: 60px;
}

.toggle-show {
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}
</style>
