<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      :active-class="activeClass"
      :large="large && !(small || xLarge)"
      :small="small"
      :x-large="xLarge"
      :block="block && !icon"
      rounded
      :value="value"
      :outlined="empty && !hover"
      :elevation="hover && !icon ? '4' : '0'"
      :loading="loading"
      :disabled="loading || disabled"
      :color="color"
      :type="submit ? 'submit' : undefined"
      :fab="icon"
      :icon="icon"
      :to="to"
      :class="['base-button', empty ? 'outlined' : null]"
      v-on="$listeners"
    >
      <!-- @slot The default slot, passed on to v-btn.-->
      <slot />
    </v-btn>
  </v-hover>
</template>

<script>
/**
 * Basic button to be reused throughout the application.
 */
export default {
  name: "BaseButton",

  props: {
    /**
     * Initial button value when used in v-btn-toggle group
     */
    value: {
      type: String,
      default: undefined,
    },
    /**
     * Button active class used in v-btn-toggle group
     */
    activeClass: {
      type: String,
      default: "",
    },
    /**
     * Makes button outlined and without fill
     */
    empty: {
      type: Boolean,
      default: false,
    },
    /**
     * Applies color to outline or to fill itself
     */
    color: {
      type: String,
      default: null,
    },
    /**
     * Enables form submit when button is used as a part of the form
     */
    submit: {
      type: Boolean,
      default: false,
    },
    /**
     * Shows loader and blocks button
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Make button large
     */
    large: {
      type: Boolean,
      default: true,
    },
    /**
     * Make button small
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Make button extra large
     */
    xLarge: {
      type: Boolean,
      default: false,
    },
    /**
     * Make button fill width
     */
    block: {
      type: Boolean,
      default: true,
    },
    /**
     * Make button icon look like
     */
    icon: {
      type: Boolean,
      default: false,
    },
    /**
     * Denotes the target route of the link. You can find more information about the to prop on the
     * vue-router documentation.
     */
    to: {
      type: [String, Object],
      default: undefined,
    },
  },
};
</script>

<style lang="scss">
// Override Vuetify's styling to set the width of an small icon button to be the same as the height.
.v-btn--icon.v-size--small .v-icon {
  width: 16px;
  min-width: 16px;
}

button.base-button.v-btn.v-size--default {
  /*height: 48px;*/
  font-size: 0.8rem;
  font-weight: bold;
}

button.base-button.v-btn.v-size--large {
  height: 56px;
  font-weight: bold;

  &.v-btn--fab {
    width: 56px;
  }
}

.outlined {
  border: thin solid;
}

button.base-button.v-btn.v-size--x-large {
  height: 70px;
  font-weight: bold;
}

button.base-button.v-btn--fab.v-size--small {
  height: 28px;
  width: 28px;
}
</style>
