<template>
  <div class="fill-height">
    <navigation-drawer
      v-model="drawer"
      :user-role="profile.role"
      :user-has-subscriptions="profile.subscription_count > 0"
      @toggleFilters="toggleFilters"
    />
    <app-toolbar
      v-model="currentQuery"
      :avatar="profile.avatar"
      :firstname="profile.firstname"
      :lastname="profile.lastname"
      :disable-search="selectedIndex === undefined || !language"
      :reason="reason"
      :index-name="indexName"
      :store-language="storeLanguage"
      :languages="languages"
      :subscription-details="subscriptionDetails"
      @toggleDrawer="toggleDrawer"
      @search="onSearch"
      @selectLanguage="selectLanguage"
      @clear="resetSearch"
      @shareIndex="shareIndex"
      @shareSubscription="shareSubscription"
    />
    <v-main class="background fill-height">
      <v-tooltip top open-delay="1000">
        <template #activator="{ on }">
          <v-btn color="secondary" class="start-tour" icon @click="onHelp" v-on="on">
            <v-icon x-large>help</v-icon>
          </v-btn>
        </template>
        <span>Start help tour</span>
      </v-tooltip>

      <v-snackbar v-model="showError" bottom>
        {{ error }}
        <BaseButton small :block="false" color="secondary" @click="showError = false">Ok</BaseButton>
      </v-snackbar>
      <router-view class="fill-height" />
    </v-main>
  </div>
</template>

<script>
import AppToolbar from "@/components/AppToolbar.vue";
import BaseButton from "@/components/BaseButton.vue";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import helpHero from "@/plugins/helphero";
import { STATUS_CODES } from "@/utils/client";

import {
  resetSearch,
  getProfile,
  search,
  toggleFilters,
  selectLanguage,
  searchAfterOAuthFailed,
} from "@/store/actionTypes";
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapState } = createNamespacedHelpers("main");
const { mapState: mapAuthState, mapActions: mapAuthActions } = createNamespacedHelpers("auth");
/**
 * Common layout of the authenticated app (toolbar, drawer, ...).
 */
export default {
  name: "AuthenticatedApp",
  components: {
    BaseButton,
    AppToolbar,
    NavigationDrawer,
  },
  data() {
    return {
      drawer: false,
      loading: false,
      error: "",
      showError: false,
      currentQuery: "",
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
      query: (state) => state.query,
      indexName: (state) => state.selectedIndexDetails.name,
      languages: (state) => state.selectedIndexDetails.lang,
      storeLanguage: (state) => state.language,
      errorState: (state) => state.errorState,
      errorStatusCode: (state) => state.errorStatusCode,
      subscriptionDetails: (state) => state.subscriptionDetails,
    }),
    ...mapAuthState({
      profile: (state) => state.profile,
    }),
    selectedIndex() {
      return this.$route.params.indexId;
    },
    reason() {
      return this.selectedIndex === undefined
        ? "Please select the data collection to open below"
        : "Please select a language to search in below";
    },
  },

  watch: {
    query(v) {
      this.currentQuery = v;
    },
  },

  mounted() {
    this.getProfile();
  },

  methods: {
    ...mapActions({
      toggleFilters,
      search,
      resetSearch,
      selectLanguage,
    }),
    ...mapAuthActions({
      getProfile,
      searchAfterOAuthFailed,
    }),
    onHelp() {
      helpHero.identify(this.profile.id);
      helpHero.startTour("YxBhuc6O0YK");
    },
    async onSearch(query) {
      if (query === "" || query === null) {
        // Don't do anything if the search string is empty
        return;
      }
      const index = this.selectedIndex;
      if (index === undefined) {
        this.error = "You have to select a data collection before you can do a search...";
        this.showError = true;
      } else {
        this.loading = true;
        await this.search({ query, index });
        if (this.errorStatusCode === STATUS_CODES.FORBIDDEN) {
          await this.searchAfterOAuthFailed({
            indexId: index,
            queryId: this.errorState.detail.qid,
            tenantId: this.errorState.detail.tenant,
          });
        }
        this.loading = false;
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    shareIndex() {
      this.$router.push({ name: "shareIndex" });
    },
    shareSubscription() {
      this.$router.push({ name: "subscriptionShare" });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  //background-color: white;
  background-color: var(--v-pale-grey-base);
  position: relative; // Make this an anchor for the fixed help button
}
.start-tour {
  position: fixed;
  right: 24px;
  bottom: 26px;
  padding: 0;
  margin: 0;
  z-index: 100;
}
</style>
