export function initState() {
  return {
    indices: [],
    subscriptions: [],
    pagination: {
      offset: 0,
    },
    selectedIndexDetails: {
      id: "",
      name: "",
      created: null,
      lang: [],
      updated: null,
      filters: [],
      users: [],
    },
    subscriptionDetails: {
      id: "",
      name: "",
      created: null,
      updated: null,
      datasources: [],
      indices: [],
      overdue_price: 0,
      owners: [],
      queries: [],
      bundle_size: 0,
      query_limit: 0,
    },
    filters: [],
    showFilters: true,
    query: "",
    currentHit: 0,
    language: null,

    result: {
      qid: "",
      totalResults: 0,
      lang: null,
      documents: [],
      cache: {},
      lastOpenedId: null,
    },
    loadingResults: false,
    scrollPosition: 0,
    errorState: null,
    sp_users: [],
    sp_groups: [],
    sp_sites: [],
  };
}

export default initState();
