<template>
  <DocumentPreviewList
    :previews="getResultDocuments"
    :total-results="totalResults"
    :error-state="errorState"
    :saved-position="scrollPosition"
    @feedback="saveDocumentFeedback"
    @hideDocument="hideResultDocument"
    @infinite-scroll="loadMoreHandler"
  />
</template>

<script>
import DocumentPreviewList from "@/components/DocumentPreviewList.vue";
import { createNamespacedHelpers } from "vuex";
import {
  hideResultDocument,
  loadMore,
  saveDocumentFeedback,
  saveScrollPosition,
  loadMoreAfterOAuthFailed,
} from "@/store/actionTypes.js";
import { getResultDocuments } from "@/store/getterTypes.js";
import { STATUS_CODES } from "@/utils/client.js";

const { mapGetters, mapActions, mapState } = createNamespacedHelpers("main");
const { mapActions: mapAuthActions } = createNamespacedHelpers("auth");

/**
 * The home page.
 */
export default {
  name: "SearchResults",
  components: {
    DocumentPreviewList,
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === "searchResults" && to.name === "document") {
      this.saveScrollPosition(window.pageYOffset);
    }
    next();
  },
  props: {
    indexId: {
      type: String,
      required: true,
    },
    qid: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      getResultDocuments,
    }),
    ...mapState({
      totalResults: (state) => state.result.totalResults,
      scrollPosition: (state) => state.scrollPosition,
      errorState: (state) => state.errorState,
    }),
  },

  mounted() {
    window.scrollTo({
      top: this.scrollPosition,
    });
    // After scrolling back to saved position, reset saved position to 0.
    // We save position only when navigationg to document
    // As result all situation when user is not closing his document - scroll will be 0 (reset state)
    this.saveScrollPosition(0);
  },
  methods: {
    ...mapActions({
      loadMore,
      hideResultDocument,
      saveDocumentFeedback,
      saveScrollPosition,
    }),
    ...mapAuthActions({ loadMoreAfterOAuthFailed }),

    async loadMoreHandler($state) {
      const options = {
        index: this.indexId,
        qid: this.qid,
      };

      if (this.errorState) {
        $state.error();
        return;
      }

      try {
        const loaded_docs = await this.loadMore(options);
        $state.loaded();

        if (loaded_docs === 0) {
          $state.complete();
        }
      } catch (e) {
        // TODO: check if it is indeed an OAuth permission mismatch or whether it was something else.
        if (e.status === STATUS_CODES.FORBIDDEN) {
          await this.loadMoreAfterOAuthFailed({
            indexId: this.indexId,
            queryId: this.qid,
            tenantId: e.response.data.detail.tenant,
          });
        } else {
          throw e;
        }
      }
    },
  },
};
</script>
