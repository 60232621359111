import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { checkSessionId } from "./oauth";
import { OAuthTypes } from "./const";

export const STATUS_CODES = Object.freeze({
  OK: 200,
  CREATED: 201,
  NOCONTENT: 204,
  NOTMODIFIED: 304,
  BADREQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOTFOUND: 404,
  CONFLICT: 409,
  INTERNALSERVERERROR: 500,
  NETWORKERROR: 598,
  UNKNOWNSTATUS: "UNKNOWNSTATUS",
});

class ClientError {
  constructor(status, data, response) {
    this.status = status;
    this.data = data;
    this.response = response;
  }
}

export class Client {
  constructor(apiKey, baseURL) {
    this.apiKey = apiKey;
    this.origin = new URL(baseURL).origin;
    this.axios = axios.create({ baseURL });
    this._authenticated = null;

    this.api = {
      forgot: () => `forgot`,
      reset: () => `reset`,
      users: () => `users`,
      version: () => `${this.origin}/version`,
      health: () => `${this.origin}/internal/0.2.0/health`,
      auth: {
        login: () => `login`,
        logout: () => `logout`,
        users: () => `users`,
        me: () => `me`,
        oauthLogin: (redirectUrl, oauthType, extraOptions, sessionId, append_tenant_id, cookies) => {
          const prefix = `oauth/${oauthType}/login?redirect_uri=${redirectUrl}&session_id=${sessionId}&cookies=${cookies}&${extraOptions}`;
          return oauthType === OAuthTypes.MICROSOFT ? `${prefix}&append_tenant_id=${append_tenant_id}` : prefix;
        },
        oauthLoginForTenant: (tenant, redirectUrl, oauthType, extraOptions, sessionId, append_tenant_id, cookies) =>
          `oauth/${oauthType}/login?redirect_uri=${redirectUrl}&session_id=${sessionId}&cookies=${cookies}&tenant=${tenant}&append_tenant_id=${append_tenant_id}&${extraOptions}`,
        oauthToken: (oauthType) => `oauth/${oauthType}/token`,
      },
      main: {
        index: () => `index`,
        indexDetails: (index) => `index/${index}`,
        shareSub: (sub) => `${this.origin}/internal/0.2.0/subscriptions/${sub}/users`,
        subscriptions: () => `${this.origin}/internal/0.2.0/subscriptions`,
        subscriptionDetails: (sub, from, to) =>
          `${this.origin}/internal/0.2.0/subscriptions/${sub}?date_from=${from}&date_to=${to}`,
        search: (index) => `index/${index}/search`,
        fetchResults: (index, qid) => `index/${index}/search/${qid}`,
        document: (index, qid, id) => `index/${index}/search/${qid}/doc/${id}`,
        download: (index, qid, id) => `index/${index}/search/${qid}/doc/${id}/download`,
        exportResults: (index, qid) => `index/${index}/search/${qid}/export`,
        shareIndex: (index) => `index/${index}/users`,
        thumbUp: (index, qid, id) => `index/${index}/search/${qid}/doc/${id}/thumbs-up`,
        thumbDown: (index, qid, id) => `index/${index}/search/${qid}/doc/${id}/thumbs-down`,
        sharepointUsers: (tenantId) => `sharepoint/${tenantId}/users`,
        sharepointGroups: (tenantId) => `sharepoint/${tenantId}/groups`,
        sharepointSites: (tenantId) => `sharepoint/${tenantId}/site`,
        selectSharePointDrives: (tenantId) => `sharepoint/${tenantId}/select_drives`,
        oauthAdminConsent: (tenantId, redirectUrl, oauthType, sessionId) =>
          `oauth/${oauthType}/admin_consent?tenant=${tenantId}&redirect_uri=${redirectUrl}&session_id=${sessionId}`,
      },
      monitoring: {
        jobs: () => `${this.origin}/internal/0.2.0/jobs`,
        jobProgress: (jobId) => `${this.origin}/internal/0.2.0/jobs/${jobId}/progress`,
      },
    };
    this._usersettings = null;
  }

  async getIndexes() {
    return await this.userAuthCallBackend({
      url: this.api.main.index(),
    });
  }

  async getIndexDetails(index) {
    return await this.userAuthCallBackend({
      url: this.api.main.indexDetails(index),
    });
  }

  async getSubscriptions() {
    return await this.userAuthCallBackend({
      url: this.api.main.subscriptions(),
    });
  }

  async getSubscriptionDetails(sub, from, to) {
    return await this.userAuthCallBackend({
      url: this.api.main.subscriptionDetails(sub, from, to),
    });
  }

  async isAuth() {
    if (this._authenticated === null) {
      try {
        await this.getCachedProfile();
        this._authenticated = true;
      } catch (e) {
        this._authenticated = false;
      }
    }
    return this._authenticated;
  }

  async updateProfile(userData) {
    const config = {
      method: "PUT",
      url: this.api.auth.me(),
      data: {
        ...userData,
      },
    };

    return await this.userAuthCallBackend(config);
  }

  async getProfile() {
    const config = {
      method: "GET",
      url: this.api.auth.me(),
    };

    this._usersettings = await this.userAuthCallBackend(config);
    return this._usersettings;
  }

  async getCachedProfile() {
    if (this._usersettings == null) {
      return await this.getProfile();
    } else {
      return this._usersettings;
    }
  }

  async search(index, params, data) {
    const { lang, query, filters = [] } = data;
    const filterInfo = ["relevance", "score"];
    const config = {
      method: "POST",
      url: this.api.main.search(index),
      params,
      data: {
        lang,
        query,
        filters,
        filterInfo,
      },
    };

    return await this.userAuthCallBackend(config);
  }

  async fetchResults(index, qid, params) {
    const filters = JSON.stringify(params.filters || []);
    const filterInfo = JSON.stringify(["relevance", "score"]);
    const config = {
      method: "GET",
      params: {
        ...params,
        filters,
        filterInfo,
      },
      url: this.api.main.fetchResults(index, qid),
    };

    return await this.userAuthCallBackend(config);
  }

  async shareIndex(index, emails, urlPrefix) {
    const config = {
      method: "POST",
      data: {
        emails,
        "url-prefix": urlPrefix,
      },
      url: this.api.main.shareIndex(index),
    };

    return await this.userAuthCallBackend(config);
  }

  async shareSub(sub, emails, urlPrefix) {
    const config = {
      method: "POST",
      data: {
        emails,
        "url-prefix": urlPrefix,
      },
      url: this.api.main.shareSub(sub),
    };

    return await this.userAuthCallBackend(config);
  }

  async document(index, qid, id) {
    const config = {
      method: "GET",
      url: this.api.main.document(index, qid, id),
    };

    return await this.userAuthCallBackend(config);
  }

  async thumbUp(index, qid, id) {
    const config = {
      method: "PUT",
      url: this.api.main.thumbUp(index, qid, id),
    };

    return await this.userAuthCallBackend(config);
  }

  async thumbDown(index, qid, id) {
    const config = {
      method: "PUT",
      url: this.api.main.thumbDown(index, qid, id),
    };

    return await this.userAuthCallBackend(config);
  }

  async downloadDocument(index, qid, id) {
    const config = {
      method: "GET",
      url: this.api.main.download(index, qid, id),
      responseType: "arraybuffer",
      headers: {
        Accept: "application/*",
      },
    };

    return await this.userAuthCallBackend(config);
  }

  async exportResults(index, qid, params) {
    const filters = JSON.stringify(params.filters || []);
    const config = {
      method: "GET",
      url: this.api.main.exportResults(index, qid),
      params: {
        filters,
      },
      responseType: "arraybuffer",
      headers: {
        Accept: "application/*",
      },
    };

    return await this.userAuthCallBackend(config);
  }

  async forgot(email, urlPrefix) {
    const config = {
      method: "POST",
      url: this.api.forgot(),
      data: {
        email,
        "url-prefix": urlPrefix,
      },
    };
    return await this.apiKeyCallBackend(config);
  }

  async reset(password, token) {
    const config = {
      method: "PUT",
      url: this.api.reset(),
      data: {
        password,
        cookies: true,
      },
    };
    const result = await this.bearerTokenCallBackend(config, token);

    this._authenticated = true;

    return result;
  }

  async login(email, password) {
    const config = {
      method: "POST",
      url: this.api.auth.login(),
      data: {
        email,
        password,
        cookies: true,
      },
    };
    const result = await this.apiKeyCallBackend(config);

    this._authenticated = true;

    return result;
  }

  async register(firstname, lastname, email, company, urlPrefix) {
    const config = {
      method: "POST",
      url: this.api.auth.users(),
      data: {
        firstname,
        lastname,
        email,
        company,
        "url-prefix": urlPrefix,
      },
    };
    return await this.apiKeyCallBackend(config);
  }

  async logout() {
    const config = {
      method: "GET",
      url: this.api.auth.logout(),
    };

    await this.userAuthCallBackend(config);

    this.clearAuth();
  }

  async oauthLogin(
    redirectUrl,
    oauthType = "",
    tenantId = "",
    append_tenant_id = false,
    extraOptions = "",
    sessionId = uuidv4(),
    cookies = true
  ) {
    const config = {
      method: "GET",
      url:
        tenantId === ""
          ? this.api.auth.oauthLogin(
              `${window.location.origin}${redirectUrl}`,
              oauthType,
              extraOptions,
              sessionId,
              append_tenant_id,
              cookies
            )
          : this.api.auth.oauthLoginForTenant(
              tenantId,
              `${window.location.origin}${redirectUrl}`,
              oauthType,
              extraOptions,
              sessionId,
              append_tenant_id,
              cookies
            ),
    };

    let response = await this.apiKeyCallBackend(config);

    if (!checkSessionId(response["auth_url"], sessionId)) {
      throw new ClientError(STATUS_CODES.CONFLICT, "", response);
    }

    return response;
  }

  async oauthToken(token, oauthType = "") {
    const config = {
      method: "GET",
      url: this.api.auth.oauthToken(oauthType),
    };

    return await this.bearerTokenCallBackend(config, token);
  }

  async oauthAdminConsent(
    tenantId,
    redirectUrl = "/sp-admin/select-drives",
    oauthType = "microsoft",
    sessionId = uuidv4()
  ) {
    const config = {
      method: "GET",
      url: this.api.main.oauthAdminConsent(tenantId, `${window.location.origin}${redirectUrl}`, oauthType, sessionId),
    };

    return await this.apiKeyCallBackend(config);
  }

  async getJobs(onlyRunning) {
    const config = {
      method: "GET",
      url: this.api.monitoring.jobs(),
      params: {
        only_running: JSON.stringify(onlyRunning),
      },
    };

    return await this.userAuthCallBackend(config);
  }

  async getJobProgress(jobId) {
    const config = {
      method: "GET",
      url: this.api.monitoring.jobProgress(jobId),
    };

    return await this.userAuthCallBackend(config);
  }

  async getSharePointUsers(tenantId) {
    return await this.userAuthCallBackend({
      url: this.api.main.sharepointUsers(tenantId),
    });
  }

  async getSharePointGroups(tenantId) {
    return await this.userAuthCallBackend({
      url: this.api.main.sharepointGroups(tenantId),
    });
  }

  async getSharePointSites(tenantId) {
    return await this.userAuthCallBackend({
      url: this.api.main.sharepointSites(tenantId),
    });
  }

  async selectSharePointDrives(tenantId, data) {
    const { sp_users, sp_groups, sp_sites = [] } = data;
    const config = {
      method: "POST",
      url: this.api.main.selectSharePointDrives(tenantId),
      data: {
        sharePointUsers: sp_users,
        sharePointGroups: sp_groups,
        sharePointSites: sp_sites,
      },
    };

    return await this.userAuthCallBackend(config);
  }

  clearAuth() {
    this._authenticated = false;
    this._usersettings = null;
  }

  async version() {
    return await this.callBackend({
      method: "GET",
      url: this.api.version(),
    });
  }

  async health() {
    return await this.callBackend({
      method: "GET",
      url: this.api.health(),
    });
  }

  async apiKeyCallBackend(config) {
    const { headers = {} } = config;
    headers["AW-API-KEY"] = this.apiKey;
    return await this.callBackend({ ...config, headers, withCredentials: true });
  }

  async bearerTokenCallBackend(config, token) {
    const { headers = {} } = config;
    headers["Authorization"] = `Bearer ${token}`;
    return await this.callBackend({ ...config, headers, withCredentials: true });
  }

  async userAuthCallBackend(config) {
    return await this.callBackend({ ...config, withCredentials: true });
  }

  async callBackend(config) {
    try {
      const response = await this.axios(config);

      if (config.responseType === "arraybuffer") {
        return {
          file: response.data,
          type: response.headers["content-type"],
          disposition: response.headers["content-disposition"],
        };
      } else {
        return response && response.data;
      }
    } catch (e) {
      if (e && !e.response && e.message === "Network Error") {
        throw new ClientError(STATUS_CODES.NETWORKERROR, "", e.response);
      }

      if (e && !e.response) {
        throw new ClientError(STATUS_CODES.UNKNOWNSTATUS, "", e.response);
      }

      if (e.response.status === STATUS_CODES.UNAUTHORIZED) {
        this._usersettings = null;
      }
      throw new ClientError(e.response.status, e.response.data, e.response);
    }
  }
}
