import * as Sentry from "@sentry/browser";
import { default as amplitude, EVENT_TYPES, logEvent } from "@/plugins/amplitude";
import helpHero from "@/plugins/helphero";
import * as actionType from "../actionTypes";
import * as mt from "../mutationTypes";
import router from "@/router";
import { awback } from "@/plugins/awback";

/**
 * Pass on the user context to Sentry and Amplitude.
 * @param user the user whose context to pass on.
 */
function _set_user_contexts(user) {
  try {
    Sentry.configureScope((scope) => scope.setUser({ id: user.id }));
    amplitude.getInstance().setUserId(user.id);
    helpHero.identify(user.id);
  } catch (e) {
    console.error("Unable to configure Sentry / Amplitude", e);
  }
}

/**
 * Register the given user (object returned by the back-end) in the store, Amplitude and Sentry, then redirect to home.
 * @param commit The Vuex commit function.
 * @param user The user that is logged in.
 * @returns {Promise<void>} The promise returned by the router redirection.
 */
async function _finish_login(commit, user) {
  logEvent(EVENT_TYPES.USER_LOGIN, { id: user.id });
  commit(mt.SET_PROFILE, user);

  _set_user_contexts(user);
  await router.push({ name: "home" });
}

export default {
  async [actionType.login]({ commit }, { email, password }) {
    email = email.trim();
    const resp = await awback.login(email, password);
    await _finish_login(commit, resp.user);
  },

  async [actionType.logout]({ dispatch }) {
    logEvent(EVENT_TYPES.USER_LOGOUT);
    await awback.logout();

    try {
      Sentry.configureScope((scope) => scope.setUser(null));
      amplitude.getInstance().setUserId(null);
      amplitude.getInstance().regenerateDeviceId();
    } catch (e) {
      console.error("Unable to configure Sentry / Amplitude", e);
    }

    dispatch(actionType.reset, null, { root: true });
  },

  [actionType.showSnackBar]({ commit }) {
    commit(mt.SHOW_FORCE_LOGOUT_SNACKBAR, true);
  },

  [actionType.hideSnackBar]({ commit }) {
    commit(mt.SHOW_FORCE_LOGOUT_SNACKBAR, false);
  },

  async [actionType.register]({}, { firstname, lastname, email, company, urlPrefix }) {
    const user = await awback.register(firstname, lastname, email, company, urlPrefix);
    logEvent(EVENT_TYPES.USER_REGISTER, { id: user.id });
    _set_user_contexts(user);
  },

  async [actionType.forgot]({}, { email, urlPrefix }) {
    logEvent(EVENT_TYPES.USER_FORGOT_PWD);
    await awback.forgot(email, urlPrefix);
  },

  async [actionType.oauthLogin]({}, { redirectUrl, oauthType, tenantId, append_tenant_id = false, extraOptions }) {
    return await awback.oauthLogin(redirectUrl, oauthType, tenantId, append_tenant_id, extraOptions);
  },

  async [actionType.oauthToken]({ commit }, { token, oauthType }) {
    // logEvent(EVENT_TYPES.USER_SET_PWD);
    const resp = await awback.oauthToken(token, oauthType);
    await _finish_login(commit, resp.user);
  },

  async [actionType.setNewPassword]({ commit }, { password, token }) {
    logEvent(EVENT_TYPES.USER_SET_PWD);
    const resp = await awback.reset(password, token);
    await _finish_login(commit, resp.user);
  },

  async [actionType.getProfile]({ commit }) {
    const response = await awback.getCachedProfile();

    commit(mt.SET_PROFILE, response);
  },
  async [actionType.updateProfile]({ commit }, profile) {
    logEvent(EVENT_TYPES.USER_UPDATE_PROFILE);
    const response = await awback.updateProfile(profile);

    commit(mt.SET_PROFILE, response);
  },

  [actionType.reset]({ commit }) {
    commit(mt.RESET);
  },

  async [actionType.searchAfterOAuthFailed]({}, { indexId, queryId, tenantId }) {
    // TODO: explain to the user why we are redirecting him (display warning, ...)
    let resp = await awback.oauthLogin(`/data-collection/${indexId}/search/${queryId}`, "microsoft", tenantId);
    window.location.href = resp["auth_url"];
  },

  async [actionType.openResultDocumentAfterOAuthFailed]({}, { indexId, queryId, documentId, tenantId }) {
    // TODO: explain to the user why we are redirecting him (display warning, ...)
    let resp = await awback.oauthLogin(
      `/data-collection/${indexId}/search/${queryId}/doc/${documentId}`,
      "microsoft",
      tenantId
    );
    window.location.href = resp["auth_url"];
  },

  async [actionType.downloadDocumentAfterOAuthFailed]({}, { indexId, queryId, documentId, tenantId }) {
    // TODO: explain to the user why we are redirecting him (display warning, ...)
    let resp = await awback.oauthLogin(
      `/data-collection/${indexId}/search/${queryId}/doc/${documentId}`,
      "microsoft",
      tenantId
    );
    window.location.href = resp["auth_url"];
  },

  async [actionType.loadMoreAfterOAuthFailed]({}, { indexId, queryId, tenantId }) {
    // let resp = await awback.oauthLogin(`data-collection/${indexId}/search/${queryId}/searchResults`);
    // TODO: explain to the user why we are redirecting him (display warning, ...)
    let resp = await awback.oauthLogin(`/data-collection/${indexId}/search/${queryId}`, "microsoft", tenantId);
    window.location.href = resp["auth_url"];
  },

  async [actionType.getSearchResultsAfterOAuthFailed]({}, { indexId, queryId, tenantId }) {
    // let resp = await awback.oauthLogin(`data-collection/${indexId}/search/${queryId}/searchResults`);
    // TODO: explain to the user why we are redirecting him (display warning, ...)
    let resp = await awback.oauthLogin(`/data-collection/${indexId}/search/${queryId}`, "microsoft", tenantId);
    window.location.href = resp["auth_url"];
  },

  async [actionType.oauthAdminConsent]({}, { tenantId }) {
    // let resp = await awback.oauthLogin(`data-collection/${indexId}/search/${queryId}/searchResults`);
    // TODO: explain to the user why we are redirecting him (display warning, ...)
    let resp = await awback.oauthAdminConsent(tenantId);
    window.location.href = resp["auth_url"];
  },

  async [actionType.getJobs]({}, onlyRunning) {
    return await awback.getJobs(onlyRunning);
  },

  async [actionType.getJobProgress]({}, jobId) {
    return await awback.getJobProgress(jobId);
  },
};
