export default {
  required: (errorMessage) => (v) => !!v || `${errorMessage}`,
  min: (errorMessage, minLength) => (v) => v.length >= minLength || `${errorMessage}`,
  max: (errorMessage, maxLength) => (v) => v.length <= maxLength || `${errorMessage}`,
  // eslint-disable-next-line no-useless-escape
  email: (errorMessage) => (v) =>
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      v
    ) || `${errorMessage}`,
  confirmMatch: (errorMessage, pass) => (confirmPass) => pass === confirmPass || `${errorMessage}`,
  validUUID: (errorMessage) => (v) =>
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(v) || `${errorMessage}`,
};
