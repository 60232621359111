<template>
  <v-footer
    v-resize="resize"
    app
    color="white"
    elevation="4"
    :height="smallScreenHeight ? '72px' : '90px'"
    class="footer-container"
  >
    <v-tooltip v-if="downloadPreview" top>
      <template #activator="{ on }">
        <div v-on="on">
          <base-button
            disabled
            color="primary"
            empty
            :block="false"
            :large="false"
            class="footer-button tour-doc-download"
          >
            <v-icon left> $download </v-icon>
            Download
          </base-button>
        </div>
      </template>
      <span>Coming soon!</span>
    </v-tooltip>

    <base-button
      v-else
      color="primary"
      empty
      :block="false"
      class="footer-button"
      :disabled="disableDownload || loading"
      :loading="downloading"
      :large="false"
      @click="$emit('download')"
    >
      <v-icon v-if="disableDownload" left>$fas-exclamation-circle</v-icon>
      <svg-icon v-else name="download" left />
      Download
    </base-button>

    <div class="controls">
      <base-button v-if="total" class="tour-prev-hit" icon :disabled="loading" @click="prevOne">
        <svg-icon name="arrow" />
      </base-button>
      <div v-if="!loading" class="hits">
        <div v-if="!total" class="hits">No matches in the document</div>
        <div v-else class="hits tour-current-hit">
          <div>Match</div>
          <div class="highlight">
            <v-text-field
              dense
              type="number"
              class="hit-input"
              hide-details
              :min="1"
              :max="total"
              :style="{ 'min-width': `${total.toString().length}em` }"
              :value="current + 1"
              :input-value="current + 1"
              @input="handleHitInput($event)"
            />
            <!-- <div :style="{ 'min-width': `${total.toString().length}em` }">{{ current + 1 }}</div> -->
          </div>
          <div>of</div>
          <div class="total">{{ total }}</div>
        </div>
      </div>
      <v-skeleton-loader v-else type="chip" />
      <base-button v-if="total" class="tour-next-hit" icon :disabled="loading" @click="nextOne">
        <svg-icon name="arrow_right" />
      </base-button>
    </div>

    <v-tooltip top>
      <template #activator="{ on }">
        <div v-on="on">
          <v-btn-toggle v-model="scrollOrder" rounded mandatory class="cycle-selection">
            <base-button
              active-class="cycling-type-button-active"
              :large="false"
              empty
              :block="false"
              class="footer-button primary-colored"
              value="score"
            >
              Score
            </base-button>
            <base-button
              active-class="cycling-type-button-active"
              :large="false"
              empty
              :block="false"
              class="footer-button primary-colored"
              value="document"
            >
              Document
            </base-button>
          </v-btn-toggle>
        </div>
      </template>
      <span>Select cycling by next hit in document or by next highest score hit</span>
    </v-tooltip>
  </v-footer>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import SvgIcon from "@/components/SvgIcon";

/**
 * Footer displayed at the bottom of opened documents to navigate between selections etc.
 */
export default {
  name: "DocumentFooter",

  components: {
    SvgIcon,
    BaseButton,
  },

  props: {
    /**
     * The current selected page, v-model input value
     */
    value: {
      type: Number,
      default: 0,
    },
    /**
     * The total number of entities contained by the current document.
     */
    total: {
      type: [String, Number],
      default: "",
    },
    /**
     * Disable the download button.
     */
    disableDownload: {
      type: Boolean,
      default: false,
    },
    /**
     * Do not render an actual download button but a sneak preview of it. .
     */
    downloadPreview: {
      type: Boolean,
      default: false,
    },
    /**
     * Set the download button in the loading state.
     */
    downloading: {
      type: Boolean,
      default: false,
    },
    /**
     * Render the component in a loading state
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current: 0,
      windowHeight: null,
      scrollOrder: "score",
    };
  },
  computed: {
    smallScreenHeight() {
      return this.windowHeight < 1000;
    },
  },
  watch: {
    value: {
      handler(v, old) {
        // If user types manually some incorrecct number (i.e. larger than total), update text field
        // W/o nextTick it is not working and typed value remains
        this.$nextTick(() => (this.current = v));
      },
      immediate: true,
    },
    scrollOrder: {
      handler(value) {
        this.$emit("update:scrollOrder", value === "document");
      },
      immediate: true,
    },
  },
  methods: {
    nextOne() {
      ++this.current;
      if (this.current >= this.total) {
        this.current = 0;
      }

      /**
       * Emitted when going to a different selection. Event value will be the number of the target selection.
       */
      this.$emit("input", this.current);
    },
    prevOne() {
      --this.current;
      if (this.current < 0) {
        this.current = this.total - 1;
      }

      /**
       * Emitted when going to a different hit. Event value will be the number of the target.
       */
      this.$emit("input", this.current);
    },
    handleHitInput(value) {
      if (value > this.total) {
        this.current = this.total - 1;
      } else {
        this.current = value - 1;
      }

      this.$emit("input", this.current);
    },
    resize() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 56px;
  border-color: var(--v-primary-base) !important;
  &:hover {
    background-color: var(--v-primary-base) !important;
    color: white !important;
  }
}
.footer-container {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
}

.cycling-type-button-active {
  background: var(--v-primary-base) !important;
  color: white !important;
}

.controls {
  flex: 0 2 20em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
}

.footer-button.base-button {
  height: 56px;
  padding: 0 24px;
  font-size: 1rem;
  font-weight: bold;

  svg {
    margin-left: 0;
    margin-right: 15px;
  }

  &.primary-colored {
    color: var(--v-primary-base);
  }
}

@media only screen and (max-height: 1000px) {
  .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: 44px;
  }
  .footer-button.base-button {
    height: 44px;
    padding: 0 18px;
  }
}

.hits {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: center;

  > div {
    padding: 5px 7px;
  }
}

.highlight {
  padding: 0 7px 7px;
  flex: 0 1 13em;
  font-weight: bold;
  text-align: center;
  background-color: rgba(10, 169, 230, 0.1);
  margin: 0 7px;

  &::v-deep {
    .v-input__slot:before {
      border-width: 0;
    }
    input {
      text-align: center;
      -moz-appearance: textfield; // Hide arrows in Firefox

      /* Hide arrows in Chrome, Safari, Edge, Opera */
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

.total {
  flex: 0 1 13em;
}

.cycle-selection {
  border-radius: 28px;
}
</style>
