export const PASSWORD_LIMITS = Object.freeze({
  min: 8,
  max: 30,
});

export const OAuthTypes = Object.freeze({
  MICROSOFT: "microsoft",
  GOOGLE: "google",
});
export const NAME_LIMITS = Object.freeze({
  min: 2,
  max: 30,
});
