<template>
  <BaseLoader v-if="loading" loading message="Loading list of available subscriptions" />
  <router-view v-else />
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { getSubscriptions } from "@/store/actionTypes.js";
import BaseLoader from "@/components/BaseLoader.vue";

const { mapActions, mapState } = createNamespacedHelpers("main");

/**
 * Responsible for loading the list of accessible subscriptions is available in the store.
 */
export default {
  name: "Index",
  components: { BaseLoader },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.loading = true;
    this.getSubscriptions().then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions({ getSubscriptions }),
  },
};
</script>
