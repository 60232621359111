import vuetify from "@/plugins/vuetify";

const colors = vuetify.framework.theme.themes.light;
export const chartsPalette = [
  colors["primary-selected"],
  colors.secondary,
  colors.tertiary,
  colors.error,
  colors["mid-blue"],
  colors.success,
  colors.warning,
  colors.login_background,
  colors["dark-grey"],

  // Non originally cauzza colors
  "#fffb53",
  "#b12dd3",
  "#ed672c",
  "#48a5a8",
  "#bafb4e",
  "#c12d9e",
  "#f8d047",
  "#6425f5",
  "#8931EF",
  "#F2CA19",
  "#FF00BD",
  "#0057E9",
  "#87E911",
  "#E11845",
];
