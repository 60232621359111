<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleLogin">
      <h1 class="text-uppercase">Log in</h1>
      <base-input
        v-model="formFields.email"
        placeholder="Email"
        type="email"
        icon="$mail"
        class="input"
        :rules="[rule.emailRequired, rule.validEmail, rule.authFailed]"
      />

      <base-input
        v-model="formFields.password"
        :type="showPwd ? 'text' : 'password'"
        :rules="[rule.pwdRequired, rule.minPwdLength, rule.authFailed]"
        placeholder="Password"
        counter
        :hint="`At least ${minPassLength} characters`"
        icon="$password"
        class="input"
      >
        <template slot="append">
          <v-icon class="toggle-show" @click="showPwd = !showPwd">
            {{ showPwd ? "$closed" : "$eye" }}
          </v-icon>
        </template>
      </base-input>

      <v-row class="text-no-wrap remember-row">
        <v-col>
          <v-checkbox
            v-model="rememberMe"
            hide-details
            color="primary"
            class="mt-0 pt-0 align-checkbox-ripple"
            label="Remember me"
            @change="remember"
          />
        </v-col>
        <v-col class="text-right">
          <router-link :to="{ name: 'forgot-password' }">Forgot password?</router-link>
        </v-col>
      </v-row>

      <base-button :color="authFailed ? 'error' : 'primary'" class="submit-button" x-large submit :loading="loading">
        <template v-if="authFailed">Invalid email or password</template>
        <template v-else>Log in</template>
      </base-button>
      <!--    <p class="pa-4">-->
      <!--      No account yet? Register-->
      <!--      &lt;!&ndash; eslint-disable-next-line &ndash;&gt;-->
      <!--      <router-link :to="{ name: 'register' }">here!</router-link>-->
      <!--    </p>-->
    </v-form>

    <button-o-auth-login
      class="oauth-button"
      :oauth-type="OAUTH_MICROSOFT"
      :loading="loading"
      @click="handleOAuth({ redirectUrl: '', oauthType: OAUTH_MICROSOFT })"
    />
    <button-o-auth-login
      class="oauth-button"
      :oauth-type="OAUTH_GOOGLE"
      :loading="loading"
      @click="handleOAuth({ redirectUrl: '', oauthType: OAUTH_GOOGLE })"
    />
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import ButtonOAuthLogin from "@/components/ButtonOAuthLogin.vue";
import { PASSWORD_LIMITS } from "@/utils/const.js";
import { login, oauthLogin, remember } from "@/store/actionTypes.js";
import { createNamespacedHelpers } from "vuex";
import { STATUS_CODES } from "@/utils/client";
import { OAuthTypes } from "@/utils/const";

const { mapActions, mapState } = createNamespacedHelpers("auth");

/**
 * The login-page.
 */
export default {
  name: "Login",

  components: {
    BaseButton,
    BaseInput,
    ButtonOAuthLogin,
  },

  props: {
    /**
     * The URL where to redirect to after a successful login. Defaults to the home route.
     */
    nextUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rule: {
        emailRequired: this.RULES.required("Email is required"),
        validEmail: this.RULES.email("This doesn't look like a valid email"),
        pwdRequired: this.RULES.required("Please choose a password"),
        authFailed: () => !this.authFailed || "",
        minPwdLength: this.RULES.min(
          `The password needs be at least ${PASSWORD_LIMITS.min} characters long`,
          PASSWORD_LIMITS.min
        ),
      },
      formFields: {
        email: process.env.NODE_ENV === "development" ? process.env.VUE_APP_USER_EMAIL : "",
        password: process.env.NODE_ENV === "development" ? process.env.VUE_APP_PASSWORD : "",
      },
      rememberMe: false,
      valid: true,
      minPassLength: PASSWORD_LIMITS.min,
      showPwd: false,
      loading: false,
      authFailed: false,
      OAUTH_MICROSOFT: OAuthTypes.MICROSOFT,
      OAUTH_GOOGLE: OAuthTypes.GOOGLE,
    };
  },

  computed: {
    redirectTarget() {
      return this.nextUrl === null ? { name: "home" } : this.nextUrl;
    },
  },
  watch: {
    authFailed: {
      handler() {
        this.$refs.form.validate();
      },
      deep: true,
    },
    formFields: {
      handler() {
        this.authFailed = false;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      login,
      remember,
      oauthLogin,
    }),

    /**
     *  Runs on submit to validate and log-in if form input is valid.
     */
    async handleLogin() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      try {
        await this.login(this.formFields);
      } catch (e) {
        if (e && e.status === STATUS_CODES.FORBIDDEN) {
          this.authFailed = true;
        } else if (e && e.status === STATUS_CODES.UNAUTHORIZED) {
          console.error("Wrong API key!");
          throw e;
        } else {
          throw e;
        }
      } finally {
        this.loading = false;
      }
    },
    async handleOAuth(data) {
      this.loading = true;
      let resp = await this.oauthLogin(data);

      window.location.href = resp["auth_url"];
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 59px;
}

.input {
  margin-bottom: 4px;
}

.remember-row .col {
  padding: 0 24px;
  /*margin-bottom: calc(90px - 26px + 4px);*/
}

.align-checkbox-ripple::v-deep {
  .v-icon {
    width: 100%;
    height: 100%;
    text-align: center;
  }
}

.submit-button {
  margin-top: 60px;
}

.oauth-button {
  margin-top: 20px;
}

.toggle-show {
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}
</style>
