<template>
  <iframe id="pdfiframe" :src="computedSrc" />
</template>

<script>
import { awback } from "@/plugins/awback";

/**
 * The home page.
 */
export default {
  name: "DocumentDownload",

  props: {
    /**
     * The document unique id, used to fetch data
     */
    id: {
      type: String,
      default: "",
    },
    qid: {
      type: String,
      default: "",
    },
    indexId: {
      type: String,
      default: "",
    },
    filename: {
      type: String,
      default: "",
    },
  },

  computed: {
    computedSrc() {
      return `${awback.origin}/0.2.0/index/${this.indexId}/search/${this.qid}/doc/${this.id}/download/${this.filename}`;
    },
  },
};
</script>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
}
</style>
