import * as mutationType from "../mutationTypes";
import { initState } from "./state";
import { cloneDeep } from "lodash";

export default {
  [mutationType.SET_QUERY](state, query) {
    state.query = query;
  },
  [mutationType.LOADING_RESULTS](state, loading) {
    state.loadingResults = loading;
  },
  [mutationType.CACHE_DOCUMENT](state, document) {
    // Add a seqCount prop to each hit to enumerate them, making it easier to cycle through hits sequentially
    // Corresponds to original order for hits per pages
    let count = 0;

    document.pages = document.pages.map((page) => {
      const hits = page.hits.map((hit) => ({ ...hit, count: count++ }));

      return { ...page, hits };
    });

    state.result.cache = { ...state.result.cache, [document.id]: document };
  },
  [mutationType.SET_LAST_OPENED](state, id) {
    state.result.lastOpenedId = id;
  },
  [mutationType.SET_RESULT](state, result) {
    state.result = result;
    state.result.documents = state.result.documents.map((document) => ({
      ...document,
      hidden: false,
      documentFeedback: null,
    }));
  },
  [mutationType.UPDATE_RESULT_DOCUMENT](state, { doc, index }) {
    state.result.documents.splice(index, 1, doc);
  },
  [mutationType.CLEAR_RESULT](state) {
    // Do not clear filterInfo here, because active score filter become blank for a while. It will be updated/refreshed in SET_RESULT
    const filterInfo = cloneDeep(state.result.filterInfo);
    state.result = { ...initState().result, filterInfo };
  },
  [mutationType.CLEAR_FILTERS](state) {
    // FIXME: Handle relevance filters better here, because they're could not be reset
    state.filters = state.filters.map((filter) => {
      return {
        ...filter,
        props: {
          field: filter.props.field,
          value: state.selectedIndexDetails.filters[filter.index].props.value,
        },
      };
    });
  },
  [mutationType.SET_LANGUAGE](state, language) {
    state.language = language;
  },
  [mutationType.TOGGLE_FILTERS](state) {
    state.showFilters = !state.showFilters;
  },
  [mutationType.SET_INDICES](state, data) {
    state.indices = data;
  },
  [mutationType.SET_INDEX_DETAILS](state, data) {
    state.selectedIndexDetails = data;

    const { filters = [] } = state.selectedIndexDetails;
    state.filters = filters.map((filter, index) => {
      return {
        type: filter.type,
        index,
        props: {
          field: filter.props.field,
          value: filter.props.value,
        },
      };
    });
  },
  [mutationType.SET_OFFSET](state, new_offset) {
    state.pagination.offset = new_offset;
  },
  [mutationType.SET_SCROLL_POSITION](state, value) {
    state.scrollPosition = value;
  },
  [mutationType.UPDATE_USER_FILTER](state, { index, filter }) {
    state.filters.splice(index, 1, filter);
  },
  [mutationType.RESET](state) {
    // acquire initial state
    const init = initState();
    Object.keys(init).forEach((key) => {
      state[key] = init[key];
    });
  },
  [mutationType.RESET_ERROR_STATE](state) {
    state.errorStatusCode = null;
    state.errorState = null;
  },
  [mutationType.SET_ERROR_STATE](state, error) {
    // TODO: Show new snackbar, after design
    state.errorStatusCode = error.status;
    state.errorState = error.response?.data;
  },

  [mutationType.SET_SP_USERS](state, sp_users) {
    state.sp_users = sp_users;
  },

  [mutationType.SET_SP_GROUPS](state, sp_groups) {
    state.sp_groups = sp_groups;
  },

  [mutationType.SET_SP_SITES](state, sp_sites) {
    state.sp_sites = sp_sites;
  },

  [mutationType.SET_SUBSCRIPTIONS](state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  [mutationType.SET_SUBSCRIPTION_DETAILS](state, subscriptionDetails) {
    state.subscriptionDetails = subscriptionDetails;
  },
};
