<template>
  <v-chip class="score" :color="scoreColor">{{ roundedScore }}</v-chip>
</template>

<script>
/**
 * Showing score with colored bubble.
 */
export default {
  name: "ScoreBubble",
  props: {
    score: {
      type: Number,
      required: true,
    },
    scoreThresholds: {
      type: Array,
      default: () => [0.75, 0.5, 0.25, 0.0],
    },
  },
  computed: {
    scoreColor() {
      if (!process.env.VUE_APP_SCORE_BUBBLE_COLORS) {
        return "secondary";
      }

      switch (true) {
        case !this.scoreThresholds.length:
          return "primary";
        case this.score > this.scoreThresholds[0]:
          return "primary";
        case this.score > this.scoreThresholds[1]:
          return "secondary";
        case this.score > this.scoreThresholds[2]:
          return "orange";
        case this.score > this.scoreThresholds[3]:
          return "tomato";
        default:
          return "tomato";
      }
    },
    roundedScore() {
      return Number(this.score * 100).toFixed();
    },
  },
};
</script>
<style lang="scss" scoped>
.score {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: normal;
  height: 1.75rem;
}
</style>
