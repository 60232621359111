// common
export const reset = "reset";
export const showErrorDialog = "showErrorDialog";
export const hideErrorDialog = "hideErrorDialog";
export const submitErrorDialog = "submitErrorDialog";

// auth
export const login = "login";
export const register = "register";
export const forgot = "forgot";
export const setNewPassword = "setNewPassword";
export const logout = "logout";
export const showSnackBar = "showSnackBar";
export const hideSnackBar = "hideSnackBar";
export const remember = "remember";
export const getProfile = "getProfile";
export const updateProfile = "updateProfile";
export const savePassword = "savePassword";
export const oauthLogin = "oauthLogin";
export const oauthToken = "oauthToken";
export const searchAfterOAuthFailed = "searchAfterOAuthFailed";
export const openResultDocumentAfterOAuthFailed = "fetchReAfterOAuthFailed";
export const downloadDocumentAfterOAuthFailed = "downloadDocumentAfterOAuthFailed";
export const loadMoreAfterOAuthFailed = "loadMoreAfterOAuthFailed";
export const getSearchResultsAfterOAuthFailed = "getSearchResultsAfterOAuthFailed";
export const oauthAdminConsent = "oauthAdminConsent";

// main
export const search = "search";
export const openResultDocument = "openResultDocument";
export const hideResultDocument = "hideResultDocument";
export const updateUserFilterValue = "updateUserFilterValue";
export const toggleFilters = "toggleFilters";
export const getIndexes = "getIndexes";
export const getIndexDetails = "getIndexDetails";
export const resetIndexDetails = "resetIndexDetails";
export const getSearchResults = "getSearchResults";
export const loadMore = "loadMore";
export const downloadDocument = "downloadDocument";
export const resetSearch = "resetSearch";
export const selectLanguage = "selectLanguage";
export const saveDocumentFeedback = "saveDocumentFeedback";
export const saveScrollPosition = "saveScrollPosition";
export const exportResults = "exportResults";
export const shareIndexWith = "shareIndexWith";
export const shareSubWith = "shareSubWith";
export const getSubscriptions = "getSubscriptions";
export const getSubscriptionDetails = "getSubscriptionDetails";
export const setSubscriptions = "setSubscriptions";
export const setSubscriptionInfo = "setSubscriptionInfo";
export const resetSubscriptionInfo = "resetSubscriptionInfo";
export const getSharePointUsers = "getSharePointUsers";
export const getSharePointGroups = "getSharePointGroups";
export const getSharePointSites = "getSharePointSites";
export const getSharePointDriveEntities = "getSharePointDriveEntities";
export const selectSharePointDrives = "selectSharePointDrives";

// job
export const getJobs = "getJobs";
export const getJobProgress = "getJobProgress";
