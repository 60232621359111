<template>
  <ToolbarFilterMenu :icon="icon" :text="fieldName + title" :close-on-content-click="false" :disabled="disabled">
    <template #text>
      {{ fieldName }}
      <span v-if="specs.range">
        <span v-if="dateFrom && !dateTo">&ge;</span>
        <span v-if="!dateFrom && dateTo">&le;</span>
        <span v-if="dateFrom && dateTo">:</span>
      </span>
      <span v-else>
        <span v-if="exactDate">=</span>
      </span>
      {{ title }}
    </template>
    <v-list v-if="specs.range">
      <v-list-item>
        <date-picker label="From" :min="specs.min" :max="dateFromMax" :value="dateFrom" @input="onFromInput" />
      </v-list-item>
      <v-list-item>
        <date-picker label="To" :min="dateToMin" :max="specs.max" :value="dateTo" @input="onToInput" />
      </v-list-item>
      <v-list-item>
        <v-checkbox v-model="allowEmpty" color="secondary" :label="`Include documents without ${fieldName}`" />
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <date-picker label="Date" :min="specs.min" :max="specs.max" :value="exactDate" @input="onExactInput" />
      </v-list-item>
      <v-list-item>
        <v-checkbox v-model="allowEmpty" color="secondary" :label="`Include documents without ${fieldName}`" />
      </v-list-item>
    </v-list>
  </ToolbarFilterMenu>
</template>

<script>
import { omit, debounce } from "lodash";
import DatePicker from "./DatePicker.vue";
import ToolbarFilterMenu from "./ToolbarFilterMenu";
import { dateFormat, startOfDay, endOfDay } from "@/utils/dateFormatter";

/**
 * Component filters that allow to filter documents by date or date range.
 */
export default {
  name: "FilterDateRange",
  components: { DatePicker, ToolbarFilterMenu },
  props: {
    /**
     * The filter specification, determines what field it will filter etc. Of the form
     * `{field: String, min: [String, undefined], max: [String, undefined], range: Boolean}` in which the string values
     * are ISO8601 formatted dates.
     */
    specs: {
      type: Object,
      required: true,
    },
    /**
     * Current filter value. Either a string in case of a non-range filter or an object with min and max fields if a
     * range filter. Of the form `{ min: String, max: String, active: Boolean }` or `{ value: String, active: Boolean }`
     * in which all String values are ISO8601 formatted dates and the former form is used in case `specs.range` is true
     * and the latter form is used in the other case.
     */
    value: {
      type: [Object],
      required: true,
    },
    /**
     * The (left) icon of this filter.
     */
    icon: {
      type: String,
      default: "date",
    },
    /**
     * Disables the filter UI.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allowEmpty: false,
      dateFrom: null,
      dateTo: null,
      exactDate: null,
      active: false,
      debounceEmit: debounce(() => {
        this.updateFilter();
      }, 1500),
    };
  },
  computed: {
    fieldName() {
      const split_field = this.specs.field.split(".");
      return this.specs.displayName || `${split_field[split_field.length - 1]}`;
    },
    title() {
      if (this.specs.range) {
        if (this.dateFrom && this.dateTo) {
          return `${dateFormat(this.dateFrom)} -> ${dateFormat(this.dateTo)}`;
        } else if (this.dateFrom) {
          return dateFormat(this.dateFrom);
        } else {
          return dateFormat(this.dateTo);
        }
      } else {
        return dateFormat(this.exactDate);
      }
    },
    dateFromMax() {
      // If a max date has been selected, that one should be the max. We assume dateTo <= specs.max
      return this.dateTo || this.specs.max;
    },
    dateToMin() {
      // If a min date has been selected, that one is the min. We assume dateFrom >= specs.min.
      return this.dateFrom || this.specs.min;
    },
  },
  watch: {
    value: {
      handler(val = {}) {
        this.active = val.active || false;
        if (this.specs.range) {
          const { value: { min, max } = {} } = val;
          const invalidValue = val.active == null || min === undefined || max === undefined;
          this.exactDate = null;
          this.dateFrom = min || null;
          this.dateTo = max || null;

          if (!this.active && (this.dateFrom || this.dateTo)) {
            this.dateFrom = null;
            this.dateTo = null;
            this.debounceEmit();
          } else if (invalidValue) {
            // FIXME: Looks like infinite loop happens here
            // this.updateFilter();
          }
        } else {
          const invalidValue = val.active == null || val.value === undefined;
          this.exactDate = val.value || null;
          this.dateFrom = null;
          this.dateTo = null;

          if (!this.active && this.exactDate) {
            this.exactDate = null;
            this.debounceEmit();
          } else if (invalidValue) {
            this.debounceEmit();
          }
        }
      },
      immediate: true,
      deep: true,
    },
    allowEmpty(val) {
      if (this.active) {
        this.debounceEmit();
      }
    },
  },
  methods: {
    updateFilter() {
      this.active = this.specs.range ? !!this.dateFrom || !!this.dateTo : !!this.exactDate;
      const value = this.specs.range ? { min: startOfDay(this.dateFrom), max: endOfDay(this.dateTo) } : this.exactDate;
      /**
       * Emits the new value corresponding to v-model.
       */
      this.$emit("input", { value: this.validateValue(value), active: this.active, include_no_data: this.allowEmpty });
    },
    onFromInput(value) {
      if (value !== this.dateFrom) {
        this.dateFrom = value;
        this.debounceEmit();
      }
    },
    onToInput(value) {
      if (value !== this.dateTo) {
        this.dateTo = value;
        this.debounceEmit();
      }
    },
    onExactInput(value) {
      if (value !== this.exactDate) {
        this.exactDate = value;
        this.debounceEmit();
      }
    },
    validateValue(value) {
      let result;
      if (this.specs.range) {
        result = { ...value };
        // Backend triggers errors on any strings or null values
        if (value.min == null) {
          result = omit(result, "min");
        }
        if (value.max == null) {
          result = omit(result, "max");
        }

        if (value.min === undefined && value.max === undefined) {
          this.active = false;
        }
      } else {
        result = value;
        if (value == null) {
          this.active = false;
        }
      }

      return result;
    },
  },
};
</script>
