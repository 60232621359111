import { render, staticRenderFns } from "./BaseButton.vue?vue&type=template&id=78a2745d&"
import script from "./BaseButton.vue?vue&type=script&lang=js&"
export * from "./BaseButton.vue?vue&type=script&lang=js&"
import style0 from "./BaseButton.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VBtn,VHover})
