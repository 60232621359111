<template>
  <ToolbarFilterMenu
    :icon="icon"
    :text="fieldName + title"
    :close-on-content-click="!specs.multiple"
    :disabled="disabled"
  >
    <v-list-item-group
      color="secondary"
      class="not-transparent"
      :multiple="specs.multiple"
      :mandatory="specs.min > 0"
      :value="selectedIndices"
      @change="onChange"
    >
      <v-list-item v-for="(item, index) in specs.items" :key="index">
        <v-list-item-title>{{ getTitle(item) }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </ToolbarFilterMenu>
</template>

<script>
import ToolbarFilterMenu from "./ToolbarFilterMenu";

/**
 * Component filters that allow to filter documents by set of possible values.
 */
export default {
  name: "FilterChoice",
  components: { ToolbarFilterMenu },
  props: {
    /**
     * The filter specification, determines what field it will filter etc. Of the form
     * `{field: String, multiple: Boolean, items: Array, min: Number}`.
     */
    specs: {
      type: Object,
      required: true,
    },
    /**
     * Current filter value. Of the form `{ value: Array }`.
     */
    value: {
      type: Object,
      required: true,
    },

    /**
     * The (left) icon of this filter. Defaults to no icon.
     */
    icon: {
      type: String,
      default: "",
    },
    /**
     * Disables the filter UI.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIndices: [],
      dirty: false,
    };
  },
  computed: {
    fieldName() {
      const split_field = this.specs.field.split(".");
      return this.specs.displayName || `${split_field[split_field.length - 1]}`;
    },
    selectedValues() {
      // Array representation of the selected values.
      if (this.specs.multiple && Array.isArray(this.selectedIndices) && this.selectedIndices.length > 0) {
        return this.selectedIndices.map((index) => this.specs.items[index]);
      } else if (Number.isInteger(this.selectedIndices)) {
        // Non multiple
        return [this.specs.items[this.selectedIndices]];
      } else {
        return [];
      }
    },
    title() {
      const prefix = `: ${this.selectedValues[0]?.title}`;
      switch (this.selectedValues.length) {
        case 0:
          return "";
        case 1:
          return prefix;
        case 2:
          return `${prefix} (+ ${this.selectedValues.length - 1} other)`;
        default:
          return `${prefix} (+ ${this.selectedValues.length - 1} others)`;
      }
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (Array.isArray(newValue.value)) {
          // Transform values (ex. ['one', 'three'] to [0, 2]) to indices
          // using spec array [{"title": "one"}, {"title": "two"}, {"title": "three"}]
          const selectedIndices = newValue.value.map((item) =>
            this.specs.items.findIndex((specItem) => specItem.title === item)
          );

          this.selectedIndices = this.specs.multiple ? selectedIndices : selectedIndices[0];
        } else {
          // Incomplete filter clause
          this.updateFilter();
        }
      },
      immediate: true,
      deep: true,
    },
    specs: {
      handler(newValue, oldValue) {
        if (oldValue && newValue?.multiple !== oldValue?.multiple) {
          if (!Array.isArray(this.selectedIndices)) {
            this.selectedIndices = null;
          } else if (newValue.multiple) {
            this.selectedIndices = [this.selectedIndices];
          } else {
            if (this.selectedIndices.length === 1) {
              this.selectedIndices = this.selectedIndices[0];
            } else {
              this.selectedIndices = null;
              this.updateFilter();
            }
          }
        }

        if (!oldValue && !newValue?.multiple && newValue.items.length === 1) {
          // Init load, select the only available option
          this.selectedIndices = 0;
        }

        // Predefined filter selection by server handle
        if (newValue.value && newValue.value.length !== oldValue?.value?.length) {
          this.selectedIndices = newValue.value.map((item) =>
            this.specs.items.findIndex((specItem) => specItem.title === item)
          );
          this.updateFilter();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateFilter() {
      /**
       * Emits the new value corresponding to v-model.
       */
      const value = this.selectedValues.map((el) => el.title);
      this.$emit("input", { value, active: this.dirty });
    },
    getTitle(item) {
      if (item.count) {
        return `${item.title} (${item.count})`;
      }

      return `${item.title}`;
    },
    onChange(selectedIndices) {
      this.selectedIndices = selectedIndices;

      this.dirty = true;
      this.updateFilter();
    },
  },
};
</script>

<style lang="scss" scoped>
.not-transparent {
  background: white;
}
</style>
